
import { DATE_PLACEHOLDER, TIME_PLACEHOLDER } from '@/constants/constants'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VIcon } from 'vuetify/lib'

@Component
export default class DashboardTimeText extends Vue {
  @Prop({ type: String, default: () => `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}` }) placeholder!: string
  render() {
    return (
      this.$slots.default && (
        <div style='text-align:left;color: var(--v-text-secondary-darken2);margin-bottom:1rem;'>
          <VIcon color='text-secondary darken2' class='mr-1' small>
            mdi-timer
          </VIcon>
          <small>{this.$slots.default || this.placeholder}</small>
        </div>
      )
    )
  }
}
