import { mapActions, mapState, mapStores, mapWritableState, StateTree, StoreDefinition, _GettersTree } from 'pinia'
import { createDecorator } from 'vue-class-component'

type TWritableState = <Id extends string, S extends StateTree, G extends _GettersTree<S>, A, Keys extends keyof S | keyof G>(
  store: StoreDefinition<Id, S, G, A>,
  stateKey?: Keys | undefined
) => import('vue-class-component').VueDecorator
export * from 'pinia-class'
export const WritableState: TWritableState = (store, stateKey) =>
  createDecorator((options, key) => {
    const stateName = stateKey !== null && stateKey !== undefined ? stateKey : key
    const { [stateName]: state } = mapWritableState(store, [stateName])
    if (!options.computed) {
      options.computed = {}
    }
    options.computed[key] = state
    return options
  })

// #region
const isEqual = <T extends {}>(o1: T, o2: T, ignoreKeys: string[] = []) =>
  !Object.keys(o2)
    .filter((k) => !ignoreKeys.includes(k))
    .some((o2k) => o2[o2k] !== o1[o2k])

export const getUpdatingDiff = <T extends {}>(aList: T[], bList: T[], idKey: string) => {
  const spliceIdItemMap = new Map<number, T>()
  const appendItem = []
  for (let bIdx = 0; bIdx < bList.length; bIdx++) {
    const aIdx = aList.findIndex((x) => x[idKey] === bList[bIdx][idKey])
    if (aIdx === -1) {
      appendItem.push(bList[bIdx])
      continue
    }
    if (isEqual(bList[bIdx], aList[aIdx])) {
      continue
    }
    spliceIdItemMap.set(aIdx, bList[bIdx])
  }

  // console.log("updateDiff - spliceIdItemMap", spliceIdItemMap)
  // console.log("updateDiff - appendItem", appendItem)
  return [spliceIdItemMap, appendItem] as const
}

/**
 * 監看 組件內 ${keyOfArr} 的前後值差異, 把有差異的新物件存到被裝飾屬性
 * ${identifyKey} 為辨別的 id
 * 被比較的 array 內 object 的值都只能是簡單型別
 */
export const WatchPlainArrDiff = (keyOfArr: string, identifyKey: string) =>
  createDecorator((options, key) => {
    options[`string${keyOfArr}`] = JSON.stringify([])
    options.watch = {
      ...options.watch,
      [keyOfArr]: {
        handler(newVal) {
          const oldVal = JSON.parse(options[`string${keyOfArr}`])

          // console.log({ newVal, oldVal })
          // console.log(newVal.find((x: any) => x[identifyKey] === '6722ec42-c986-4895-aea7-c247d3a5d625'))
          // console.log(oldVal.find((x: any) => x[identifyKey] === '6722ec42-c986-4895-aea7-c247d3a5d625'))

          const [spliceItems] = getUpdatingDiff(oldVal, newVal, identifyKey)

          ;(this as any).$set(this, key, Array.from(spliceItems.values()))

          options[`string${keyOfArr}`] = JSON.stringify(newVal)
        },
      },
    }

    return options
  })

// #endregion
