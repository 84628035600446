














import { Component, Prop, Vue } from 'vue-property-decorator'
import { getComputedColor } from '@/util/helpers'

@Component
export default class ScaleChart extends Vue {
  @Prop({ type: Number, default: 100 }) maxValue!: number
  @Prop({ type: Number, default: 10 }) minValue!: number
  @Prop({ type: Number, default: 45 }) value!: number
  @Prop({ type: Number, default: 20 }) renderScale!: number

  @Prop({ type: Number, default: 5 }) gap!: number
  @Prop({ type: Number, default: 3 }) height!: number

  @Prop({ type: String, default: '--v-primary-base' }) activeColor!: string
  @Prop({ type: String, default: '--v-secondary-base' }) baseColor!: string
  @Prop({ type: String, default: 'title' }) title!: string
  @Prop({ type: Number, default: 2 }) fixDigit!: number

  get activeVal() {
    const v = this.value - this.minValue
    if (v <= 0) {
      return 0
    }
    return Math.ceil((v / (this.maxValue - this.minValue)) * this.renderScale)
  }

  get valueStyle() {
    return {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: 'var(--v-primary-lighten2)',
    }
  }

  getScaleStyle(idx: number) {
    const style = {
      flex: 1,
      height: `${this.height}rem`,
      backgroundColor: getComputedColor(this.baseColor),
    }
    return idx <= this.activeVal
      ? {
          ...style,
          backgroundColor: getComputedColor(this.activeColor),
        }
      : style
  }
}
