export const config = [
  {
    path: '/',
    redirect: 'overview',
  },
  {
    name: 'Overview',
    meta: { name: '資訊總覽' },
    path: '/overview',
  },
  {
    name: 'MapMgmt',
    meta: { name: '檢視地圖' },
    path: '/map-mgmt',
  },
  {
    name: 'IntersectionMgmt',
    meta: { name: '路口管理' },
    path: '/intersection-mgmt',
  },
  {
    name: 'IntersectionMgmtDashboard',
    meta: { name: '路口詳細資訊' },
    path: '/intersection-mgmt/dashboard',
  },
  {
    name: 'DevicesMgmtCreate',
    meta: { name: '新增路口及設備' },
    path: '/devices-mgmt-create',
  },
  {
    name: 'DevicesMgmtList',
    meta: { name: '路口及設備組合列表' },
    path: '/devices-mgmt-list',
  },
  {
    name: 'DevicesMgmtDetailList',
    meta: { name: '換裝歷程' },
    path: '/devices-mgmt-list-detail',
  },
  {
    name: 'RecordQuery',
    meta: { name: '資料查詢' },
    path: '/record-query',
  },
  {
    name: 'AlertSetting',
    meta: { name: '告警設定' },
    path: '/alert-setting',
  },
  {
    name: 'ReportQuery',
    meta: { name: '報表查詢' },
    path: '/report-query',
  },
  {
    name: 'FixMgmtInstall',
    meta: { name: '換修管理' },
    path: '/fix-mgmt/install',
  },
  {
    name: 'AccountMgmtUser',
    meta: { name: '使用者帳號' },
    path: '/account-mgmt-user',
  },
  {
    name: 'AccountMgmtProjectUser',
    meta: { name: '使用者帳號(專案)' },
    path: '/account-mgmt-project-user',
  },
  {
    name: 'AccountMgmtGroup',
    meta: { name: '使用者群組' },
    path: '/account-mgmt-group',
  },
  {
    name: 'ProjectMgmt',
    meta: { name: '專案管理' },
    path: '/project-mgmt',
  },
  {
    name: 'UserLoginLog',
    meta: { name: '使用者登入資料' },
    path: '/user-log',
  },
  {
    meta: { name: '資訊總覽' },
    path: '/:catchAll(.*)*',
    redirect: 'Overview',
  },
]

export default config
