var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ADMIN_ROLE)?_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',[_c('DevicesMgmtCreateIntersectionUpload',{attrs:{"tableList":_vm.tableList},on:{"refreshList":_vm.getIntersectionList}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("已建立路口 ("+_vm._s(_vm.totalElements)+")")])])],1),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__tableTop"},[_c('div',{staticClass:"table__tableTop--searchInput"},[_c('v-text-field',{attrs:{"placeholder":"搜尋路口/供電車","outlined":"","dense":"","height":"32","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.search === '')?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',{on:{"click":function($event){_vm.search = ''}}},[_vm._v("mdi-close")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('downloadCsv',{staticClass:"table__tableTop--exportReport",attrs:{"bom":true,"data":_vm.CSVTable,"name":"已建立路口.csv"}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-file-export-outline ")]),_vm._v("匯出列表 ")],1)],1),_c('SearchResult',{attrs:{"search":_vm.search,"searchResultNum":_vm.searchResultNum},on:{"clearSearch":function($event){_vm.search = ''}}}),_c('v-data-table',{staticClass:"mt-7 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"footer-props":{ 'items-per-page-options': [15, 50, 100, -1] },"search":_vm.search},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.latitude",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',[_vm._v(_vm._s(item.longitude))]),_c('span',[_vm._v(_vm._s(item.latitude))])])]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createTime ? _vm.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updateTime ? _vm.$dayjs(item.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},(_vm.ADMIN_ROLE)?{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.copyLineToken(item)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("複製 Intersection ID")])]),_c('div',{staticClass:"ml-4"},[_c('button',{on:{"click":function($event){return _vm.handleEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]),_c('div',{staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.intersectionStatus === 'NOT_INSTALL')?_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.enabledIntersection(item.intersectionLabel, item.intersectionId)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1):_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.disabledIntersection(item.intersectionLabel, item.intersectionId)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("目前為"+_vm._s(item.intersectionStatus === 'NOT_INSTALL' ? '停用' : '啟用')+"狀態")])])],1),_c('div',{staticClass:"ml-4"},[(item.intersectionStatus === 'NOT_INSTALL')?_c('button',{on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',{attrs:{"icon":"fas fa-plus mdi-delete-outline"}},[_vm._v("mdi-delete-outline")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('v-icon',{attrs:{"icon":"fas fa-plus mdi-delete-off-outline"}},[_vm._v("mdi-delete-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("因路口已啟用，故不能刪除")])])],1)],1)]}}:null],null,true)})],1),_c('Dialog',{attrs:{"dialogStatus":_vm.dialogStatus,"width":600,"title":_vm.dialogTitle},on:{"closeDialog":function($event){_vm.dialogStatus = false}}},[_c('EditIntersection',{attrs:{"editTemp":_vm.editTemp},on:{"closeDialogAndRefreshList":_vm.closeDialogAndRefreshList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }