














































































import { useAppStore } from '@/store/app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import DateTimePicker from '@/components/DateTimePicker.vue'
import { ErrorTypeLabel } from '@/constants/report'

interface reportItem {
  createTime: string
  reportType: string
}

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class EventAndAlarmInfo extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  dateRangeValue: number[] = []
  startTime = 0
  endTime = 0

  selectAll = false
  reportTypeList = Object.values(ErrorTypeLabel).map((i) => ({ label: i, value: i }))
  selectedReportList: string[] = []

  tableItems: reportItem[] = []
  tableHeader = [
    { text: '產出日期', value: 'createTime', align: 'left' },
    { text: '報表類型', value: 'reportType', sortable: false, align: 'left' },
    { text: '檔案下載', value: 'handler', sortable: false, align: 'left' },
  ]

  getAPIList: any[] = []
  CSVname = ''
  CSVTable: any[] = []

  @Watch('selectedReportList')
  onChange() {
    this.selectAll = this.selectedReportList.length === this.filterAdminTypeList.length
  }

  get filterAdminTypeList() {
    if (JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN') {
      return this.reportTypeList
    } else {
      return this.reportTypeList.filter((i) => i.label !== '歷史告警資訊')
    }
  }

  disableDate(date: Date) {
    const currentMonth = this.$dayjs().startOf('month').format('YYYY-MM')
    const selectedMonth = this.$dayjs(date).startOf('month').format('YYYY-MM')

    return selectedMonth >= currentMonth
  }

  calculateRangeTime(startTime: number, endTime: number) {
    if (endTime > startTime) {
      const diffTime = endTime - startTime

      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60)

      return days > 0 ? `${days}D : ${hours}H : ${minutes}M` : `${hours}H : ${minutes}M`
    }
  }

  toggleSelectAll() {
    if (this.selectAll) {
      this.selectedReportList = this.filterAdminTypeList.map((option) => option.value)
    } else {
      this.selectedReportList = []
    }
  }

  async handleSearch() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    const startDate = this.$dayjs(this.dateRangeValue[0]).format('YYYY-MM')
    const endDate = this.$dayjs(this.dateRangeValue[1]).format('YYYY-MM')

    this.tableItems = this.generateTableItems(startDate, endDate)
  }

  generateTableItems(startDate: string, endDate: string) {
    const tableItems = []

    for (let month = new Date(startDate); month <= new Date(endDate); month.setMonth(month.getMonth() + 1)) {
      const yearMonth = month.toISOString().slice(0, 7).replace('-', '/')
      for (const item of this.selectedReportList) {
        tableItems.push({ createTime: yearMonth, reportType: item })
      }
    }

    return tableItems
  }

  checkTelemetry(count: number) {
    if (count === 0) {
      this.$swal.fire({
        title: `${this.CSVname}尚無資料`,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  async handleDownload(item: reportItem) {
    this.CSVname = ''
    this.CSVTable = []
    this.getAPIList = []

    this.startTime = this.$dayjs(item.createTime).startOf('month').valueOf()
    this.endTime = this.$dayjs(item.createTime).endOf('month').valueOf()

    switch (item.reportType) {
      case '斷電事件清單':
        this.CSVname = `${this.$dayjs(item.createTime).format('YYYY_MM')}_斷電事件清單`
        await this.getInputErrorLog()
        break

      case '斷電事件資訊總表':
        this.CSVname = `${this.$dayjs(item.createTime).format('YYYY_MM')}_斷電事件資訊總表`
        await this.getErrorEventLog()
        break

      case '歷史告警資訊':
        this.CSVname = `${this.$dayjs(item.createTime).format('YYYY_MM')}_歷史告警資訊`
        await this.getEventLogList()
        break

      case '異常事件統計':
        this.CSVname = `${this.$dayjs(item.createTime).format('YYYY_MM')}_異常事件統計`
        await this.getEventErrorStatistics()
        break

      default:
        break
    }

    this.doDownLoad()
  }

  doDownLoad() {
    const downloadCsv = document.getElementById('downloadCsv')
    downloadCsv && downloadCsv.click()
  }

  // 斷電事件清單
  async getInputErrorLog() {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getInputErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        isFilterMobile: true,
      })

      if (this.checkTelemetry(data!.length)) return

      this.CSVTable = data!.map((i, index) => ({
        項次: index + 1,
        路口編號: i.intersectionNumber,
        路口名稱: i.intersectionLabel,
        ['行政區/移動式供電車']: i.intersectionGroupLabel === 'MOBILE' ? '供電車' : i.intersectionGroupLabel,
        緯度: i.latitude,
        經度: i.longitude,
        開始日期: i.startTime ? this.$dayjs(i.startTime).format('YYYY年 MM月 DD日') : '---/--/--',
        開始時間: i.startTime ? this.$dayjs(i.startTime).format('HH時 mm分 ss秒') : '--:--:--',
        結束日期: i.endTime ? this.$dayjs(i.endTime).format('YYYY年 MM月 DD日') : '---/--/--',
        結束時間: i.endTime ? this.$dayjs(i.endTime).format('HH時 mm分 ss秒') : '--:--:--',
        持續時間: this.calculateRangeTime(i.startTime, i.endTime),
        期間平均功率: i.avgUpsOutput ? i.avgUpsOutput : '-',
        換電次數: i.changeTimes,
        消耗電量: i.totalPower ? `${Math.round(i.totalPower)} %` : '-',
      }))
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  // 斷電事件資訊總表
  async getErrorEventLog(page = 0) {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        sort: 'DESC',
        pageSize: 100,
        page,
        intersectionId: '',
      })

      if (data === undefined) throw new TypeError()
      if (this.checkTelemetry(data.totalElements)) return

      this.getAPIList = [...this.getAPIList, ...data.data]

      if (data.hasNext) {
        await this.getErrorEventLog(page + 1)
      } else {
        this.CSVTable = this.getAPIList.map((i, index) => ({
          項次: index + 1,
          路口編號: i.intersectionNumber,
          路口名稱: i.intersectionLabel,
          發生日期: i.createTime ? this.$dayjs(i.createTime).format('YYYY年 MM月 DD日') : '---/--/--',
          發生時間: i.createTime ? this.$dayjs(i.createTime).format('HH時 mm分 ss秒') : '--:--:--',
          訊息: i.eventDescription,
          路口電量: i.avgBatteryLevel ? `${Math.round(i.avgBatteryLevel)} %` : '-',
        }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  // 歷史告警資訊
  async getEventLogList(page = 0) {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        sort: 'DESC',
        pageSize: 100,
        page,
      })

      if (data === undefined) throw new TypeError()
      if (this.checkTelemetry(data.totalElements)) return

      this.getAPIList = [...this.getAPIList, ...data.data]

      if (data.hasNext) {
        await this.getEventLogList(page + 1)
      } else {
        this.CSVTable = this.getAPIList.map((i, index) => ({
          項次: index + 1,
          路口編號: i.intersectionNumber,
          路口名稱: i.intersectionLabel,
          ['行政區/移動式供電車']: i.intersectionGroupLabel === 'MOBILE' ? '供電車' : i.intersectionGroupLabel,
          經度: i.longitude,
          緯度: i.latitude,
          事件類別: i.eventDescription,
          發生日期: i.createTime ? this.$dayjs(i.createTime).format('YYYY年 MM月 DD日') : '---/--/--',
          發生時間: i.createTime ? this.$dayjs(i.createTime).format('HH時 mm分 ss秒') : '--:--:--',
        }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  // 異常事件統計
  async getEventErrorStatistics() {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventErrorStatistics({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
      })

      if (this.checkTelemetry(data!.length)) return

      this.CSVTable = data!.map((i, index) => ({
        項次: index + 1,
        路口編號: i.intersectionNumber,
        路口名稱: i.intersectionLabel,
        日期: i.createTime ? this.$dayjs(i.createTime).format('YYYY年 MM月 DD日') : '---/--/--',
        UPS斷線: i.upsDisconnectCount,
        電池斷線: i.batteryDisconnectCount,
        市電異常: i.inputErrorCount,
      }))
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }
}
