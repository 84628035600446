



































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import { IGetIntersectionItem, IIntersectionPutUpdateRequest } from '@/types/api/intersection'
import { WritableState } from '@/util/annotations'
import Select from '@/components/DefaultSelect.vue'

@Component({
  components: {
    Select,
  },
})
export default class editIntersection extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ required: true, type: Object }) editTemp!: IGetIntersectionItem

  temp = JSON.parse(JSON.stringify(this.editTemp))

  get intersectionGroups() {
    return Object.entries(JSON.parse(localStorage.getItem('intersectionGroup')!)).map(([key, value]) => ({ label: value, value: key }))
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    const query: IIntersectionPutUpdateRequest = {
      projectId: JSON.parse(localStorage.getItem('projectId')!),
      updateIntersectionList: [
        {
          intersectionId: this.editTemp.intersectionId,
          groupId: this.temp.groupId,
          intersectionLabel: this.temp.intersectionLabel,
          latitude: this.temp.latitude,
          longitude: this.temp.longitude,
        },
      ],
    }

    await this.doSubmit(query)
  }

  async doSubmit(query: IIntersectionPutUpdateRequest) {
    try {
      this.isPageLoading = true
      await this.$api.intersection.update(query)
      await this.$swal.fire({
        title: '編輯成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })
      await this.$emit('closeDialogAndRefreshList')
    } catch (error: any) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }
}
