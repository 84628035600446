

// #region
export enum InputStatusLabel {
  '正常' = '正常',
  '異常' = '異常',
}

export const InputStatusCodeLabelMap = new Map([
  [0, InputStatusLabel.正常],
  [1, InputStatusLabel.異常],
])

export const InputStatusConfigMap = new Map([
  [
    InputStatusLabel.正常,
    {
      color: '--v-success-base',
      icon: 'mdi-check-bold',
      filter: (inputs: Array<any>, key: string) => inputs.filter((input) => Reflect.get(input, key) === InputStatusLabel.正常),
    },
  ],
  [
    InputStatusLabel.異常,
    {
      color: '--v-error-base',
      icon: 'mdi-close',
      filter: (inputs: Array<any>, key: string) =>
        inputs.filter((input) => Reflect.get(input, key) === InputStatusLabel.異常 || Reflect.get(input, 'key').startsWith('1')),
    },
  ],
])
// #endregion
