

















import { Component, Vue, Prop } from 'vue-property-decorator'

export interface IChipsSelectorItem {
  label: string
  value: string
  color: string
  icon: string
}

@Component
export default class ChipsSelector extends Vue {
  @Prop({ type: String, required: true }) value!: string // value
  @Prop({ type: Array, required: true }) items!: IChipsSelectorItem[]
}
