



































import { XLSXReader } from '@/util/helpers'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IFileData } from '@/types/api/uploadFile'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv } })
export default class UploadBatch extends Vue {
  @Prop({ type: Boolean }) value!: boolean
  @Prop({ type: String }) csvName!: string
  @Prop({ type: Array }) csvExample!: any

  files: IFileData[] = []
  idx = -1
  inputRef!: HTMLInputElement
  isParsing = false

  @Watch('files')
  onFilesChange(v: File[]) {
    this.$emit('change', v)
  }

  onFileRemove(idx: number) {
    this.files.splice(idx, 1)
  }

  onFileUpload(idx?: number) {
    if (this.$refs.fileUploader instanceof HTMLInputElement) {
      this.inputRef = this.$refs.fileUploader
      this.$refs.fileUploader.click()
    }
    if (idx === undefined) {
      this.idx = this.files.length
    } else {
      this.idx = idx
    }
  }

  async onFileChange() {
    if (this.inputRef.files === null) {
      return
    }

    try {
      this.$emit('loading', true)

      const allowedExtensions = /(\.csv)$/i
      const [file] = this.inputRef.files

      if (!allowedExtensions.exec(file.name)) {
        this.$swal.fire({
          title: '請使用下載的範本',
          icon: 'warning',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        this.inputRef.files = null
        return
      }

      const result = await XLSXReader.read(file)
      const { Sheet1 } = result
      const filterSheet1 = Sheet1.filter((i: string | number[]) => i.length > 0)
      const msg = `檔案包含${filterSheet1.length - 1}筆資料`

      result.Sheet1 = filterSheet1

      this.files.splice(this.idx, 1, {
        name: file.name,
        msg,
        result,
        file,
      })
      this.inputRef.value = null as any
    } catch (e) {
      console.error(e)
    } finally {
      this.$emit('loading', false)
    }
  }
}
