import Vue from 'vue'
import Router from 'vue-router'
import { config as routeConfig } from './routesConfig'
import { beforeEach } from './routerGuard'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      component: () => import('@/views/Login.vue'),
      path: '/login',
    },
    {
      component: () => import('@/views/SendEmail.vue'),
      path: '/sendEmail',
    },
    {
      component: () => import('@/views/ResetPassword.vue'),
      path: '/resetPassword',
    },
    {
      component: () => import('@/layouts/default/Index.vue'),
      children: routeConfig.map((route) => ({
        ...route,
        component: () => import('@/views/' + route.name),
      })),
      path: '',
    },
  ],
})

router.beforeEach(beforeEach)

export default router
