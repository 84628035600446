export enum DeviceType {
  'gateway' = 'gateway',
  'ups' = 'ups',
  'battery' = 'battery',
}
export enum SelectedType {
  'input' = 'input',
  'battery' = 'battery',
  'ups' = 'ups',
}
