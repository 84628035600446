































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Label from '@/components/Label.vue'
import { IDeviceInstallFile } from '@/types/device'
import ImageDialog from '@/components/ImageDialog.vue'

@Component({ components: { Label, ImageDialog } })
export default class ConstructionImage extends Vue {
  @Prop({ type: String }) intersectionId!: string
  @Prop({ type: Array }) value!: IDeviceInstallFile[]
  @Prop({ type: Boolean }) ADMIN_ROLE!: boolean
  @Prop({ type: String, default: '' }) dialogTitle!: string

  files: string[] = []
  fileInputRef!: HTMLInputElement
  fileReader = new FileReader()
  previewIdx = -1
  dialogStatus = false
  fileImageBase64 = ''

  get previewDialogVisibility() {
    return this.previewIdx > -1
  }

  set previewDialogVisibility(v: boolean | number) {
    if (typeof v === 'number') {
      this.previewIdx = v
    } else if (v === false) {
      this.previewIdx = -1
    }
  }

  get previewDialogData() {
    return this.files[this.previewIdx] || this.value[this.previewIdx]
  }

  get filesPreviewView() {
    if (this.value[0] !== undefined && this.value.length > 0) {
      this.files = this.value.map((file) => file.toString())
    }

    return [...this.files, undefined]
  }

  openDialog(file: string) {
    this.dialogStatus = true
    this.fileImageBase64 = file
  }

  compressAndEncode(file: File, maxWidth: number, maxHeight: number) {
    const MAX_QUALITY = 1
    const QUALITY_STEP = 0.05
    const TARGET_SIZE = 1 * 1024 * 1024 // 目標一張圖片大小 1MB 以內

    let currentQuality = MAX_QUALITY

    return new Promise((resolve, reject) => {
      const image = new Image()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

      image.onload = () => {
        let width = image.width
        let height = image.height

        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }

        let dataURL = ''

        const compressAndCheck = () => {
          canvas.width = width
          canvas.height = height
          ctx.drawImage(image, 0, 0, width, height)

          //  通話指定的壓縮大小，把圖片轉換成 baseURL
          dataURL = canvas.toDataURL('image/jpeg', currentQuality)

          // 計算圖片大小並確認 size 在 1MB 以內
          const sizeInBytes = this.getBase64Bytes(dataURL)

          if (sizeInBytes <= TARGET_SIZE || currentQuality <= 0) {
            resolve(dataURL)
          } else {
            // 如果圖片 size 仍然超過 2MB 就繼續降低圖片品質
            currentQuality -= QUALITY_STEP
            setTimeout(compressAndCheck, 0)
          }
        }

        // 開始重複執行確認、壓縮、檢查
        compressAndCheck()
      }

      image.onerror = () => {
        reject(new Error('圖片加載失敗'))
      }

      image.src = URL.createObjectURL(file)
    })
  }

  getBase64Bytes(base64String: string): number {
    const binaryString = base64String.indexOf(',') + 1
    const base64 = base64String.slice(binaryString)
    return base64.length
  }

  onFileUpload(idx: number) {
    if (this.files.length === 4) return
    if (!this.intersectionId) {
      this.$swal({
        title: '請選擇路口',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
      })
      return
    }

    const ref = this.$refs.fileUploader
    if (ref instanceof HTMLInputElement && this.fileInputRef === undefined) {
      this.fileInputRef = ref
    }
    this.fileInputRef.click()
  }

  async onFileChange() {
    if (this.fileInputRef.files === null) return

    const [file] = this.fileInputRef.files
    const base64 = (await this.compressAndEncode(file, 2000, 2000)) as string

    this.fileInputRef.value = null as any
    this.files.push(base64)
    this.$emit('change', this.files)
  }

  onRemoveFile(idx: number) {
    this.files.splice(idx, 1)
    this.$emit('change', this.files)
  }
}
