





























































import { Component, Vue } from 'vue-property-decorator'
import EditGroup from './edit.vue'
import { useAppStore } from '@/store/app'
import { IGetUserGroupItemResponse, IUserItem } from '@/types/api/user'
import SearchResult from '@/components/SearchResult.vue'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    SearchResult,
    EditGroup,
  },
})
export default class tableList extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  appStore = useAppStore()

  project = ''
  showTableStatus = true
  expanded = []
  search = ''
  searchResultNum = 0

  tableHeader = [
    { text: '群組名稱', value: 'userGroupLabel', sortable: false, align: 'left' },
    { text: '成員數', value: 'userLabel', sortable: false, align: 'left' },
    { text: '群組建立時間', value: 'createTime', align: 'left' },
    { text: '群組成員', value: 'userDetailList', sortable: false, align: 'left' },
    { text: '', value: 'email', sortable: false, align: 'left' },
    { text: '', value: 'handler', align: 'end', sortable: false },
  ]

  userList: IUserItem[] = []
  userGroupList: IGetUserGroupItemResponse[] = []
  editTemp: IGetUserGroupItemResponse = {
    lineToken: '',
    projectId: '',
    userGroupId: '',
    userGroupLabel: '',
    createTime: 0,
    updateTime: 0,
    userIdList: [],
    userDetailList: [],
  }

  get tableList() {
    return this.userGroupList.map((i) => ({
      ...i,
      userGroupLabel: i.userGroupLabel,
      createTime: i.createTime ? this.$dayjs(i.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
      updateTime: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
      userDetailList: this.userList.filter((x) => i.userIdList.includes(x.userId)),
      userLabel: this.userList.filter((x) => i.userIdList.includes(x.userId)).map((o) => o.userLabel),
      email: this.userList.filter((x) => i.userIdList.includes(x.userId)).map((o) => o.email),
    }))
  }

  getFiltered(e: IGetUserGroupItemResponse[]) {
    this.searchResultNum = e.length
  }

  async copyLineToken(item: IGetUserGroupItemResponse) {
    await navigator.clipboard.writeText(item.lineToken)
    await this.$swal.fire({
      title: '已複製 Line Token',
      icon: 'success',
      toast: true,
      position: 'top',
      timer: 1500,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }

  async handleEdit(item: IGetUserGroupItemResponse) {
    this.showTableStatus = false
    this.editTemp = JSON.parse(JSON.stringify(item))
  }

  async refreshList() {
    this.showTableStatus = true
    await this.getUserGroupList()
  }

  async handleDeleteGroup(item: IGetUserGroupItemResponse) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除 ${item.userGroupLabel}?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true

        const deleteQuery = {
          projectId: JSON.parse(localStorage.getItem('projectId')!),
          userGroupId: item.userGroupId,
        }

        await this.$api.user.deleteGroup(deleteQuery)
        await this.$swal.fire({
          title: '刪除成功',
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })
        await this.getUserGroupList()
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  async getUserGroupList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getGroupList({ projectId: this.project })
      this.userGroupList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getUserList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getUserList({ projectId: this.project })
      this.userList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.project = JSON.parse(localStorage.getItem('projectId')!)

    if (this.project) {
      await this.getUserGroupList()
      await this.getUserList()
    }
  }
}
