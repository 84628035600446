<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    titleTemplate: '%s | ' + process.env.VUE_APP_SYSNAME,
    htmlAttrs: { lang: 'en' },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
  },
}
</script>

<style lang="scss">
.swal2-icon {
  font-size: 10px !important;
}

.swal2-actions {
  button {
    margin: 0 16px;
  }
}
</style>
