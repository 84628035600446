

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VNode } from 'vue'
import { IMarkerOption, GMapAdvancedMarkers } from './GMapAdvancedMarkers'
import GMapClass from './GMap'
import { openMarkerLabel, closeMarkerLabel } from './GMapMarkersLabelOverlayView'

@Component
export default class GMap extends Vue {
  @Prop({ type: String, required: true }) value!: string // activedMarkerId
  @Prop({ type: Array }) markerItemOpts!: IMarkerOption[]
  @Prop({ type: Array }) replacingMarkerItemOpts!: IMarkerOption[] // 需要取代的
  @Prop({ type: Object }) labelVNode?: VNode
  @Prop({ type: Object, default: () => ({ lat: 25.03746, lng: 121.564558 }) }) location!: google.maps.LatLngLiteral // taipei
  @Prop({ type: Number, default: 12 }) zoom!: number

  gmapIns!: google.maps.Map

  //backToDefaultExtent 和 flyTo 方法：用於移動地圖到特定位置和縮放級別
  backToDefaultExtent() {
    GMapClass.flyTo(this.gmapIns, this.location, this.zoom)
  }

  flyTo(location = this.location, zoom = this.zoom) {
    GMapClass.flyTo(this.gmapIns, location, zoom)
  }

  isLoading = true

  async mounted() {
    const mapRef = await new Promise<any>((resolve) => this.$nextTick(() => resolve(this.$refs.gmap)))

    this.gmapIns = await GMapClass.createMap(mapRef, {
      mapId: 'uuid',
      center: this.location,
      clickableIcons: false,
      disableDefaultUI: true,
      zoom: this.zoom,
    })

    this.initMarkers()
    this.backToDefaultExtent()
    this.onValueChange()

    this.$emit('loaded', this)
  }

  @Watch('markerItemOpts', { deep: true })
  async checkOptionMark() {
    this.initMarkers()
  }

  @Watch('replacingMarkerItemOpts')
  async onReplacingMarkerItemOptsChange() {
    if (this.replacingMarkerItemOpts.length === 0) {
      return
    }

    if (GMapClass.isLoading) {
      return
    }

    if (this.markerItemOpts.length === 0) {
      GMapAdvancedMarkers.setAllMarkersVisibility(false)
    }

    for (const { id } of this.replacingMarkerItemOpts) {
      GMapAdvancedMarkers.destroy(id)
    }

    GMapAdvancedMarkers.addMarkersToCluster(
      this.transformOption(this.replacingMarkerItemOpts.filter((r) => this.markerItemOpts.findIndex((x) => x.id === r.id) > -1)),
      this.gmapIns
    )

    this.activeMark()
    this.openMarkerLabel()
  }

  //初始化標記
  initMarkers() {
    this.isLoading = GMapClass.isLoading
    // early than component mounted
    if (GMapClass.isLoading) {
      return
    }
    closeMarkerLabel()
    GMapAdvancedMarkers.setClusterMarkers(this.transformOption(this.markerItemOpts), this.gmapIns)
    this.activeMark()
    this.openMarkerLabel()
  }

  //當 value 屬性變化時更新標記
  @Watch('value')
  onValueChange() {
    this.isLoading = GMapClass.isLoading
    // early than component mounted
    if (GMapClass.isLoading) {
      return
    }
    GMapAdvancedMarkers.deActiveAllMarkers()
    this.activeMark()
    this.openMarkerLabel()
  }

  // activeMark 和 openMarkerLabel 方法：觸發和打開標記的標籤
  activeMark() {
    GMapAdvancedMarkers.setMarkerStatus(this.value, true)
  }

  openMarkerLabel() {
    const mark = GMapAdvancedMarkers.idMarkerMap.get(this.value)
    if (this.labelVNode !== undefined && mark !== undefined) {
      openMarkerLabel({
        mark,
        vnode: this.labelVNode,
      })
    }
  }

  onMarkerClick(marker: GMapAdvancedMarkers) {
    this.$emit('input', marker.id)
  }

  //轉換標記選項以便在地圖上使用
  transformOption(arr: IMarkerOption[]) {
    return arr.map((opt) => ({
      ...opt,
      isNotDrawingOnMap: true,
      onClick: this.onMarkerClick,
      labelVNode: this.labelVNode,
    }))
  }

  //在組件銷毀前清除所有標記
  beforeDestroy() {
    // console.log('if unmount , clear all markers load in memory at the beginning')
    GMapAdvancedMarkers.destroyAll()
  }
}
