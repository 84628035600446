



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Upload extends Vue {
  @Prop({ type: String, default: '' }) value!: 'manual' | 'batch' | ''

  get checkAdminStatus() {
    const BMS_SYSTEM_ADMIN = JSON.parse(localStorage.getItem('userRoleGroupName')!)
    const BMS_PROJECT_ADMIN = JSON.parse(localStorage.getItem('userRoleGroupName')!)

    return BMS_SYSTEM_ADMIN === 'BMS_SYSTEM_ADMIN' ? true : BMS_PROJECT_ADMIN === 'BMS_PROJECT_ADMIN'
  }

  get uploadBtnProps() {
    return {
      rounded: true,
      large: true,
      outlined: this.value !== 'batch',
      color: this.value === 'batch' ? 'primary' : '',
    }
  }

  get uploadManualBtnProps() {
    return {
      rounded: true,
      large: true,
      outlined: this.value !== 'manual',
      color: this.value === 'manual' ? 'primary' : '',
    }
  }
}
