import { axiosIns } from '../axiosIns'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import { useUserStore } from '@/store/user'

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      throw errorData
    } else {
      return data as T
    }
  }

  throw new Error(`HTTP status code: ${status}`)
}

export default {
  getCaptcha: async () => {
    return await axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/noauth/generate-validate-image`)
  },

  login: async (payload: { email: string; secret: string; captcha: string }) => {
    return await handleResponse<string>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/noauth/token`, payload))
  },

  getRefreshedAccessToken: async () => {
    try {
      const { data } = await axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/noauth/token/refresh`)

      // 檢查 data 是否為字符串，如果是則直接返回
      if (typeof data === 'string') {
        return data
      }

      // 檢查是否為特定的錯誤物件
      if (data.errorCode && +data.errorCode > 1000) {
        useUserStore().logout()
        return
      }
    } catch (error) {
      console.error(error)
    }
  },
}
