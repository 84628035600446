

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IntersectionConfigMap, IntersectionStatusLabelMap, INTERSECTION_LABEL, INTERSECTION_STATUS } from '@/constants/intersection'
import { IGetIntersectionItem } from '@/types/api/intersection'
import OverviewMapMarkerLabel from './OverviewMapMarkerLabel.vue'
import Gmap from '@/components/GMap/GMap.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import GMapVueMarker from '@/components/GMap/GMapVueMarker.vue'
import { getComputedColor } from '@/util/helpers'
import ChipsSelector from '@/components/ChipsSelector.vue'
import { useIntersectionStore } from '@/store/intersection'
import { GMapAdvancedMarkers } from '@/components/GMap/GMapAdvancedMarkers'
import { WatchPlainArrDiff } from '@/util/annotations'
import { useUserStore } from '@/store/user'

interface IIntersectionListView extends IGetIntersectionItem {
  labelIconColor: string
  valueIcon: string
  valueIconColor: string
  batteryLevel: string
}

const defaultIntersectionListView = {
  labelIconColor: '#00ce8e',
  valueIcon: 'mdi-battery',
  valueIconColor: '--v-info-base',
  batteryLevel: '-',
}

@Component({
  components: { Gmap, AutoComplete, ChipsSelector },
})
export default class OverviewMap extends Vue {
  @Prop({ type: String }) value!: string // binding actived intersection id
  @Prop({ type: Array }) intersectionList!: IGetIntersectionItem[]
  @Prop({ type: String }) intersectionUpsEnableTimeActived!: string
  @Prop({ type: String }) intersectionUpsEnableDurationActived!: string

  intersectionStatus: INTERSECTION_LABEL | '' = ''
  intersectionStatusItems = [
    {
      label: '全部',
      value: '',
      color: 'grey',
      icon: 'mdi-map-marker',
    },
    ...Object.values(INTERSECTION_LABEL).map((intersectionStatusLabel) => {
      const { icon, color } = IntersectionConfigMap.get(intersectionStatusLabel)!
      return {
        label: intersectionStatusLabel,
        value: intersectionStatusLabel,
        icon,
        color,
      }
    }),
  ]

  get filteredIntersectionList() {
    const list = this.intersectionList
    if (this.intersectionStatus === '') {
      return list
    }
    const { filter } = IntersectionConfigMap.get(this.intersectionStatus)!
    return filter(list)
  }

  getComputedColor = getComputedColor

  get intersectionListView(): IIntersectionListView[] {
    return this.filteredIntersectionList
      .filter((o) => o.intersectionType === this.intersectionStore.intersectionType)
      .map((intersection) => {
        // const { icon, color } = IntersectionConfigMap.get(IntersectionStatusLabelMap.get(intersection.intersectionStatus)!)!
        return {
          ...intersection,
          ...defaultIntersectionListView,
          batteryLevel: this.parseBatteryLevel(intersection.avgBatteryLevel),
        }
      })
  }

  get searchIntersectionModel() {
    return this.value
  }

  set searchIntersectionModel(intersectionId: string) {
    if (!intersectionId) {
      return
    }
    this.$emit('input', intersectionId)
  }

  get intersectionItemActived() {
    return this.intersectionList.find((i) => i.intersectionId === this.value)
  }

  parseBatteryLevel(l: any) {
    return `${l ? l.toFixed(1) : '0'}%`
  }

  private mapToMarkerItemOpts(list: IGetIntersectionItem[]) {
    return list.map((x) => {
      let color, icon

      if (x.intersectionType === 'FIXED') {
        console.log(x.intersectionType === 'FIXED')
        const { color: FIXED_COLOR, icon: FIXED_ICON } = IntersectionConfigMap.get(
          IntersectionStatusLabelMap.get(x.intersectionStatus as INTERSECTION_STATUS)!
        )!
        color = FIXED_COLOR
        icon = FIXED_ICON
      } else {
        color = '#00ce8e'
        icon = 'mdi mdi-car-electric'
      }

      return {
        id: x.intersectionId,
        position: {
          lat: +x.latitude,
          lng: +x.longitude,
        },
        panOffset: [240, 0],
        vnode: <GMapVueMarker color={color} icon={icon} />,
      }
    })
  }

  @WatchPlainArrDiff('intersectionList', 'intersectionId')
  diffIntersectionList: IGetIntersectionItem[] = []

  get replacingMarkerItemOpts() {
    const list =
      this.intersectionStore.intersectionType === 'MOBILE'
        ? this.intersectionListView.filter((i) => i.intersectionType === 'MOBILE')
        : this.diffIntersectionList.filter((i) => i.intersectionType === 'FIXED')

    return this.mapToMarkerItemOpts(list)
  }

  get markerItemOpts() {
    const list =
      this.intersectionStore.intersectionType === 'MOBILE'
        ? this.intersectionListView.filter((i) => i.intersectionType === 'MOBILE')
        : this.intersectionListView.filter((i) => i.intersectionType === 'FIXED')

    return this.mapToMarkerItemOpts(list)
  }

  get labelVNode() {
    return (
      <OverviewMapMarkerLabel
        intersectionLabel={this.intersectionItemActived?.intersectionLabel}
        batteryAvgLevel={this.parseBatteryLevel(this.intersectionItemActived?.avgBatteryLevel)}
        intersectionStatus={this.intersectionItemActived?.intersectionStatus}
        upsEnableTime={this.intersectionUpsEnableTimeActived}
        upsEnableDuration={
          this.intersectionItemActived?.intersectionType === 'FIXED'
            ? this.intersectionUpsEnableDurationActived
            : this.intersectionItemActived
            ? this.formatMinutes(this.intersectionItemActived.upsEnableTimeMinutes)
            : '--:--'
        }
        on={{
          onMoreBtnClick: () => {
            this.$router.push({
              path: '/map-mgmt',
              query: {
                id: this.intersectionItemActived?.intersectionId,
                type: this.intersectionStore.intersectionType,
              },
            })
          },
        }}
      />
    )
  }

  formatMinutes(minutes: number) {
    const duration = this.$dayjs.duration(minutes, 'minutes')
    const days = duration.days()
    const hours = duration.hours()
    const mins = duration.minutes()

    let result = ''

    if (days > 0) {
      result += `${days}D : `
    }

    if (days > 0 || hours > 0) {
      result += `${hours}H : `
    }

    result += mins < 10 ? `0${mins}m` : `${mins}m`

    return result.trim()
  }

  onMapInput($event: string) {
    this.$emit('input', $event)
  }

  intersectionStore = useIntersectionStore()
  mapCompCtx?: Gmap = undefined

  async onMapLoaded(mapCompCtx: Gmap) {
    this.mapCompCtx = mapCompCtx
    this.flyToActivedMarker()
  }

  /*
  @Watch('intersectionStatus')
  onIntersectionStatusChange() {
    if (this.mapCompCtx === undefined) {
      return
    }
    this.intersectionStore.resetIntersection()

    this.$nextTick(() => {
      this.mapCompCtx!.initMarkers()
      this.mapCompCtx!.backToDefaultExtent()
    })
  }
  */

  userStore = useUserStore()

  get location() {
    return {
      lat: Number(this.userStore.mapInitLatitude),
      lng: Number(this.userStore.mapInitLongitude),
    }
  }

  get zoom() {
    return Number(this.userStore.mapInitZoom)
  }

  /*
  @Watch('userStore.projectId')
  onProjectChange() {
    this.mapCompCtx!.initMarkers()
    this.mapCompCtx!.flyTo(this.location, this.zoom)
  }
  */

  @Watch('intersectionStore.isIntersectionListHasLoaded')
  initMapMarkersAndFit() {
    this.$nextTick(() => {
      if (!this.intersectionStore.isIntersectionListHasLoaded) {
        return
      }
      if (this.mapCompCtx === undefined) {
        return
      }
      // console.log('initMapMarkersAndFit')
      this.mapCompCtx.initMarkers()
      this.mapCompCtx.flyTo(this.location, this.zoom)
      this.flyToActivedMarker()
    })
  }

  @Watch('userStore.projectId')
  @Watch('intersectionStatus')
  forceInitMapMarkersAndFit() {
    if (this.mapCompCtx === undefined) {
      return
    }
    this.intersectionStore.resetIntersection()

    this.$nextTick(() => {
      // console.log('forceInitMapMarkersAndFit')
      this.mapCompCtx!.initMarkers()
      this.mapCompCtx!.flyTo(this.location, this.zoom)
    })
  }

  flyToActivedMarker() {
    // 如果已有被選擇的路口則載入完地圖後定位
    const aIid = this.intersectionStore.intersectionItemActived?.intersectionId
    if (aIid) {
      /** keep the same extent */
      GMapAdvancedMarkers.setMarkerStatus(aIid, true)
    }
  }
}
