



















































import { Component, Vue } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import Combobox from '@/components/Combobox.vue'
import { useAppStore } from '@/store/app'
import { ICreateGroupRequest, IUserItem } from '@/types/api/user'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    LabelTextField,
    Label,
    Combobox,
  },
})
export default class add extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  temp: ICreateGroupRequest = {
    lineToken: '',
    projectId: JSON.parse(localStorage.getItem('projectId')!),
    userGroupLabel: '',
    userIdList: [],
  }

  userList: IUserItem[] = []
  selectedUserIdList: IUserItem[] = []

  filterUserIdList() {
    this.temp.userIdList = this.selectedUserIdList.map((i) => i.userId)
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    try {
      this.isPageLoading = true

      await this.$api.user.createGroup(this.temp)
      await this.$swal.fire({
        title: '新增成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      this.selectedUserIdList = []
      this.temp = {
        lineToken: '',
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        userGroupLabel: '',
        userIdList: [],
      }
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getUserList() {
    try {
      this.isPageLoading = true
      const projectId = JSON.parse(localStorage.getItem('projectId')!)
      const data = await this.$api.user.getUserList({ projectId })
      this.userList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getUserList()
  }
}
