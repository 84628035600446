











































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class OverviewDevices extends Vue {
  @Prop({ type: Number }) checkInstallIntersectionsCnt!: number
  @Prop({ type: Number }) workingIntersectionsCnt!: number
  @Prop({ type: Number }) errorIntersectionsCnt!: number
  @Prop({ type: Number }) mobileCarCnt!: number
  @Prop({ type: Number }) mobileCarUsedCnt!: number
}
