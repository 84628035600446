







































import { Component, Vue } from 'vue-property-decorator'
import { IIntersectionMgmt } from '@/types/api/intersection'
import SearchResult from '@/components/SearchResult.vue'
import { useAppStore } from '@/store/app'
import { WritableState } from '@/util/annotations'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, SearchResult } })
export default class DevicesMgmtList extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  search = ''
  searchResultNum = 0

  viewHeader = [
    { text: '編號', value: 'intersectionNumber', sortable: false, align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: 'UPS', value: 'upsLabel', sortable: false, align: 'left' },
    { text: 'Gateway', value: 'gatewayLabel', sortable: false, align: 'left' },
    { text: '電池 A', value: 'batteryLabelA', sortable: false, align: 'left' },
    { text: '電池 B', value: 'batteryLabelB', sortable: false, align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  intersectionCurrentList: IIntersectionMgmt[] = []

  get tableList() {
    return this.intersectionCurrentList.map((i) => ({
      ...i,
      intersectionLabel: i.intersectionLabel ? i.intersectionLabel : '-',
      upsLabel: i.upsLabel ? i.upsLabel : '-',
      gatewayLabel: i.gatewayLabel ? i.gatewayLabel : '-',
      batteryLabelA: i.batteryLabelList ? (i.batteryLabelList[0] ? i.batteryLabelList[0] : '-') : '-',
      batteryLabelB: i.batteryLabelList ? (i.batteryLabelList[1] ? i.batteryLabelList[1] : '-') : '-',
    }))
  }

  get CSVTable() {
    return this.tableList.map((i, index) => ({
      編號: index + 1,
      路口名稱: i.intersectionLabel,
      UPS: i.upsLabel,
      Gateway: i.gatewayLabel,
      [`電池 A`]: i.batteryLabelA,
      [`電池 B`]: i.batteryLabelB,
    }))
  }

  getFiltered(e: any) {
    this.searchResultNum = e.length
  }

  async checkDetail(item: IIntersectionMgmt) {
    this.$router.push({
      name: 'DevicesMgmtDetailList',
      query: {
        intersectionLabel: item.intersectionLabel,
        intersectionId: item.intersectionId,
      },
    })
  }

  async getIntersectionCurrentList() {
    try {
      this.isPageLoading = true
      const projectId = JSON.parse(localStorage.getItem('projectId')!)
      const data = await this.$api.intersection.getIntersectionCurrentList({ projectId })
      this.intersectionCurrentList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    const projectId = JSON.parse(localStorage.getItem('projectId')!)

    await (projectId && this.getIntersectionCurrentList())
  }
}
