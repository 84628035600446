






























































































































import { useAppStore } from '@/store/app'
import { Component, Vue } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import { IEventLogResponse } from '@/types/api/event'
import DateTimePicker from '@/components/DateTimePicker.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class EventLog extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  totalElements = 0

  searchIntersectionLabel = ''
  searchIntersectionGroupLabel = ''
  searchEventDescription = ''

  dateRangeValue: number[] = []
  startTime = 0
  endTime = 0

  eventLogs: IEventLogResponse['data'] = []

  tableHeader = [
    { text: '項次', value: 'index', align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '行政區', value: 'intersectionGroupLabel', sortable: false, align: 'left' },
    { text: '事件類別', value: 'eventDescription', sortable: false, align: 'left' },
    { text: '發生時間', value: 'createTime', sortable: false, align: 'left' },
  ]

  get IntersectionGroupLabelList() {
    return this.tableItems.map((i) => i.intersectionGroupLabel)
  }

  get IntersectionLabelList() {
    return this.filteredItems.map((i) => i.intersectionLabel)
  }

  get eventDescriptionList() {
    return this.filteredItems.map((i) => i.eventDescription)
  }

  get filteredItems() {
    return this.tableItems.filter((item) => {
      const intersectionLabel = item.intersectionLabel.toLowerCase().includes(this.searchIntersectionLabel?.toLowerCase())
      const intersectionGroupLabel = item.intersectionGroupLabel.toLowerCase().includes(this.searchIntersectionGroupLabel?.toLowerCase())
      const eventDescription = item.eventDescription.toLowerCase().includes(this.searchEventDescription?.toLowerCase())

      return intersectionLabel && intersectionGroupLabel && eventDescription
    })
  }

  get tableItems() {
    return this.eventLogs.map((i, index) => ({
      ...i,
      index: index + 1,
    }))
  }

  get CSVTable() {
    return this.filteredItems.map((i, index) => ({
      項次: index + 1,
      路口名稱: i.intersectionLabel,
      ['行政區/移動式供電車']: i.intersectionGroupLabel === 'MOBILE' ? '供電車' : i.intersectionGroupLabel,
      經度: i.longitude,
      緯度: i.latitude,
      事件類別: i.eventDescription,
      發生日期: i.createTime ? this.$dayjs(i.createTime).format('YYYY年 MM月 DD日') : '---/--/--',
      發生時間: i.createTime ? this.$dayjs(i.createTime).format('HH時 mm分 ss秒') : '--:--:--',
    }))
  }

  disableDate(date: Date) {
    return date > new Date()
  }

  async handleMaxTimeRange() {
    const firstTime = new Date(new Date(this.dateRangeValue[0]))
    const secondTime = new Date(new Date(this.dateRangeValue[1]))
    const firstTimeValue = this.$dayjs(firstTime).startOf('day').valueOf()
    const secondTimeEndValue = this.$dayjs(secondTime).endOf('day').valueOf()

    const dayDiff = (+secondTime - +firstTime) / 86400000
    const maxTimeRange = (31 * 24 * 60 * 60 * 1000) / 86400000

    if (dayDiff < 0 || dayDiff > maxTimeRange) {
      this.$swal.fire({
        title: '日期範圍最多 31 天',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    this.startTime = +firstTimeValue
    this.endTime = +secondTimeEndValue

    await this.getEventLogList()
  }

  async getEventLogList(page = 0) {
    try {
      this.isPageLoading = true

      if (page === 0) {
        this.eventLogs.splice(0)
      }

      const data = await this.$api.event.getEventLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        sort: 'DESC',
        pageSize: 100,
        page,
      })

      if (data === undefined) {
        throw new TypeError()
      }

      this.totalElements = data.totalElements

      this.eventLogs = [...this.eventLogs, ...data.data]
      if (data.hasNext) {
        await this.getEventLogList(page + 1)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.startTime = +this.$dayjs().subtract(30, 'day').valueOf()
    this.endTime = +this.$dayjs().valueOf()
    this.dateRangeValue = [this.startTime, this.endTime]

    await this.getEventLogList()
  }
}
