var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ADMIN_ROLE)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DevicesMgmtCreateGatewayUpload',{attrs:{"tableList":_vm.tableList},on:{"refreshList":_vm.getDeviceList}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("已建立 Gateway ("+_vm._s(_vm.totalElements)+")")])])],1),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__tableTop"},[_c('div',{staticClass:"table__tableTop--searchInput"},[_c('v-text-field',{attrs:{"placeholder":"搜尋 Gateway","outlined":"","dense":"","height":"32","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.search)?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',{on:{"click":function($event){_vm.search = ''}}},[_vm._v("mdi-close")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('downloadCsv',{staticClass:"table__tableTop--exportReport",attrs:{"bom":true,"data":_vm.CSVTable,"name":"已建立 Gateway.csv"}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-file-export-outline ")]),_vm._v("匯出列表 ")],1)],1),_c('SearchResult',{attrs:{"search":_vm.search,"searchResultNum":_vm.searchResultNum},on:{"clearSearch":function($event){_vm.search = ''}}}),_c('v-data-table',{staticClass:"mt-7 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"footer-props":{ 'items-per-page-options': [15, 50, 100, -1] },"search":_vm.search},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createTime ? _vm.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updateTime ? _vm.$dayjs(item.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},(_vm.ADMIN_ROLE)?{key:"item.handler",fn:function(ref){
var item = ref.item;
return [(item.intersectionId)?_c('button',{staticClass:"mt-3 mb-5 mr-2 deleteBtn",on:{"click":function($event){return _vm.handleUnbind(item)}}},[_c('v-icon',{staticClass:"deleteIcon"},[_vm._v("mdi-lock-open")])],1):_vm._e(),(!item.intersectionId)?_c('button',{staticClass:"mt-3 mb-5 deleteBtn",on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',{staticClass:"deleteIcon"},[_vm._v("mdi-delete-outline")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("因路口未解除綁定，故不能刪除")])])]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }