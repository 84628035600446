
import { Component, Vue } from 'vue-property-decorator'
import { ScopedSlotChildren, VNode } from 'vue/types/vnode'
import { DataTableHeader } from 'vuetify'
import {
  VDataTable,
  VBtn,
  // VIcon
} from 'vuetify/lib'

interface IItem {
  index: number
  item: any
  isMobile: boolean
  headers: DataTableHeader[]
  value: any
}

interface IScopedSlots {
  [key: string]: (props: IItem) => string | ScopedSlotChildren | VNode
}

interface IAction {
  name: string
  on: { [evtName: string]: (item: IItem) => void }
  text: boolean
  rounded: boolean
  class: string | { [name: string]: boolean } // elevation: 0,
  color: string
  disabled: boolean
}

@Component
export default class DataTable extends Vue {
  render() {
    const $headers: DataTableHeader[] = this.$attrs.headers as any
    const scopedSlots: IScopedSlots = {}

    for (const { value: header } of $headers) {
      scopedSlots[`item.${header}`] = (props: IItem) => {
        const s = this.$scopedSlots[`item.${header}`]
        if (s) {
          return s(props)
        }
        if (header === 'actions') {
          return props.value.map((action: IAction) => {
            return (
              <VBtn props={action} on={action.on}>
                {action.name}
              </VBtn>
            )
          })
        }
        return String(props.value)
      }
    }

    return <VDataTable props={this.$attrs} on={this.$listeners} scopedSlots={scopedSlots} />
  }
}
