import { render, staticRenderFns } from "./EventAndAlarmInfo.vue?vue&type=template&id=56e85162&scoped=true&"
import script from "./EventAndAlarmInfo.vue?vue&type=script&lang=ts&"
export * from "./EventAndAlarmInfo.vue?vue&type=script&lang=ts&"
import style0 from "./EventAndAlarmInfo.vue?vue&type=style&index=0&id=56e85162&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e85162",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VCol,VDataTable,VIcon,VRow})
