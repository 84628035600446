





































import { Component, Vue } from 'vue-property-decorator'
import EventErrorLog from './Views/EventErrorLog.vue'
import EventLog from './Views/EventLog.vue'
import InputErrorLog from './Views/InputErrorLog.vue'
import TelemetryTimeSeries from './Views/TelemetryTimeSeries.vue'
import ProjectIntersectionStatusExtremesQuery from './Views/ProjectIntersectionStatusExtremesQuery.vue'

@Component({
  components: {
    EventErrorLog,
    EventLog,
    InputErrorLog,
    TelemetryTimeSeries,
    ProjectIntersectionStatusExtremesQuery,
  },
})
export default class RecordQuery extends Vue {
  tabIndex = 0

  get BMS_SYSTEM_ADMIN() {
    return JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN'
  }

  intersectionId = ''
  mounted() {
    const { id } = this.$route.query
    if (id !== undefined && typeof id === 'string') {
      this.tabIndex = 3
      this.intersectionId = id
    }
  }
}
