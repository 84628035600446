
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IEventErrorLogGetResponse } from '@/types/api/event'
import TimeText from '@/views/IntersectionMgmtDashboard/components/TimeText.vue'
import { useIntersectionStore } from '@/store/intersection'
import api from '@/api/api'

@Component({
  components: { TimeText },
})
export default class indexErrEventList extends Vue {
  @Prop({ type: String }) time!: string
  intersectionStore = useIntersectionStore()

  events: IEventErrorLogGetResponse['data'] = []
  tableList: IEventErrorLogGetResponse['data'] = []

  timer: any = null

  startTime = this.$dayjs().startOf('month').valueOf() // 取得當前月份的第一天
  endTime = this.$dayjs().endOf('month').valueOf() // 取得當前月份的最後一天

  @Watch('time')
  async onChange() {
    this.startTime = this.$dayjs(this.time).startOf('month').valueOf()
    this.endTime = this.$dayjs(this.time).endOf('month').valueOf()

    await this.getRefreshEventErrorLog()
  }

  get title() {
    return `${this.time ? this.$dayjs(this.time).format('YYYY 年 MM ') : this.time}月異常事件列表`
  }

  async setData(page = 0) {
    try {
      const data = await api.event.getEventErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime, // 取得當前月份的第一天
        endTime: this.endTime, // 取得當前月份的最後一天
        sort: 'DESC',
        pageSize: 100,
        page,
        intersectionId: `${this.$route.query.id}`,
      })

      this.events = [...this.events, ...data!.data]

      if (data!.hasNext) {
        await this.setData(page + 1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getRefreshEventErrorLog() {
    try {
      await this.setData()

      this.tableList.splice(0, this.tableList.length, ...this.events)
      this.events.splice(0)

      await this.startTimer()
    } catch (error) {
      console.log(error)
    }
  }

  async startTimer() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    this.timer = setInterval(async () => await this.getRefreshEventErrorLog(), 60 * 1000)
  }

  async mounted() {
    await this.getRefreshEventErrorLog()
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
