









import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { createChart } from '@/util/echartUtil'
import { EChartsOption } from 'echarts'
import { IEventGetWorkLogResponse, IEventInputErrorLogResponse } from '@/types/api/event'
import { UpsStatusCodeLabelMap, UpsStatusLabel } from '@/constants/ups'

@Component
export default class OverviewChartUPS extends Vue {
  @Prop({ type: Array }) inputErrorList!: IEventInputErrorLogResponse[]
  @Prop({ type: Array }) workLogList!: IEventGetWorkLogResponse[]

  upsWorkChartOpts: EChartsOption = {
    grid: {
      left: 0,
      right: 0,
      width: '100%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (param: any) => {
        return `<div>
          <strong>${param[0].name}日</strong>
          ${param
            .map((x: any) => {
              const min = x.data[1]
              const ms = min * 60 * 1000

              return `<div>${x.marker} ${x.seriesName}
                ${min.toFixed(2)} 分
                <small> ${this.$dayjs().duration(Math.abs(ms), 'milliseconds').format('HH:mm:ss', { trim: false })}</small></div>`
            })
            .join('')}
          </div>`
      },
    },
    legend: { width: '100%', bottom: 0, orient: 'horizontal' },
    xAxis: {
      type: 'category',
      axisLabel: { hideOverlap: false, align: 'center' },
    },
    yAxis: { type: 'value' },
    series: [
      {
        type: 'bar',
        name: 'UPS供電時間',
        color: '#556fff',
        data: [],
      },
      {
        type: 'bar',
        name: '市電斷電時間',
        color: '#ff7d4a',
        data: [],
      },
    ],
  }

  initSeries() {
    const [inputErrsAccData, upsWorkAccData] = this.upsWorkChartOpts.series as any
    inputErrsAccData.data = Array.from(Array(this.$dayjs().daysInMonth())).map((_, i) => [i + 1, 0])
    upsWorkAccData.data = Array.from(Array(this.$dayjs().daysInMonth())).map((_, i) => [i + 1, 0])
  }

  async mounted() {
    const ref = await new Promise((resolve) => this.$nextTick(() => resolve(this.$refs.upsWorkChart)))
    if (!(ref instanceof Element)) {
      return
    }
    await createChart.call(this, ref, this.upsWorkChartOpts)
    this.initSeries()
  }

  @Watch('inputErrorList', { immediate: true })
  @Watch('workLogList', { immediate: true })
  async setupsWorkChart() {
    this.initSeries()

    const [{ data: upsWorkAcc }, { data: inputErrsAcc }] = this.upsWorkChartOpts.series as any

    for (const { startTime, endTime } of this.inputErrorList) {
      const idx = dayjs(startTime).date() - 1
      const minute = (endTime - startTime) / 1000 / 60
      inputErrsAcc[idx][1] += minute
    }

    for (const { startTime, endTime, upsStatus } of this.workLogList) {
      const idx = dayjs(startTime).date() - 1
      const upsStatusLabel = UpsStatusCodeLabelMap.get(+upsStatus)
      if (upsStatusLabel === UpsStatusLabel.電池模式 || upsStatusLabel === UpsStatusLabel.市電模式) {
        const minute = (endTime - startTime) / 1000 / 60
        upsWorkAcc[idx][1] += minute
      }
    }
  }
}
