var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Upload',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('template',{slot:"batch"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[[(_vm.intersectionGroups)?_c('div',[_c('UploadBatch',{ref:"UploadBatch",attrs:{"csvExample":_vm.csvExample,"csvName":'新增路口範本.csv'},on:{"change":_vm.onBatchCreate}})],1):_c('div',[_c('p',{staticStyle:{"color":"var(--error)"}},[_vm._v("請至專案管理新增群組")])])]],2),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.filePayload.createIntersectionList.length === 0},on:{"click":_vm.handleSubmitFile}},[_vm._v("建立")])],1)],1)],1),_c('template',{slot:"manual"},[_c('v-radio-group',{attrs:{"row":"","label":"類別 : "},model:{value:(_vm.intersectionType),callback:function ($$v) {_vm.intersectionType=$$v},expression:"intersectionType"}},[_c('v-radio',{attrs:{"label":"新增固定式路口","value":"FIXED","color":"primary"}}),_c('v-radio',{attrs:{"label":"新增移動式供電車","value":"MOBILE","color":"primary"}})],1),_c('ValidationObserver',{ref:"FIXEDForm"},[(_vm.intersectionType === 'FIXED')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"intersectionLabel",attrs:{"name":"路口名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"hide-details":"","solo":"","depressed":"","label":"路口名稱"},model:{value:(_vm.intersectionLabel),callback:function ($$v) {_vm.intersectionLabel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"intersectionLabel"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3096688831)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"intersectionGroupId",attrs:{"name":"群組","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AutoCombobox',{attrs:{"id":"combobox","items":_vm.intersectionGroups,"item-text":'label',"item-value":'value',"hide-details":"","solo":"","dense":"","label":"選擇群組"},model:{value:(_vm.intersectionGroupId),callback:function ($$v) {_vm.intersectionGroupId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"intersectionGroupId"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0])+" "),(!_vm.intersectionGroups)?_c('span',[_vm._v("( 請至專案管理新增群組 )")]):_vm._e()])]}}],null,false,4248755462)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"longitude",attrs:{"name":"經度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"hide-details":"","solo":"","depressed":"","label":"經度"},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"longitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3622685042)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"latitude",attrs:{"name":"緯度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"hide-details":"","solo":"","depressed":"","label":"緯度"},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"latitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2162051997)})],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSubmitInterSection}},[_vm._v("建立")])],1)],1):_vm._e()],1),_c('ValidationObserver',{ref:"MOBILEForm"},[(_vm.intersectionType === 'MOBILE')?_c('v-row',{staticClass:"d-flex align-end"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"batteryCar",attrs:{"name":"供電車名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"hide-details":"","solo":"","depressed":"","label":"供電車名稱"},model:{value:(_vm.intersectionLabel),callback:function ($$v) {_vm.intersectionLabel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"intersectionLabel"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3980208985)})],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSubmitBatteryCar}},[_vm._v("建立")])],1)],1):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }