import vuetify from '@/plugins/vuetify'
import Vue from 'vue'
import { GMapAdvancedMarkers } from './GMapAdvancedMarkers'
import GMap from './GMap'

export interface IGMapMarkersLabelOverlayViewOption {
  mark: GMapAdvancedMarkers
  vnode: Vue.VNode
}

const getClass = (OverlayView: typeof google.maps.OverlayView) => class extends OverlayView {

  private dom!: HTMLDivElement
  private vm!: Vue
  private mark: GMapAdvancedMarkers

  constructor(properties: IGMapMarkersLabelOverlayViewOption) {
    super()

    this.mark = properties.mark
    this.setMap(this.mark.map)
    this.buildVM(properties.vnode)

  }

  /** set up virtual dom by real dom */
  buildVM(vNode: Vue.VNode) {
    this.dom = document.createElement('div')
    this.dom.style.position = 'absolute'
    this.vm = new Vue({
      name: 'vue_label',
      vuetify,
      render: (h) => vNode,
    }).$mount()
    this.dom.append(this.vm.$el)
  }

  /** @override */
  onAdd() {
    const panes = this.getPanes()
    if (panes === null) {
      return
    }
    panes.overlayMouseTarget.appendChild(this.dom)
  }

  /** @override */
  async draw() {
    try {
      const point = this.getProjection().fromLatLngToDivPixel(this.mark.markIns.position!)

      const { x, y } = point!
      this.dom.style.left = `${x + 20}px`
      this.dom.style.top = `${y - 20}px`
      this.dom.style.zIndex = `${GMapAdvancedMarkers.incrementalDomIdx}`
    } catch {
      throw (new Error(" marker label drawing fail !"))
    }
  }

  remove() {
    if (this.dom.parentNode) {
      this.dom.parentNode.removeChild(this.dom)
    }
    this.vm.$destroy()
    this.setMap(null)
  }

}

type GMapMarkersLabelOverlayView = InstanceType<ReturnType<typeof getClass>>
const gMapMarkersLabelOverlayView = (properties: IGMapMarkersLabelOverlayViewOption) => new (getClass(GMap.MapsLibrary.OverlayView))(properties)

export let labelOverlayView: GMapMarkersLabelOverlayView | undefined

export const closeMarkerLabel = () => {
  if (labelOverlayView !== undefined) {
    labelOverlayView.remove()
  }
}

export const openMarkerLabel = (opts: IGMapMarkersLabelOverlayViewOption) => {
  closeMarkerLabel()
  labelOverlayView = gMapMarkersLabelOverlayView(opts)
}
