


































































import { useAppStore } from '@/store/app'
import { Component, Vue } from 'vue-property-decorator'
import { IDeviceItemResponse } from '@/types/api/device'
import DevicesMgmtCreateUpsUpload from './DevicesMgmtCreateUpsUpload.vue'
import { WritableState } from '@/util/annotations'
import SearchResult from '@/components/SearchResult.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { DevicesMgmtCreateUpsUpload, downloadCsv, SearchResult } })
export default class DevicesMgmtCreateUps extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  search = ''
  searchResultNum = 0

  totalElements = 0

  tableList: IDeviceItemResponse[] = []
  tableHeader = [
    { text: '編號', value: 'deviceNumber', align: 'left' },
    { text: 'UPS  ID', value: 'deviceLabel', sortable: false, align: 'left' },
    { text: 'UPS 廠商', value: 'deviceVendor', sortable: false, align: 'left' },
    { text: 'UPS 型號', value: 'upsModel', sortable: false, align: 'left' },
    { text: '建檔時間', value: 'createTime', align: 'left' },
    { text: '上次更新時間', value: 'updateTime', align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  get ADMIN_ROLE() {
    return this.isAdminRole('BMS_SYSTEM_ADMIN') || this.isAdminRole('BMS_PROJECT_ADMIN')
  }

  isAdminRole(roleName: string) {
    const userRoleGroupName = JSON.parse(localStorage.getItem('userRoleGroupName') || 'null')
    return userRoleGroupName === roleName
  }

  get tableItems() {
    const groupNameList = JSON.parse(localStorage.getItem('upsVendorGroup') as string)

    if (groupNameList) {
      this.tableList.forEach((item, index) => {
        Object.entries(groupNameList).forEach(([key, value]) => {
          if (item.deviceVendor === key) this.tableList[index].deviceVendor = value as string
        })
      })
    }

    return this.tableList.map((i, index) => ({
      ...i,
      deviceNumber: index + 1,
      upsModel: i.deviceTag?.upsModel ? i.deviceTag.upsModel || i.deviceTag.batteryCapacity : '-',
    }))
  }

  get CSVTable() {
    return this.tableItems.map((i, index) => ({
      編號: index + 1,
      [`UPS ID`]: i.deviceLabel,
      [`UPS 廠商`]: i.deviceVendor,
      [`UPS 型號`]: i.upsModel,
      建檔時間: i.createTime ? this.$dayjs(i.createTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
      上次更新時間: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
    }))
  }

  async mounted() {
    await (JSON.parse(localStorage.getItem('projectId')!) && this.getDeviceList())
  }

  async getDeviceList(page = 0) {
    if (page === 0) {
      this.tableList.splice(0)
    }

    try {
      this.isPageLoading = true

      const data = await this.$api.device.getDeviceList({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        sort: 'DESC',
        pageSize: 100,
        page,
        deviceType: 'ups',
      })

      if (data) {
        this.totalElements = data.totalElements
        const filteredArray = data.data.filter((item) => !this.tableList.some((exitingItem) => exitingItem.deviceId === item.deviceId))
        this.tableList = [...this.tableList, ...filteredArray]

        if (data.hasNext) {
          await this.getDeviceList(page + 1)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  getFiltered(e: IDeviceItemResponse[]) {
    this.searchResultNum = e.length
  }

  async showSwalFireMessage(title: string) {
    await this.$swal.fire({
      title,
      icon: 'success',
      toast: true,
      position: 'top',
      timer: 1500,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }

  async handleUnbind(item: IDeviceItemResponse) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要解除 ${item.deviceLabel} 綁定?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      await this.doUnbind(item)
    }
  }

  async doUnbind(item: IDeviceItemResponse) {
    try {
      this.isPageLoading = true

      await this.$api.device.deviceUnbind({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        deviceId: item.deviceId,
      })
      await this.showSwalFireMessage('已解除綁定')
      await this.getDeviceList()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleDelete(item: IDeviceItemResponse) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除 ${item.deviceLabel}?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true

        await this.$api.device.deleteDevice({
          projectId: JSON.parse(localStorage.getItem('projectId')!),
          deviceId: item.deviceId,
        })
        await this.showSwalFireMessage('刪除成功')
        await this.getDeviceList()
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }
}
