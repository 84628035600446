

















































































































import { useAppStore } from '@/store/app'
import { Component, Vue } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import { IEventInputErrorLogResponse } from '@/types/api/event'
import DateTimePicker from '@/components/DateTimePicker.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class InputErrorLog extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  dateRangeValue: number[] = []
  startTime = 0
  endTime = 0

  searchIntersectionLabel = ''
  searchIntersectionGroupLabel = ''

  inputErrorList: IEventInputErrorLogResponse[] = []

  tableHeader = [
    { text: '項次', value: 'index', align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '行政區/移動式供電車', value: 'intersectionGroupLabel', sortable: false, align: 'left' },
    { text: '開始時間', value: 'startTime', sortable: false, align: 'left' },
    { text: '結束時間', value: 'endTime', sortable: false, align: 'left' },
    { text: '持續時間', value: 'totalTime', sortable: false, align: 'left' },
    { text: '換電次數', value: 'changeTimes', sortable: false, align: 'left' },
    { text: '消耗電量', value: 'totalPower', sortable: false, align: 'right' },
  ]

  get IntersectionGroupLabelList() {
    return this.tableItems.map((i) => i.intersectionGroupLabel)
  }

  get IntersectionLabelList() {
    return this.filteredItems.map((i) => i.intersectionLabel)
  }

  get filteredItems() {
    return this.tableItems.filter((item) => {
      const intersectionGroupLabel = item.intersectionGroupLabel.toLowerCase().includes(this.searchIntersectionGroupLabel?.toLowerCase())
      const intersectionLabel = item.intersectionLabel.toLowerCase().includes(this.searchIntersectionLabel?.toLowerCase())

      return intersectionLabel && intersectionGroupLabel
    })
  }

  get tableItems() {
    return this.inputErrorList.map((i, index) => ({
      ...i,
      index: index + 1,
      totalTime: this.calculateRangeTime(i.startTime, i.endTime),
      totalPower: i.totalPower ? `${Math.round(i.totalPower)} %` : '-',
    }))
  }

  get CSVTable() {
    return this.tableItems.map((i, index) => ({
      項次: index + 1,
      路口名稱: i.intersectionLabel,
      ['行政區/移動式供電車']: i.intersectionGroupLabel === 'MOBILE' ? '供電車' : i.intersectionGroupLabel,
      緯度: i.latitude,
      經度: i.longitude,
      開始日期: i.startTime ? this.$dayjs(i.startTime).format('YYYY年 MM月 DD日') : '---/--/--',
      開始時間: i.startTime ? this.$dayjs(i.startTime).format('HH時 mm分 ss秒') : '--:--:--',
      結束日期: i.endTime ? this.$dayjs(i.endTime).format('YYYY年 MM月 DD日') : '---/--/--',
      結束時間: i.endTime ? this.$dayjs(i.endTime).format('HH時 mm分 ss秒') : '--:--:--',
      持續時間: i.totalTime,
      換電次數: i.changeTimes,
      消耗電量: i.totalPower,
    }))
  }

  calculateRangeTime(startTime: number, endTime: number) {
    if (endTime > startTime) {
      const diffTime = endTime - startTime

      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60)

      return days > 0 ? `${days}D : ${hours}H : ${minutes}M` : `${hours}H : ${minutes}M`
    }
  }

  disableDate(date: Date) {
    return date > new Date()
  }

  async handleMaxTimeRange() {
    const firstTime = new Date(new Date(this.dateRangeValue[0]))
    const secondTime = new Date(new Date(this.dateRangeValue[1]))
    const secondTimeEndValue = this.$dayjs(secondTime).endOf('day').valueOf()

    const dayDiff = (+secondTime - +firstTime) / 86400000
    const maxTimeRange = (31 * 24 * 60 * 60 * 1000) / 86400000

    if (dayDiff < 0 || dayDiff > maxTimeRange) {
      this.$swal.fire({
        title: '日期範圍最多 31 天',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    this.startTime = +firstTime
    this.endTime = +secondTimeEndValue

    await this.getInputErrorLog()
  }

  async getInputErrorLog() {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getInputErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        isFilterMobile: true,
      })

      this.inputErrorList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.startTime = +this.$dayjs().subtract(30, 'day').valueOf()
    this.endTime = +this.$dayjs().valueOf()
    this.dateRangeValue = [this.startTime, this.endTime]

    await this.getInputErrorLog()
  }
}
