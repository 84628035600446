
import { Component, Vue, Prop } from 'vue-property-decorator'
import { NormalizedScopedSlot } from 'vue/types/vnode'
import { VAutocomplete, VBtn, VIcon } from 'vuetify/lib'

export interface IAutoCompleteItemProps<T = any> {
  text: string | number | object
  value: T
  disabled?: boolean
  divider?: boolean
  header?: string
}

@Component
export default class AutoComplete extends Vue {
  @Prop({ type: String }) label!: string
  @Prop({ type: Boolean }) loading!: boolean
  @Prop({ type: Array, default: () => [] }) items!: Array<IAutoCompleteItemProps<any>>

  mounted() {
    // console.log('this.$refs.autoCompleteRef')
    const ref = (this.$refs.autoCompleteRef as Vue).$el
    const [input] = ref.querySelectorAll('input') as any
    input.addEventListener('compositionend', (e: any) => {
      console.log('compositionend', e)
    })
    input.addEventListener('compositionupdate', (e: any) => {
      console.log('compositionupdate', e)
    })
  }

  render() {
    return (
      <VAutocomplete
        ref='autoCompleteRef'
        attrs={this.$attrs}
        on={this.$listeners}
        label={this.label}
        disabled={this.loading}
        items={this.items}
        solo
        small
        scopedSlots={{
          item: (props: any) => {
            // console.log('scopedSlots.item', props)
            return (this.$scopedSlots.item as NormalizedScopedSlot)(props.item)
          },
        }}>
        <div class='appendSlot' slot='append'>
          {this.$slots.append || (
            <VBtn
              color='primary'
              loading={this.loading}
              disabled={this.loading}
              on={{
                click: () => {
                  this.$emit('search')
                },
              }}>
              <VIcon>mdi-magnify</VIcon>
            </VBtn>
          )}
        </div>
      </VAutocomplete>
    )
  }
}
