


















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import Upload from '../Upload/Upload.vue'
import UploadBatch from '../Upload/UploadBatch.vue'
import LabelTextField from '@/components/LabelTextField.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import { IDevicePostRequest, IDeviceItemResponse, IDevicePostItemRequest } from '@/types/api/device'
import { useAppStore } from '@/store/app'
import { IFileData } from '@/types/api/uploadFile'
import { WritableState } from '@/util/annotations'
import AutoCombobox from '@/components/AutoCombobox.vue'

@Component({
  components: {
    Upload,
    UploadBatch,
    LabelTextField,
    LabelSelect,
    AutoCombobox,
  },
})
export default class DevicesMgmtCreateGatewayUpload extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Array }) tableList!: IDeviceItemResponse[]

  venderGroupObj = JSON.parse(localStorage.getItem('gatewayVendorGroup')!)
  appStore = useAppStore()

  deviceLabel = ''
  deviceVendor = this.transformVenderGroupList.length === 1 ? this.transformVenderGroupList[0].value : ''
  gatewayModel = ''

  filePayload: IDevicePostRequest = {
    projectId: JSON.parse(localStorage.getItem('projectId')!),
    createDeviceList: [],
  }

  type: '' | 'batch' | 'manual' = ''

  csvExample = [
    {
      [`Gateway ID`]: '123456789',
      [`Gateway 廠商`]: '威力',
      [`Gateway 型號`]: '型號',
    },
  ]

  get transformVenderGroupList() {
    const group = JSON.parse(localStorage.getItem('gatewayVendorGroup')!)
    const newArr = []

    for (const key in group) {
      newArr.push({ label: group[key], value: key })
    }

    return newArr
  }

  onBatchCreate(fileData: IFileData) {
    if (
      this.hasFileDuplicateDeviceLabel(this.getFileData(fileData)) ||
      this.hasTableDuplicateDeviceLabel(this.getFileData(fileData)) ||
      this.hasDuplicateVender(this.getFileData(fileData))
    ) {
      ;(this.$refs.UploadBatch as UploadBatch).onFileRemove(0)
    } else {
      this.filePayload.createDeviceList = this.transformVenderId(this.getFileData(fileData))
    }
  }

  getFileData(fileData: IFileData) {
    const createDeviceList = []

    if (Array.isArray(fileData)) {
      for (const { result } of fileData) {
        const { Sheet1 } = result

        for (let i = 1; i < Sheet1.length; i++) {
          const row = Sheet1[i]
          const deviceLabel = row[0]
          const deviceVendor = row[1]
          const deviceTag = row[2] === undefined ? null : { gatewayModel: row[2] }

          createDeviceList.push({ deviceLabel, deviceVendor, deviceTag, deviceType: 'gateway' })
        }
      }
    }

    return createDeviceList
  }

  transformVenderId(createDeviceList: IDevicePostItemRequest[]) {
    createDeviceList.forEach((i, index) => {
      for (const key in this.venderGroupObj) {
        if (i.deviceVendor === this.venderGroupObj[key]) {
          createDeviceList[index].deviceVendor = key
        }
      }
    })

    return createDeviceList
  }

  hasFileDuplicateDeviceLabel(createDeviceList: IDevicePostItemRequest[]) {
    const labelList = createDeviceList.map((i) => i.deviceLabel)
    const repeatList = labelList.filter((i, index) => labelList.indexOf(i) !== index)

    if ([...new Set(repeatList)].length > 0) {
      this.toggleSwalAlert('檔案含有重複的 Gateway ID', [...new Set(repeatList)])

      return true
    }
    return false
  }

  hasTableDuplicateDeviceLabel(createDeviceList: IDevicePostItemRequest[]) {
    const labelList = this.tableList.map((i) => i.deviceLabel)
    const repeatList = createDeviceList.filter((i) => labelList.includes(`${i.deviceLabel}`)).map((o) => o.deviceLabel)

    if ([...new Set(repeatList)].length > 0) {
      this.toggleSwalAlert('與列表 Gateway ID 重複', [...new Set(repeatList)])

      return true
    }
    return false
  }

  hasDuplicateVender(createDeviceList: IDevicePostItemRequest[]) {
    const venderGroupValueList = Object.values(this.venderGroupObj).map((item) => item)
    const failGroupIdList = createDeviceList.filter((i) => !venderGroupValueList.includes(`${i.deviceVendor}`)).map((o) => o.deviceVendor)

    if ([...new Set(failGroupIdList)].length > 0) {
      this.toggleSwalAlert('上傳錯誤的廠商名稱', [...new Set(failGroupIdList)])

      return true
    }
    return false
  }

  toggleSwalAlert(title: string, uniqueList: string[]) {
    let htmlString = '<ol style="display: inline-block; text-align: left">'
    uniqueList.forEach((item) => {
      htmlString += `<li>${item}</li>`
    })
    htmlString += '</ol>'

    this.$swal.fire({ title, html: htmlString, icon: 'warning', showCancelButton: false })
  }

  async handleSubmitFile() {
    if (this.filePayload.createDeviceList.length === 0) return

    try {
      this.isPageLoading = true

      await this.$api.device.create(this.filePayload)
      await this.showSwalFire('新增成功')
      await this.refreshList()

      this.filePayload.createDeviceList = []
      ;(this.$refs.UploadBatch as UploadBatch).files = []
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    const payload: IDevicePostRequest = {
      projectId: JSON.parse(localStorage.getItem('projectId')!),
      createDeviceList: [
        {
          deviceLabel: this.deviceLabel,
          deviceType: 'gateway',
          deviceVendor: this.deviceVendor,
          deviceTag: { gatewayModel: this.gatewayModel },
        },
      ],
    }

    if (this.hasFileDuplicateDeviceLabel(payload.createDeviceList)) return

    await this.doSubmit(payload)
  }

  async doSubmit(payload: IDevicePostRequest) {
    this.isPageLoading = true

    try {
      await this.$api.device.create(payload)
      await this.showSwalFire('新增成功')
      await this.refreshList()

      this.deviceLabel = ''
      this.deviceVendor = this.transformVenderGroupList.length === 1 ? this.transformVenderGroupList[0].value : ''
      this.gatewayModel = ''
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }

  async showSwalFire(title: string) {
    await this.$swal.fire({
      title,
      icon: 'success',
      toast: true,
      position: 'top',
      timer: 1500,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }

  @Emit('refreshList')
  refreshList() {}
}
