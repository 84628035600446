



























import { Component, Vue } from 'vue-property-decorator'

import TableList from './Views/tableList.vue'
import AddUser from './Views/add.vue'

@Component({
  components: {
    TableList,
    AddUser,
  },
})
export default class AccountMgmtProjectUser extends Vue {
  tabIndex = 0

  async changeTab() {
    if (this.$refs.TableList as TableList) {
      if (this.tabIndex === 1) (this.$refs.TableList as TableList).getProjectUserList()
      ;(this.$refs.TableList as TableList).showTableStatus = true
    }
  }
}
