var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-x-transition',{attrs:{"mode":"out-in","duration":"500"}},[(_vm.showTableStatus)?_c('div',[_c('h2',{staticClass:"font-weight-black mb-4"},[_vm._v("使用者列表 ("+_vm._s(_vm.tableList.length)+")")]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__tableTop"},[_c('div',{staticClass:"table__tableTop--searchInput"},[_c('v-text-field',{attrs:{"placeholder":"搜尋使用者","outlined":"","dense":"","height":"32","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.search === '')?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',{on:{"click":function($event){_vm.search = ''}}},[_vm._v("mdi-close")])]},proxy:true}],null,false,3487067220),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('downloadCsv',{staticClass:"table__tableTop--exportReport",attrs:{"bom":true,"data":_vm.CSVTable,"name":"使用者列表.csv"}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-file-export-outline ")]),_vm._v("匯出列表 ")],1)],1),_c('v-data-table',{staticClass:"mt-7 elevation-1",attrs:{"headers":_vm.viewHeader,"items":_vm.tableList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.projectLabel",fn:function(ref){
var item = ref.item;
return [_c('ol',_vm._l((item.projectLabel),function(label,index){return _c('li',{key:index},[_vm._v(_vm._s(label))])}),0)]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createTime ? _vm.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updateTime ? _vm.$dayjs(item.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('button',{staticClass:"mr-2 my-1",on:{"click":function($event){return _vm.handleEditUser(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('button',{staticClass:"mx-2 my-1",on:{"click":function($event){return _vm.handleDeleteUser(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)])]}}],null,true)})],1)]):_c('EditUser',{attrs:{"editTemp":_vm.editTemp,"projectList":_vm.projectList,"hasOtpStatus":_vm.hasOtpStatus},on:{"refreshList":_vm.refreshList,"checkUserTotp":_vm.checkUserTotp}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }