























































































import { useAppStore } from '@/store/app'
import { Component, Vue } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import { IEventErrorLogGetResponse } from '@/types/api/event'
import DateTimePicker from '@/components/DateTimePicker.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class EventErrorLog extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  totalElements = 0

  dateRangeValue: number[] = []
  startTime = 0
  endTime = 0

  searchIntersectionLabel = ''

  eventErrorLogList: IEventErrorLogGetResponse['data'] = []

  tableHeader = [
    { text: '項次', value: 'index', align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '發生時間', value: 'createTime', sortable: false, align: 'left' },
    { text: '訊息', value: 'eventDescription', sortable: false, align: 'left' },
    { text: '路口電量', value: 'avgBatteryLevel', sortable: false, align: 'right' },
  ]

  get IntersectionLabelList() {
    return this.tableItems.map((i) => i.intersectionLabel)
  }

  get filteredItems() {
    return this.tableItems.filter((item) => {
      const intersectionLabel = item.intersectionLabel.toLowerCase().includes(this.searchIntersectionLabel?.toLowerCase())

      return intersectionLabel
    })
  }

  get tableItems() {
    return this.eventErrorLogList.map((i, index) => ({
      ...i,
      index: index + 1,
      avgBatteryLevel: i.avgBatteryLevel ? `${Math.round(i.avgBatteryLevel)} %` : '-',
    }))
  }

  get CSVTable() {
    return this.filteredItems.map((i, index) => ({
      項次: index + 1,
      路口名稱: i.intersectionLabel,
      發生日期: i.createTime ? this.$dayjs(i.createTime).format('YYYY年 MM月 DD日') : '---/--/--',
      發生時間: i.createTime ? this.$dayjs(i.createTime).format('HH時 mm分 ss秒') : '--:--:--',
      訊息: i.eventDescription,
      路口電量: i.avgBatteryLevel,
    }))
  }

  disableDate(date: Date) {
    return date > new Date()
  }

  async handleMaxTimeRange() {
    const firstTime = new Date(new Date(this.dateRangeValue[0]))
    const secondTime = new Date(new Date(this.dateRangeValue[1]))
    const secondTimeEndValue = this.$dayjs(secondTime).endOf('day').valueOf()

    const dayDiff = (+secondTime - +firstTime) / 86400000
    const maxTimeRange = (31 * 24 * 60 * 60 * 1000) / 86400000

    if (dayDiff < 0 || dayDiff > maxTimeRange) {
      this.$swal.fire({
        title: '日期範圍最多 31 天',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    this.startTime = +firstTime
    this.endTime = +secondTimeEndValue

    await this.getErrorEventLog()
  }

  async getErrorEventLog(page = 0) {
    try {
      this.isPageLoading = true

      if (page === 0) {
        this.eventErrorLogList.splice(0)
      }

      const data = await this.$api.event.getEventErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        sort: 'DESC',
        pageSize: 100,
        page,
        intersectionId: '',
      })

      if (data === undefined) {
        throw new TypeError()
      }

      this.totalElements = data.totalElements
      this.eventErrorLogList = [...this.eventErrorLogList, ...data.data]
      if (data.hasNext) {
        await this.getErrorEventLog(page + 1)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.startTime = +this.$dayjs().subtract(30, 'day').valueOf()
    this.endTime = +this.$dayjs().valueOf()
    this.dateRangeValue = [this.startTime, this.endTime]

    await this.getErrorEventLog()
  }
}
