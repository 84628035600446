import { IGetIntersectionItem } from '@/types/api/intersection'

export enum INTERSECTION_STATUS {
  'NOT_INSTALL' = 'NOT_INSTALL', // 未確認安裝
  'STANDBY' = 'STANDBY', // 待機中
  'UPS_ACTIVE' = 'UPS_ACTIVE', // UPS 啟用中
  'LOW_BATTERY_UPS_ACTIVE' = 'LOW_BATTERY_UPS_ACTIVE', // 低電量 UPS 啟用
  'ABNORMAL' = 'ABNORMAL', // 異常
  'LONG_TIME_NO_DATA' = 'LONG_TIME_NO_DATA', // 長時間未收到資料
}

export enum INTERSECTION_LABEL {
  電池模式 = '電池模式',
  市電模式 = '市電模式',
  低電量啟用 = '低電量啟用',
  連線異常 = '連線異常',
}

export const IntersectionStatusLabelMap = new Map([
  [INTERSECTION_STATUS.UPS_ACTIVE, INTERSECTION_LABEL.電池模式],
  [INTERSECTION_STATUS.STANDBY, INTERSECTION_LABEL.市電模式],
  [INTERSECTION_STATUS.LOW_BATTERY_UPS_ACTIVE, INTERSECTION_LABEL.低電量啟用],
  [INTERSECTION_STATUS.ABNORMAL, INTERSECTION_LABEL.連線異常],

  [INTERSECTION_STATUS.NOT_INSTALL, INTERSECTION_LABEL.連線異常],
  [INTERSECTION_STATUS.LONG_TIME_NO_DATA, INTERSECTION_LABEL.連線異常],
])

export const IntersectionConfigMap = new Map([
  [
    INTERSECTION_LABEL.電池模式,
    {
      color: '#00ce8e',
      icon: 'mdi-battery-charging-100', // 'mdi-checkbox-marked-circle',
      filter: (intersections: IGetIntersectionItem[]) =>
        intersections.filter(
          ({ intersectionStatus }) =>
            intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL && intersectionStatus === INTERSECTION_STATUS.UPS_ACTIVE
        ),
    },
  ],
  [
    INTERSECTION_LABEL.市電模式,
    {
      color: '#556fff',
      icon: 'mdi-transmission-tower', // 'mdi-chat-sleep',
      filter: (intersections: IGetIntersectionItem[]) =>
        intersections.filter(
          ({ intersectionStatus }) =>
            intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL && intersectionStatus === INTERSECTION_STATUS.STANDBY
        ),
    },
  ],
  [
    INTERSECTION_LABEL.低電量啟用,
    {
      color: '#fc7f7f',
      icon: 'mdi-battery-low',
      filter: (intersections: IGetIntersectionItem[]) =>
        intersections.filter(
          ({ intersectionStatus }) =>
            intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL && intersectionStatus === INTERSECTION_STATUS.LOW_BATTERY_UPS_ACTIVE
        ),
    },
  ],
  [
    INTERSECTION_LABEL.連線異常,
    {
      color: '#091944',
      icon: 'mdi-link-variant-remove', // 'mdi-alert-circle',
      filter: (intersections: IGetIntersectionItem[]) =>
        intersections.filter(
          ({ intersectionStatus }) =>
            intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL &&
            (intersectionStatus === INTERSECTION_STATUS.ABNORMAL || intersectionStatus === INTERSECTION_STATUS.LONG_TIME_NO_DATA)
        ),
    },
  ],
])
