var render, staticRenderFns
import script from "./LabelSelect.vue?vue&type=script&lang=tsx&"
export * from "./LabelSelect.vue?vue&type=script&lang=tsx&"
import style0 from "./LabelSelect.vue?vue&type=style&index=0&id=13a7b546&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a7b546",
  null
  
)

export default component.exports