var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-black"},[_vm._v("新增專案")]),_c('ValidationObserver',{ref:"form"},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"projectLabel",attrs:{"name":"專案名稱","rules":"required|projectLabel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"clearable":"","hide-details":"","label":"專案名稱","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.projectLabel),callback:function ($$v) {_vm.$set(_vm.temp, "projectLabel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.projectLabel"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"mapInitLongitude",attrs:{"name":"經度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"clearable":"","hide-details":"","label":"經度","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.mapInitLongitude),callback:function ($$v) {_vm.$set(_vm.temp, "mapInitLongitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.mapInitLongitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"mapInitLatitude",attrs:{"name":"緯度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"clearable":"","hide-details":"","label":"緯度","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.mapInitLatitude),callback:function ($$v) {_vm.$set(_vm.temp, "mapInitLatitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.mapInitLatitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"mapInitZoom",attrs:{"name":"地圖比例尺縮放","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelSelect',{attrs:{"items":_vm.mapInitZoomList,"hide-details":"","label":"地圖比例尺縮放","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.mapInitZoom),callback:function ($$v) {_vm.$set(_vm.temp, "mapInitZoom", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.mapInitZoom"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'路口群組'}}),_c('Editor',{attrs:{"title":'路口名稱',"objectString":_vm.temp.intersectionGroup},on:{"inputJson":_vm.inputIntersectionValue,"onError":_vm.intersectionOnError}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'Battery 廠商'}}),_c('Editor',{attrs:{"title":'Battery 廠商',"objectString":_vm.temp.batteryVendorGroup},on:{"inputJson":_vm.inputBatteryValue,"onError":_vm.batteryOnError}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'GATEWAY 廠商'}}),_c('Editor',{attrs:{"title":'GATEWAY 廠商',"objectString":_vm.temp.gatewayVendorGroup},on:{"inputJson":_vm.inputGatewayValue,"onError":_vm.gatewayOnError}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'UPS 廠商'}}),_c('Editor',{attrs:{"title":'UPS 廠商',"objectString":_vm.temp.upsVendorGroup},on:{"inputJson":_vm.inputUpsValue,"onError":_vm.upsOnError}})],1)],1)],1),_c('div',{staticClass:"mt-12 d-flex justify-center"},[_c('v-btn',{staticClass:"submitBtn",attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("確認")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }