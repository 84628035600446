import Vue from 'vue'
import { defineStore } from 'pinia'
import api from '@/api/api'
import { parseJwt } from '@/util/helpers'
import router from '@/router'
import { useAppStore } from '@/store/app'
import { axiosIns } from '@/api/axiosIns'
import { IGetProjectItem } from '@/types/api/project'
import { StoreInterval } from './intersection'

interface State {
  userId: string
  groupId: string
  isGroupAdmin: boolean
  defaultPath: string
  projectList: IGetProjectItem[]
  errMsg: string
  toolbarTitle: string
  qrCodeImgBase64Src: string
  hasOtpStatus: boolean
  mapInitLatitude: string
  mapInitLongitude: string
  mapInitZoom: string
  projectId: string
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    userId: '',
    groupId: '',
    isGroupAdmin: false,
    defaultPath: '',
    projectList: [],
    errMsg: '',
    toolbarTitle: '',
    qrCodeImgBase64Src: '',
    hasOtpStatus: false,
    mapInitLatitude: JSON.parse(localStorage.getItem('mapInitLatitude')!),
    mapInitLongitude: JSON.parse(localStorage.getItem('mapInitLongitude')!),
    mapInitZoom: JSON.parse(localStorage.getItem('mapInitZoom')!),
    projectId: JSON.parse(localStorage.getItem('projectId')!),
  }),

  actions: {
    setLocalStorage(key: string, value: any) {
      localStorage.setItem(key, JSON.stringify(value))
    },

    async getTOTPQrCode() {
      const appStore = useAppStore()
      try {
        appStore.isPageLoading = true

        const data = await api.otp.registerOtpSecret()
        if (data) {
          this.qrCodeImgBase64Src = data
        }
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async checkUserTotp(param: { email: string }) {
      const appStore = useAppStore()
      try {
        appStore.isPageLoading = true

        const data = await api.otp.checkUserOtp(param)

        this.hasOtpStatus = data as boolean
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async sync() {
      // console.log('parseJwt(t)', parseJwt(token))

      const token = sessionStorage.getItem('token')
      const { email } = token && parseJwt(token)
      localStorage.setItem('email', email)
    },

    async login(param: { email: string; secret: string; captcha: string }) {
      try {
        const data = await api.auth.login(param)

        sessionStorage.setItem('token', data as string)
        axiosIns.defaults.headers.common.Authorization = data as string

        this.setLocalStorage('switchValue', true)

        useAppStore().switchValue = true

        await this.initInfo()
        await this.getVersion()
        await this.getRoleGroupList()
        await router.replace(this.defaultPath).catch((error) => error)
      } catch (error: any) {
        console.error(error)
        this.errMsg = error.errorMsg
        throw error
      }
    },

    logout() {
      const itemsToRemove = [
        'switchValue',
        'intersectionGroup',
        'upsVendorGroup',
        'gatewayVendorGroup',
        'batteryVendorGroup',
        'roleGroup',
        'projectList',
        'projectId',
        'userLabel',
        'userId',
        'userRoleGroupName',
        'userLabel',
        'toolbarTitle',
        'userProjectList',
        'serverVersion',
        'email',
        'userName',
        'hasOtpStatus',
        'mapInitLatitude',
        'mapInitLongitude',
        'mapInitZoom',
        'defaultTitle',
      ]

      sessionStorage.removeItem('token')
      this.removeItemsFromLocalStorage(itemsToRemove)
      StoreInterval.removeAll()
      router.replace('/login')
    },

    removeItemsFromLocalStorage(items: string[]) {
      items.forEach((item) => {
        localStorage.removeItem(item)
      })
    },

    async getVersion() {
      const appStore = useAppStore()

      try {
        appStore.isPageLoading = true

        const { data } = await api.admin.getVersion()
        this.setLocalStorage('serverVersion', data)
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async getRouterMenu() {
      await useAppStore().userMenuPermission()
      await useAppStore().mappingMenu()
      this.defaultPath = useAppStore().permissionMenu[0].to
    },

    async getRoleGroupList() {
      const appStore = useAppStore()

      try {
        appStore.isPageLoading = true

        const data = await api.setting.getRoleGroupList()
        this.setLocalStorage('roleGroup', data)
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async getProjectList() {
      const appStore = useAppStore()

      try {
        appStore.isPageLoading = true

        const { data } = await api.project.getProjectList()
        this.projectList = data
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async getProjectUserList() {
      const appStore = useAppStore()

      try {
        appStore.isPageLoading = true

        const data = await api.project.gteProjectUserList()

        const { defaultProjectId, userId, projectIdList, userRoleGroupName, userLabel } = data!.filter(
          (i) => i.email === localStorage.getItem('email')
        )[0]
        const { projectLabel } = this.projectList.filter((i) => i.projectId === defaultProjectId)[0]
        const userProjectList = this.projectList.filter((i) => projectIdList.includes(i.projectId))

        this.setLocalStorage('toolbarTitle', projectLabel)
        this.setLocalStorage('userLabel', userLabel)
        this.setLocalStorage('userProjectList', userProjectList)
        this.setLocalStorage('userId', userId)
        this.setLocalStorage('userRoleGroupName', userRoleGroupName)
        this.setLocalStorage('projectId', defaultProjectId)

        if (defaultProjectId) {
          await this.getGroupParams(defaultProjectId)
        } else {
          this.swalAlert('查無您所屬的專案，請洽專案管理者')
        }
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async handleChangeProject(changeProjectId = '') {
      try {
        const { projectLabel } = this.projectList.filter((i) => i.projectId === changeProjectId)[0]

        this.setLocalStorage('toolbarTitle', projectLabel)
        this.setLocalStorage('projectId', changeProjectId)

        if (changeProjectId) {
          await this.getGroupParams(changeProjectId)
        } else {
          this.swalAlert('查無您所屬的專案，請洽專案管理者')
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getGroupParams(projectId: string) {
      const appStore = useAppStore()

      try {
        appStore.isPageLoading = true

        const data = await api.setting.getParamList({ projectId })

        if (data) {
          this.setLocalStorage('batteryVendorGroup', JSON.parse(data.batteryVendorGroup))
          this.setLocalStorage('intersectionGroup', JSON.parse(data.intersectionGroup))
          this.setLocalStorage('gatewayVendorGroup', JSON.parse(data.gatewayVendorGroup))
          this.setLocalStorage('upsVendorGroup', JSON.parse(data.upsVendorGroup))
          this.setLocalStorage('mapInitLatitude', JSON.parse(data.mapInitLatitude))
          this.setLocalStorage('mapInitLongitude', JSON.parse(data.mapInitLongitude))
          this.setLocalStorage('mapInitZoom', JSON.parse(data.mapInitZoom))

          this.mapInitLatitude = data.mapInitLatitude
          this.mapInitLongitude = data.mapInitLongitude
          this.mapInitZoom = data.mapInitZoom
        }
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async initInfo() {
      await this.sync()
      await this.getProjectList()
      await this.getProjectUserList()
      await this.getRouterMenu()
    },

    swalAlert(title: string) {
      Vue.prototype.$swal.fire({
        title,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    },
  },
})
