import { axiosIns } from '../axiosIns'
import { IRoleGroup, IDeviceStatusItem, IGetSettingResponse } from '@/types/api/setting'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  getParamList: (params: { projectId: string }) => {
    return handleResponse<IGetSettingResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/setting/project/params`, { params }))
  },

  getRoleGroupList: () => {
    return handleResponse<IRoleGroup[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/setting/role/group/list`))
  },

  getDeviceStatusList: () => {
    return handleResponse<IDeviceStatusItem[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/setting/install/device/status/list`))
  },
}
