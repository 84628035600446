
















































































































































import { useAppStore } from '@/store/app'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import { ITelemetryGetResponse } from '@/types/api/event'
import { IIntersectionMgmt } from '@/types/api/intersection'
import DateTimePicker from '@/components/DateTimePicker.vue'
import Dialog from '@/components/ImageDialog.vue'
import VueJsonPretty from 'vue-json-pretty' // @ts-ignore

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker, Dialog, VueJsonPretty } })
export default class TelemetryTimeSeries extends Vue {
  @Prop({ type: String }) value?: string // intersectionId

  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  payloadDialogStatus = false
  startTimeStatus = false
  endTimeStatus = false

  totalElements = 0
  totalPages = 0
  currentPage = 1
  currentPageSize = 10
  hasNext = false
  dateValue = +this.$dayjs().valueOf()

  startTime = '07:00'
  endTime = '08:00'

  dialogList: any = {}
  telemetryList: ITelemetryGetResponse['data'] = []
  totalTelemetryList: ITelemetryGetResponse['data'] = []
  intersectionList: IIntersectionMgmt[] = []
  intersectionId = ''
  CSVname = ''

  tableHeader = [
    { text: '裝置類別', value: 'deviceType', sortable: false, align: 'left' },
    { text: '電池槽位', value: 'position', sortable: false, align: 'left' },
    { text: '裝置 ID', value: 'deviceId', sortable: false, align: 'left' },
    { text: 'SrcTransTime', value: 'dataTime', sortable: false, align: 'left' },
    { text: 'create_time', value: 'createTime', sortable: false, align: 'left' },
    { text: 'update_time', value: 'updateTime', sortable: false, align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'left' },
  ]

  get tableItems() {
    return this.telemetryList.map((i) => ({
      ...i,
      intersectionLabel: this.intersectionList.filter((o) => o.intersectionId === i.intersectionId)[0]?.intersectionLabel,
      position: i.position ? (i.position === '0' ? 'A' : 'B') : '',
    }))
  }

  get mappingIntersectionList() {
    return this.intersectionList.map((i) => ({
      ...i,
      intersectionIndex: `${i.intersectionNumber} ${i.intersectionLabel}`,
    }))
  }

  get CSVTable() {
    return this.totalTelemetryList.map((i) => ({
      路口名稱: i.intersectionLabel,
      裝置類別: i.deviceType,
      電池槽位: i.position,
      裝置ID: i.deviceId,
      SrcTransTime: i.dataTime ? this.$dayjs(i.dataTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
      payload: JSON.stringify(i.payload),
      create_time: i.createTime ? this.$dayjs(i.createTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
      update_time: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
    }))
  }

  get showTemperatureTips() {
    return Object.keys(this.dialogList).includes('batteryTemperature')
  }

  getIntersectionLabel() {
    this.CSVname = this.intersectionList.filter((i) => i.intersectionId === this.intersectionId)[0].intersectionLabel
  }

  toggleDialog() {
    this.payloadDialogStatus = !this.payloadDialogStatus
    if (!this.payloadDialogStatus) {
      this.dialogList = {}
    }
  }

  handleView(item: any) {
    this.toggleDialog()
    this.dialogList = item.payload
  }

  disableDate(date: Date) {
    const today = new Date()
    return date > today || date < new Date(today.setMonth(today.getMonth() - 3))
  }

  checkTime() {
    const [startTimeHour] = this.startTime.split(':')
    const [endTimeHour] = this.endTime.split(':')

    if (+startTimeHour > +endTimeHour) {
      this.$swal.fire({
        title: '結束時間不能早於起始時間',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  checkIntersection() {
    if (this.intersectionId === '') {
      this.$swal.fire({
        title: '請選擇路口',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  checkTelemetry(data: ITelemetryGetResponse) {
    if (data.totalElements === 0) {
      this.$swal.fire({
        title: `${this.CSVname}尚無資料`,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  async handleSearch() {
    if (this.checkTime() || this.checkIntersection()) return

    await this.getTelemetry()
  }

  async handleDownLoadCSV() {
    if (this.checkTime() || this.checkIntersection()) return

    await this.getTotalTelemetry()
    this.doDownLoad()
  }

  doDownLoad() {
    const downloadCsv = document.getElementById('TelemetryTimeSeriesDownloadCsv')
    downloadCsv && downloadCsv.click()
  }

  async getTotalTelemetry(page = 0) {
    if (page === 0) this.totalTelemetryList.splice(0)

    const startTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.startTime}`
    const endTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.endTime}`

    try {
      this.isPageLoading = true

      const data = await this.$api.event.getTelemetry({
        intersectionId: this.intersectionId,
        startTime: `${this.$dayjs(startTimeString).valueOf()}`,
        endTime: `${this.$dayjs(endTimeString).valueOf()}`,
        sort: 'DESC',
        pageSize: 10000,
        page,
      })

      if (data === undefined) throw new TypeError()
      if (this.checkTelemetry(data)) return

      this.totalTelemetryList = [...this.totalTelemetryList, ...data.data]

      if (data.hasNext) {
        await this.getTotalTelemetry(page + 1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getIntersectionCurrentList() {
    try {
      this.isPageLoading = true
      const projectId = JSON.parse(localStorage.getItem('projectId')!)
      const data = await this.$api.intersection.getIntersectionCurrentList({ projectId })
      this.intersectionList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async selectTelemetryTotalNum() {
    this.currentPage = 1
    await this.getTelemetry()
  }

  async previousPage() {
    if (this.currentPage === 1) return
    await this.currentPage--
    await this.getTelemetry()
  }

  async nextPage() {
    if (!this.hasNext) return
    await this.currentPage++
    await this.getTelemetry()
  }

  async getTelemetry() {
    const startTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.startTime}`
    const endTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.endTime}`

    try {
      this.isPageLoading = true

      const data = await this.$api.event.getTelemetry({
        intersectionId: this.intersectionId,
        startTime: `${this.$dayjs(startTimeString).valueOf()}`,
        endTime: `${this.$dayjs(endTimeString).valueOf()}`,
        sort: 'DESC',
        pageSize: this.currentPageSize,
        page: this.currentPage - 1,
      })

      if (data === undefined) throw new TypeError()
      if (this.checkTelemetry(data)) return

      this.totalElements = data.totalElements
      this.totalPages = data.totalPages
      this.telemetryList = data.data
      this.hasNext = data.hasNext
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getIntersectionCurrentList()

    if (this.value) {
      this.intersectionId = this.value
      await this.getTelemetry()
    }
  }
}
