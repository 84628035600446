
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getComputedColor } from '@/util/helpers'
import { VIcon } from 'vuetify/lib'

@Component
export default class StatusText extends Vue {
  @Prop({ type: String, default: '--v-success-base' }) color!: string
  @Prop({ type: String }) icon?: string

  get dotStyle() {
    return {
      flexShrink: 0,
      borderRadius: '100%',
      width: '0.8rem',
      height: '0.8rem',
      backgroundColor: getComputedColor(this.color),
    }
  }

  render() {
    return (
      <div class='d-flex align-center justify-center'>
        {this.icon ? (
          <VIcon class='mr-2' style={{ color: getComputedColor(this.color) }}>
            {this.icon}
          </VIcon>
        ) : (
          <div class='mr-2' style={this.dotStyle} />
        )}
        <span>{this.$slots.default}</span>
      </div>
    )
  }
}
