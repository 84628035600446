












































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import Combobox from '@/components/Combobox.vue'
import { IGetUserGroupItemResponse } from '@/types/api/user'
import { IEventSettingResponse } from '@/types/api/event'

@Component({ components: { Combobox } })
export default class alertDialog extends Vue {
  @Prop({ type: Object }) temp!: IEventSettingResponse
  @Prop({ type: Boolean }) dialogStatus!: boolean
  @Prop({ type: Array }) userGroupList!: IGetUserGroupItemResponse[]
  @Prop({ type: Array }) lineUserGroup!: IGetUserGroupItemResponse[]
  @Prop({ type: Array }) lineNotifyGroupDetail!: IGetUserGroupItemResponse[]
  @Prop({ type: Array }) emailNotifyGroupDetail!: IGetUserGroupItemResponse[]

  lineNotifyGroupList: IGetUserGroupItemResponse[] = this.lineNotifyGroupDetail
  emailNotifyGroupList: IGetUserGroupItemResponse[] = this.emailNotifyGroupDetail

  lineNotifyGroupIdList: string[] = []
  emailNotifyGroupIdList: string[] = []

  @Watch('lineNotifyGroupDetail', { immediate: true })
  onLineGroupChange(v: IGetUserGroupItemResponse[]) {
    this.lineNotifyGroupList = v
  }

  @Watch('emailNotifyGroupDetail', { immediate: true })
  onEmailGroupChange(v: IGetUserGroupItemResponse[]) {
    this.emailNotifyGroupList = v
  }

  isLineItemDisabled(item: IGetUserGroupItemResponse): boolean {
    return item.lineToken === ''
  }

  @Emit('emitGroup')
  async emitGroup() {
    this.lineNotifyGroupIdList = this.lineNotifyGroupList.map((i) => i.userGroupId)
    this.emailNotifyGroupIdList = this.emailNotifyGroupList.map((i) => i.userGroupId)
    return [this.lineNotifyGroupIdList, this.emailNotifyGroupIdList]
  }
}
