import { EChartsOption } from 'echarts'

export async function createChart(this: Vue, ref: Vue | Element | (Vue | Element)[] | undefined, opts: EChartsOption) {
  ref = await new Promise((resolve) => this.$nextTick(() => resolve(ref)))

  if (!(ref instanceof HTMLDivElement)) {
    throw new Error('ref is not HTMLDivElement')
  }

  ref.style.width ||= '100%'
  ref.style.height ||= '400px'
  let lastDataZoonStart = 0

  const ptr = this.$echarts.init(ref as HTMLDivElement)
  const resizeHandler = () => ptr.resize()
  ptr.on('datazoom', (e: any) => {
    lastDataZoonStart = e.batch[0].start
  })

  window.addEventListener('resize', resizeHandler)
  this.$once('hook:beforeDestroy', () => {
    // console.log('[createChart util - unbind]', ptr)
    window.removeEventListener('resize', resizeHandler)
  })

  const render = (_opts: EChartsOption) => {
    if (!_opts) {
      return
    }
    // console.log('[createChart util - this.$watch]', _opts)
    // console.log('[_opts?.dataZoom?]', _opts?.dataZoom)
    if (_opts?.dataZoom) {
      ;(_opts.dataZoom as any).start = lastDataZoonStart
    }
    ptr.setOption(_opts)
  }

  // console.log('[createChart util - this.$data]', this.$data)

  let keyOfOptsInVmData = Object.keys(this.$data).find((k) => this.$data[k] === opts)

  if (!keyOfOptsInVmData) {
    // console.log('[createChart util - opts outside vm]')
    keyOfOptsInVmData = 'keyOfOptsInVmData'
    this.$data.keyOfOptsInVmData = opts
  }

  this.$watch(keyOfOptsInVmData, render, { deep: true })

  ptr.resize()
  render(opts)

  return ptr
}
