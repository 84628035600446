








































import { Component, Vue } from 'vue-property-decorator'
import EventAndAlarmInfo from './Views/EventAndAlarmInfo.vue'
import IntersectionHourlyStatusInfo from './Views/IntersectionHourlyStatusInfo.vue'
import EventErrorLogDetail from './Views/EventErrorLogDetail.vue'

@Component({
  components: {
    EventAndAlarmInfo,
    IntersectionHourlyStatusInfo,
    EventErrorLogDetail,
  },
})
export default class RecordQuery extends Vue {
  tabIndex = 0

  get SYSTEM_ADMIN() {
    return JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN'
  }
}
