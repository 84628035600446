import { getComputedColor } from '@/util/helpers'

// #region 電池充電狀態
export enum BatteryCharingStatusLabel {
  '充電中' = '充電中',
  '放電中' = '放電中',
  '已拔除' = '已拔除',
  '停止充電' = '停止充電',
}
export const BatteryCharingStatusCodeLabelMap = new Map([
  [0, BatteryCharingStatusLabel.充電中],
  [1, BatteryCharingStatusLabel.放電中],
  [2, BatteryCharingStatusLabel.已拔除],
  [3, BatteryCharingStatusLabel.停止充電],
])
export const BatteryCharingStatusLabelConfigMap = new Map([
  [BatteryCharingStatusLabel.放電中, { color: '--v-success-base' }],
  [BatteryCharingStatusLabel.充電中, { color: '--v-warning-base' }],
  [BatteryCharingStatusLabel.已拔除, { color: '#d7d7d7' }],
  [BatteryCharingStatusLabel.停止充電, { color: '#d7d7d7' }],
])
// #endregion

// #region 電池連線狀態
export enum BatteryConnectStatusLabel {
  正常 = '正常',
  離線 = '離線',
  更換中 = '更換中',
}
export const BatteryConnectStatusLabelMap = new Map([
  [0, BatteryConnectStatusLabel.正常],
  [1, BatteryConnectStatusLabel.離線],
  [2, BatteryConnectStatusLabel.更換中],
])
export const BatteryConnectStatusLabelConfigMap = new Map([
  [BatteryConnectStatusLabel.正常, { color: '--v-success-base', icon: 'mdi-check-bold' }],
  [BatteryConnectStatusLabel.離線, { color: '--v-error-base', icon: 'mdi-close' }],
  [BatteryConnectStatusLabel.更換中, { color: '--v-warning-base', icon: 'mdi-cached' }],
])
// #endregion

export const getBatteryLevelColor = (level: number | string) => {
  const leveInt = Number(level)
  if (leveInt >= 90) {
    return getComputedColor('--v-success-base')
  }
  if (leveInt >= 31) {
    return getComputedColor('--v-primary-base')
  }
  return getComputedColor('--v-error-base')
}
