




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IEventInputErrorLogResponse, IEventGetWorkLogResponse, IEventErrorLogGetResponse } from '@/types/api/event'

@Component
export default class OverviewMonthly extends Vue {
  @Prop({ type: Array }) inputErrorList!: IEventInputErrorLogResponse[]
  @Prop({ type: Array }) workLogList!: IEventGetWorkLogResponse[]
  @Prop({ type: Array }) errEventLogs!: IEventErrorLogGetResponse['data']

  /** UPS 供電時間 */
  get upsHoldUpDuration() {
    let totalDiff = 0

    for (let i = 0; i < this.workLogList.length; i++) {
      if (this.workLogList[i].endTime > this.workLogList[i].startTime) {
        const timeDiff = this.workLogList[i].endTime - this.workLogList[i].startTime
        totalDiff += timeDiff
      }
    }
    const hours = Math.floor(totalDiff / (1000 * 60 * 60))
    const minutes = Math.floor((totalDiff / (1000 * 60)) % 60)
    return [hours, minutes]
  }

  /** 斷電總時數 */
  get totalPowerOutageHours() {
    let totalDiff = 0

    for (let i = 0; i < this.inputErrorList.length; i++) {
      if (this.inputErrorList[i].endTime > this.inputErrorList[i].startTime) {
        const timeDiff = this.inputErrorList[i].endTime - this.inputErrorList[i].startTime
        totalDiff += timeDiff
      }
    }
    const hours = Math.floor(totalDiff / (1000 * 60 * 60))
    const minutes = Math.floor((totalDiff / (1000 * 60)) % 60)

    return [hours, minutes]
  }
}
