





































import { Component, Vue } from 'vue-property-decorator'
import { IIntersectionMgmt } from '@/types/api/intersection'
import { useAppStore } from '@/store/app'
import SearchResult from '@/components/SearchResult.vue'
import { WritableState } from '@/util/annotations'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, SearchResult } })
export default class MgmtDetail extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  search = ''
  searchResultNum = 0

  tableHeader = [
    { text: '換裝日期', value: 'updateTime', sortable: false, align: 'left' },
    { text: 'UPS', value: 'upsLabel', sortable: false, align: 'left' },
    { text: 'Gateway', value: 'gatewayLabel', sortable: false, align: 'left' },
    { text: '電池 A', value: 'batteryLabelA', sortable: false, align: 'left' },
    { text: '電池 B', value: 'batteryLabelB', sortable: false, align: 'left' },
  ]

  intersectionDeviceHistoryList: IIntersectionMgmt[] = []

  get tableList() {
    return this.intersectionDeviceHistoryList.map((i) => ({
      ...i,
      batteryLabelA: i.batteryLabelList ? (i.batteryLabelList[0] ? i.batteryLabelList[0] : '-') : '-',
      batteryLabelB: i.batteryLabelList ? (i.batteryLabelList[1] ? i.batteryLabelList[1] : '-') : '-',
      gatewayLabel: i.gatewayLabel ? i.gatewayLabel : '-',
      intersectionLabel: i.intersectionLabel ? i.intersectionLabel : '-',
      upsLabel: i.upsLabel ? i.upsLabel : '-',
    }))
  }

  get CSVTable() {
    return this.tableList.map((i) => ({
      換裝日期: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
      UPS: i.upsLabel,
      Gateway: i.gatewayLabel,
      [`電池 A`]: i.batteryLabelA,
      [`電池 B`]: i.batteryLabelB,
    }))
  }

  getFiltered(e: any) {
    this.searchResultNum = e.length
  }

  async getIntersectionDeviceHistory() {
    try {
      this.isPageLoading = true
      const data = await this.$api.intersection.getIntersectionHistory({ intersectionId: `${this.$route.query.intersectionId}` })
      this.intersectionDeviceHistoryList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getIntersectionDeviceHistory()
  }
}
