var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__tableTop"},[_c('ValidationObserver',{ref:"form"},[_c('v-row',{staticClass:"d-flex align-start"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-theme-gray mb-1"},[_vm._v("資料時間")]),_c('ValidationProvider',{ref:"dateRangeValue",attrs:{"name":"資料時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"range":true,"label":'請選擇資料時間',"format":"YYYY-MM","type":"month","value-type":"timestamp","disabled-date":_vm.disableDate},model:{value:(_vm.dateRangeValue),callback:function ($$v) {_vm.dateRangeValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dateRangeValue"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"text-theme-gray mb-1"},[_vm._v("報表類型")]),_c('div',{staticClass:"reportType"},[_c('v-checkbox',{staticClass:"nowrap",attrs:{"label":"全選","dense":"","hide-details":""},on:{"change":_vm.toggleSelectAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectAll"}}),_c('ValidationProvider',{ref:"selectedReportList",attrs:{"name":"報表類型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.filterAdminTypeList),function(item,index){return _c('v-checkbox',{key:index,attrs:{"dense":"","hide-details":"","label":item.label,"value":item.value},model:{value:(_vm.selectedReportList),callback:function ($$v) {_vm.selectedReportList=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedReportList"}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1),_c('div',{staticClass:"errorAlert reportAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"text__size-14 px-10 mt-4 w-100",attrs:{"color":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("搜尋")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-md-12 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"footer-props":{ 'items-per-page-options': [15, 50, 100, -1] }},scopedSlots:_vm._u([{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createTime ? _vm.$dayjs(item.createTime).format('YYYY/MM') : '----/--')+" ")]}},{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-theme-blue download",on:{"click":function($event){return _vm.handleDownload(item)}}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-download")]),_vm._v(" "+_vm._s(_vm.$dayjs(item.createTime).format('YYYY 月 MM 月'))+"_"+_vm._s(item.reportType)+" ")],1)]}}],null,true)})],1),_c('downloadCsv',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"downloadCsv","bom":true,"data":_vm.CSVTable,"name":(_vm.CSVname + ".csv")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }