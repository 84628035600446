import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/VeeValidate/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@mdi/font/css/materialdesignicons.css'

/** @see https://github.com/vuejs/pinia/discussions/723 */
import pinia from './plugins/pinia'
import router from './router'
import dayjs from 'dayjs'
import '@/plugins/sweetalert'

import * as echarts from 'echarts'
import api from '@/api/api'
import dialog from '@/util/dialog'
import drawer from './util/drawer'
import '@/styles/overrides.sass'
import 'vue-json-pretty/lib/styles.css'

import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$dialog = dialog
Vue.prototype.$drawer = drawer
Vue.prototype.$api = api
Vue.prototype.$dayjs = dayjs

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
