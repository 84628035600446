
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VAutocomplete } from 'vuetify/lib'
import Label from './Label.vue'

@Component
export default class AutoCombobox extends Vue {
  @Prop({ type: String }) title!: string
  @Prop({ type: Boolean }) depressed!: boolean

  render() {
    return (
      <div>
        <Label style='margin-bottom:0.5rem;' title={this.title || this.$attrs.label} />
        <VAutocomplete props={this.$attrs} attr={this.$attrs} on={this.$listeners} class={{ depressed: this.depressed }} />
      </div>
    )
  }
}
