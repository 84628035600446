var render, staticRenderFns
import script from "./OverviewMapMarkerLabel.vue?vue&type=script&lang=tsx&"
export * from "./OverviewMapMarkerLabel.vue?vue&type=script&lang=tsx&"
import style0 from "./OverviewMapMarkerLabel.vue?vue&type=style&index=0&id=def5c908&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "def5c908",
  null
  
)

export default component.exports