































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import Label from '@/components/Label.vue'
import { ICheckDeviceInfo } from '@/types/api/device'
import { WritableState } from '@/util/annotations'

@Component({ components: { Label } })
export default class FixMgmtStatus extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Object }) checkDeviceInfo!: ICheckDeviceInfo
  @Prop({ type: String }) intersectionId!: string
  @Prop({ type: Boolean }) ADMIN_ROLE!: boolean

  get updateTime() {
    return this.checkDeviceInfo.updateTime
      ? this.$dayjs(this.checkDeviceInfo.updateTime).format('YYYY/MM/DD HH:mm:ss')
      : '----/--/-- --:--:--'
  }

  get gatewayLabel() {
    return this.checkDeviceInfo.gatewayLabel ? this.checkDeviceInfo.gatewayLabel : 'N/A'
  }

  get inputStatus() {
    return this.checkDeviceInfo.inputStatus ? `狀態 : ${this.checkDeviceInfo.inputStatus}` : 'N/A'
  }

  get inputVoltage() {
    return this.checkDeviceInfo.inputVoltage ? `${this.checkDeviceInfo.inputVoltage} Volt` : 'N/A'
  }

  get upsLabel() {
    return this.checkDeviceInfo.upsLabel ? this.checkDeviceInfo.upsLabel : 'N/A'
  }

  get upsStatus() {
    return this.checkDeviceInfo.upsStatus ? `狀態 : ${this.checkDeviceInfo.upsStatus}` : 'N/A'
  }

  get upsTemperature() {
    return this.checkDeviceInfo.upsTemperature ? `${this.checkDeviceInfo.upsTemperature} °C ` : 'N/A'
  }

  get batteryA() {
    if (!this.checkDeviceInfo.checkBatteryInfoList || !this.checkDeviceInfo.checkBatteryInfoList[0]) {
      return {
        batteryLabel: '',
        batteryLevel: 0,
        batteryStatus: '',
      }
    } else {
      return this.checkDeviceInfo.checkBatteryInfoList[0]
    }
  }

  get batteryB() {
    if (!this.checkDeviceInfo.checkBatteryInfoList || !this.checkDeviceInfo.checkBatteryInfoList[1]) {
      return {
        batteryLabel: '',
        batteryLevel: 0,
        batteryStatus: '',
      }
    } else {
      return this.checkDeviceInfo.checkBatteryInfoList[1]
    }
  }
}
