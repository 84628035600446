























import { useAppStore } from '@/store/app'
import { computed, watch, ref, Ref } from '@vue/composition-api'

export default {
  name: 'DefaultDrawer',
  data() {
    return {
      webVersion: process.env.VERSION,
    }
  },

  components: {
    DefaultDrawerHeader: () => import('./DrawerHeader.vue') /* webpackChunkName: "default-drawer-header" */,
    DefaultList: () => import('./DrawerList.vue') /* webpackChunkName: "default-list" */,
  },

  setup() {
    const store = useAppStore()
    const menuList: Ref<any> = ref([])
    const drawerVisibility = computed({
      get: () => store.drawerVisibility,
      set: (v) => {
        store.drawerVisibility = v
      },
    })

    const drawerIsMini = computed({
      get: () => store.drawerIsMini,
      set: (v) => {
        store.drawerIsMini = v
      },
    })

    watch(
      () => store.permissionMenu,
      (newValue, oldValue) => {
        menuList.value = newValue
      },
      { immediate: true, deep: true }
    )

    const serverVersion = JSON.parse(localStorage.getItem('serverVersion')!)

    return { drawerIsMini, drawerVisibility, serverVersion, menuList }
  },
}
