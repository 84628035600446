
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VIcon } from 'vuetify/lib/components'

@Component
export default class GMapVueMarker extends Vue {
  @Prop({ type: String }) color!: string
  @Prop({ type: String }) icon!: string

  render() {
    return (
      <div class='marker-wrap'>
        <VIcon class='marker-wrap__baseIcon' x-large color={this.color}>
          mdi-map-marker
        </VIcon>
        <div class='marker-wrap__circle-wrapper' style={{ backgroundColor: this.color }}>
          <VIcon class='marker-wrap__mainIcon' color='white'>
            {this.icon}
          </VIcon>
        </div>
      </div>
    )
  }
}
