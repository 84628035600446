import { axiosIns } from '../axiosIns'
import {
  IEditGroupRequest,
  ICreateGroupRequest,
  IDeleteGroupRequest,
  IGetUserGroupItemResponse,
  IUserItem,
  IResetPasswordRequest,
} from '@/types/api/user'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  getUserList: (params: { projectId: string }) => {
    return handleResponse<IUserItem[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/user/list`, { params }))
  },

  editGroup: (payload: IEditGroupRequest) => {
    return handleResponse<void>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/user/group`, payload))
  },

  createGroup: (payload: ICreateGroupRequest) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/user/group`, payload))
  },

  deleteGroup: (params: IDeleteGroupRequest) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/user/group`, { params }))
  },

  getGroupList: (params: { projectId: string }) => {
    return handleResponse<IGetUserGroupItemResponse[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/user/group/list`, { params }))
  },

  updateDefaultProject: (payload: { defaultProjectId: string }) => {
    return handleResponse<void>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/user/project/default`, payload))
  },

  updatePassword: (payload: IResetPasswordRequest) => {
    return handleResponse<void>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/noauth/user/update-secret`, payload))
  },

  updateSecretEmail: () => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/user/update-secret-email`))
  },

  forgotSecretEmail: (payload: { userEmail: string }) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/noauth/user/forgot-secret-email`, payload))
  },
}
