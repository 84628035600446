import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const options = {
  confirmButtonText: '確認',
  cancelButtonText: '取消',
  confirmButtonColor: '#627AFF',
  cancelButtonColor: '#FF6666',
  reverseButtons: true,
  width: 400,
  showClass: {
    popup: '',
  },
}

Vue.use(VueSweetalert2, options)
