import { useAppStore } from '@/store/app'
import { useIntersectionStore } from '@/store/intersection'
import { NavigationGuard } from 'vue-router'
import { useUserStore } from '@/store/user'

export const beforeEach: NavigationGuard = async (to, from, next) => {
  // console.log('[beforeEach] {to, from, next}', { to, from, next })

  const token = sessionStorage.getItem('token')
  const appStore = useAppStore()

  appStore.footerVisibility = true

  if ((to.path === '/sendEmail' && !token) || (to.path === '/resetPassword' && from.path !== '/login')) {
    next()
  } else if (to.path !== '/login' && !token) {
    next('/login')
  } else if (to.path === '/login' && token) {
    next('/')
  } else {
    next()
  }

  if (token) {
    try {
      const { setAllIntersection } = useIntersectionStore()

      await appStore.userMenuPermission()
      await appStore.mappingMenu()
      await useUserStore().checkUserTotp({ email: JSON.parse(JSON.stringify(localStorage.getItem('email'))) })
      await setAllIntersection()
    } catch (error) {
      console.log(error)
    }
  }
}
