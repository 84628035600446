
























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import { IntersectionConfigMap, IntersectionStatusLabelMap, INTERSECTION_LABEL, INTERSECTION_STATUS } from '@/constants/intersection'
import { IGetIntersectionItem } from '@/types/api/intersection'
import { useIntersectionStore } from '@/store/intersection'
import { getComputedColor } from '@/util/helpers'
import Gmap from '@/components/GMap/GMap.vue'
import ChipsSelector from '@/components/ChipsSelector.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import GMapVueMarker from '@/components/GMap/GMapVueMarker.vue'
import MapMgmtDrawer from './MapMgmtDrawer.vue'
import { GMapAdvancedMarkers } from '@/components/GMap/GMapAdvancedMarkers'
import { WatchPlainArrDiff } from '@/util/annotations'
import { useUserStore } from '@/store/user'

interface IIntersectionListView extends IGetIntersectionItem {
  labelIconColor: string
  valueIcon: string
  valueIconColor: string
  batteryLevel: string
}

const defaultIntersectionListView = {
  labelIconColor: '#00ce8e',
  valueIcon: 'mdi-battery',
  valueIconColor: '--v-info-base',
  batteryLevel: '-',
}

@Component({
  components: { Gmap, MapMgmtDrawer, ChipsSelector, AutoComplete },
})
export default class MapMgmt extends Vue {
  appStore = useAppStore()
  intersectionStore = useIntersectionStore()
  getComputedColor = getComputedColor

  drawerVisibility = false

  // current intersection id
  intersectionIdSelected = ''
  intersectionStatus: INTERSECTION_LABEL | '' = ''
  intersectionStatusItems = [
    {
      label: '全部',
      value: '',
      color: 'grey',
      icon: 'mdi-map-marker',
    },
    ...Object.values(INTERSECTION_LABEL).map((intersectionStatusLabel) => {
      const { icon, color } = IntersectionConfigMap.get(intersectionStatusLabel)!
      return {
        label: intersectionStatusLabel,
        value: intersectionStatusLabel,
        icon,
        color,
      }
    }),
  ]

  // drawer infos
  get intersectionList() {
    const list = this.intersectionStore.intersectionInstalled
    if (this.intersectionStatus === '') {
      return list
    }
    const { filter } = IntersectionConfigMap.get(this.intersectionStatus)!
    return filter(list)
  }

  parseBatteryLevel(l: any) {
    return `${l ? l.toFixed(1) : '-'}%`
  }

  get intersectionListView(): IIntersectionListView[] {
    return this.intersectionList
      .filter((o) => o.intersectionType === this.intersectionStore.intersectionType)
      .map((intersection) => {
        // const { icon, color } = IntersectionConfigMap.get(IntersectionStatusLabelMap.get(intersection.intersectionStatus)!)!
        return {
          ...intersection,
          ...defaultIntersectionListView,
          batteryLevel: this.parseBatteryLevel(intersection.avgBatteryLevel),
        }
      })
  }

  @Watch('intersectionStore.intersectionType')
  onIntersectionTypeChange() {
    if (this.intersectionStore.intersectionType === 'MOBILE') {
      this.intersectionIdSelected = ''
      this.intersectionStore.resetIntersection()
      this.drawerVisibility = false
    }
  }

  @WatchPlainArrDiff('intersectionList', 'intersectionId')
  diffIntersectionList: IGetIntersectionItem[] = []

  private mapToMarkerItemOpts(list: IGetIntersectionItem[]) {
    return list.map((x) => {
      let color, icon

      if (x.intersectionType === 'FIXED') {
        const { color: FIXED_COLOR, icon: FIXED_ICON } = IntersectionConfigMap.get(
          IntersectionStatusLabelMap.get(x.intersectionStatus as INTERSECTION_STATUS)!
        )!

        color = FIXED_COLOR
        icon = FIXED_ICON
      } else {
        color = '#00ce8e'
        icon = 'mdi mdi-car-electric'
      }

      return {
        id: x.intersectionId,
        position: {
          lat: +x.latitude,
          lng: +x.longitude,
        },
        panOffset: [240, 0],
        vnode: <GMapVueMarker color={color} icon={icon} />,
      }
    })
  }

  get replacingMarkerItemOpts() {
    const list =
      this.intersectionStore.intersectionType === 'MOBILE'
        ? this.intersectionListView.filter((i) => i.intersectionType === 'MOBILE')
        : this.diffIntersectionList.filter((i) => i.intersectionType === 'FIXED')

    return this.mapToMarkerItemOpts(list)
  }

  get markerItemOpts() {
    const list =
      this.intersectionStore.intersectionType === 'MOBILE'
        ? this.intersectionListView.filter((i) => i.intersectionType === 'MOBILE')
        : this.intersectionListView.filter((i) => i.intersectionType === 'FIXED')

    return this.mapToMarkerItemOpts(list)
  }

  onMapInput(id: string) {
    if (this.intersectionStore.intersectionItemActived?.intersectionId === id) {
      this.drawerVisibility = true
      return
    }
    this.activeIntersection(id)
  }

  async activeIntersection(id: string) {
    this.intersectionIdSelected = id
    await this.intersectionStore.setActivedIntersectionId(this.intersectionIdSelected)
    this.drawerVisibility = true
  }

  created() {
    this.appStore.footerVisibility = false
    // console.log('this.$router.query', this.$route)
  }

  mapCompCtx?: Gmap = undefined

  onMore() {
    this.$router.push({
      path: '/intersection-mgmt/dashboard',
      query: {
        id: this.intersectionIdSelected,
      },
    })
  }

  async onMapLoaded(mapCompCtx: Gmap) {
    this.mapCompCtx = mapCompCtx
    await this.flyToActivedMarker()
  }

  /*
  @Watch('intersectionStatus')
  onIntersectionStatusChange() {
    if (this.mapCompCtx === undefined) {
      return
    }
    this.intersectionIdSelected = ''
    this.drawerVisibility = false
    this.intersectionStore.resetIntersection()

    this.$nextTick(() => {
      this.mapCompCtx!.backToDefaultExtent()
      this.mapCompCtx!.initMarkers()
    })
  }
  */

  userStore = useUserStore()

  get location() {
    return {
      lat: Number(this.userStore.mapInitLatitude),
      lng: Number(this.userStore.mapInitLongitude),
    }
  }

  get zoom() {
    return Number(this.userStore.mapInitZoom)
  }

  /*
  @Watch('userStore.projectId')
  onProjectChange() {
    this.mapCompCtx!.initMarkers()
    this.mapCompCtx!.flyTo(this.location, this.zoom)
  }
  */

  @Watch('intersectionStore.isIntersectionListHasLoaded')
  initMapMarkersAndFit() {
    this.$nextTick(async () => {
      if (this.mapCompCtx === undefined) {
        return
      }
      this.mapCompCtx.initMarkers()
      this.mapCompCtx.flyTo(this.location, this.zoom)
      await this.flyToActivedMarker()
    })
  }

  @Watch('userStore.projectId')
  @Watch('intersectionStatus')
  forceInitMapMarkersAndFit() {
    if (this.mapCompCtx === undefined) {
      return
    }
    this.intersectionIdSelected = ''
    this.drawerVisibility = false
    this.intersectionStore.resetIntersection()

    this.$nextTick(() => {
      this.mapCompCtx!.flyTo(this.location, this.zoom)
      this.mapCompCtx!.initMarkers()
    })
  }

  async flyToActivedMarker() {
    // 從 query string 取得路口 id
    const { id: qIid } = this.$route.query
    if (qIid !== undefined && typeof qIid === 'string') {
      await this.activeIntersection(qIid)
      /** keep the same extent */
      GMapAdvancedMarkers.setMarkerStatus(qIid, true)
    }

    /** @see #13861 檢查路口狀態預設打開 drawer  */
    const aIid = this.intersectionStore.intersectionItemActived?.intersectionId
    if (aIid) {
      await this.activeIntersection(aIid)
      /** keep the same extent */
      GMapAdvancedMarkers.setMarkerStatus(aIid, true)
    }
  }
}
