<template>
  <v-app>
    <v-overlay :value="loading" z-index="999">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </v-overlay>
    <ValidationObserver ref="form">
      <div class="wrap" :style="`background-image:url(${bg})`">
        <div class="glass">
          <v-toolbar-title class="mb-5 glass__title">{{ SYS_NAME }}</v-toolbar-title>
          <!-- account -->
          <ValidationProvider ref="email" v-slot="{ errors }" name="信箱" rules="required">
            <v-text-field
              v-model.trim="email"
              dark
              prepend-icon="mdi-account"
              name="Email"
              label="信箱"
              type="text"
              autocomplete="off"
              :error-messages="errors"
              @focus="errMsg = ''"
              @input="inputChange"
            />
          </ValidationProvider>

          <!-- password -->
          <ValidationProvider ref="secret" v-slot="{ errors }" name="密碼" rules="required">
            <v-text-field
              id="mdi-password"
              v-model.trim="secret"
              dark
              prepend-icon="mdi-lock"
              name="Secret"
              label="密碼"
              autocomplete="off"
              :error-messages="errors"
              :append-icon="passwordStatus ? 'mdi-eye-off' : 'mdi-eye'"
              :type="passwordStatus ? 'password' : 'text'"
              @click:append="passwordStatus = !passwordStatus"
              @focus="errMsg = ''"
              @keyup.enter="hasOtpStatus ? doOTPLogin : handleLogin"
            />
          </ValidationProvider>

          <!-- TOTP -->
          <ValidationProvider ref="totp" v-slot="{ errors }" name="TOTP" :rules="hasOtpStatus ? 'required|otp' : ''">
            <v-text-field
              v-if="hasOtpStatus"
              id="mdi-totp"
              v-model.trim="totp"
              maxlength="6"
              dark
              prepend-icon="mdi-account-key"
              name="Totp"
              label="TOTP"
              autocomplete="off"
              :error-messages="errors"
              @keyup.enter="doOTPLogin"
            />
          </ValidationProvider>

          <!-- captcha -->
          <div>
            <template v-if="loadingChangeCaptchaImg">
              <v-skeleton-loader style="width: 100%; height: 60px" type="image" />
            </template>
            <template v-else>
              <img style="width: 100%; height: 60px" :src="captchaImgBase64Src" alt="captcha" />
            </template>
            <ValidationProvider ref="uppercaseInput" v-slot="{ errors }" name="圖形驗證碼" rules="required|captcha">
              <v-text-field
                v-model.trim="uppercaseInput"
                dark
                name="captcha"
                label="請輸入驗證碼"
                type="captcha"
                hide-details
                append-outer-icon="mdi-refresh"
                @hook:mounted="onChangeCaptcha"
                @keyup.enter="handleLogin"
                @click:append-outer="onChangeCaptcha"
              />

              <div class="errorAlert mb-8">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>

          <p v-if="errMsg" style="text-align: center; color: var(--v-error-lighten1)">{{ errMsg }}</p>

          <div class="d-flex justify-center">
            <v-btn v-if="hasOtpStatus" color="primary" @click="doOTPLogin">登入</v-btn>
            <v-btn v-else color="primary" @click="handleLogin">登入</v-btn>
          </div>

          <v-btn :disabled="loading" class="mt-3 font-weight-black" color="white" plain x-large @click="handleForgetPassword">
            忘記密碼?
          </v-btn>
        </div>
      </div>
    </ValidationObserver>
  </v-app>
</template>

<script>
import bg from '@/assets/bg.png'
import { useUserStore } from '@/store/user'

export default {
  name: 'Login',
  props: {
    source: String,
  },
  data: () => ({
    SYS_NAME: process.env.VUE_APP_SYSNAME,
    email: '',
    secret: '',
    errMsg: '',
    bg,
    loading: false,
    passwordStatus: true,

    loadingChangeCaptchaImg: false,
    captchaImgBase64Src: '',
    captcha: '',
    hasOtpStatus: false,
    totp: '',
  }),

  computed: {
    uppercaseInput: {
      get() {
        return this.captcha
      },
      set(newValue) {
        this.captcha = newValue.toUpperCase()
      },
    },
    disAllowLogin() {
      return !this.username || !this.password || !this.captcha
    },
  },

  methods: {
    inputChange() {
      this.hasOtpStatus = false
      this.totp = ''
      this.errMsg = ''
    },

    async handleLogin() {
      const validateStatus = await this.$refs.form.validate()
      if (!validateStatus) return

      await this.checkUserTotp()

      const { hasOtpStatus, logout } = useUserStore()

      logout()
      if (!hasOtpStatus) {
        await this.doOTPLogin()
      } else {
        this.hasOtpStatus = true
      }
    },

    async checkUserTotp() {
      try {
        this.loading = true

        const { checkUserTotp: _checkUserTotp } = useUserStore()

        await _checkUserTotp({ email: this.email })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async doOTPLogin() {
      const validateStatus = await this.$refs.form.validate()
      if (!validateStatus) return

      try {
        this.loading = true

        const { login: _login } = useUserStore()
        await _login({ email: this.email, secret: this.secret, captcha: this.captcha, totp: this.totp })
      } catch (error) {
        console.log(error)
      } finally {
        this.errMsg = await useUserStore().errMsg

        this.loading = false
      }
    },

    async onChangeCaptcha() {
      try {
        this.loadingChangeCaptchaImg = true
        const { data } = await this.$api.auth.getCaptcha()
        this.captchaImgBase64Src = data
        this.captcha = ''
      } finally {
        this.loadingChangeCaptchaImg = false
      }
    },

    handleForgetPassword() {
      this.$router.push({ path: '/sendEmail' })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.glass {
  width: 320px;

  @media screen and (min-width: 425px) {
    width: 360px;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }

  background-color: rgba(white, 0.25);
  backdrop-filter: blur(5px);
  padding: 3rem 2rem;
  border: 2px solid var(--v-text-accent-base);
  color: var(--v-text-accent-base);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    text-align: center;
  }
}
</style>
