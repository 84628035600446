










import { Component, Vue } from 'vue-property-decorator'
import ScaleChart from '@/views/IntersectionMgmtDashboard/components/ScaleChart.vue'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import TimeTitle from '@/views/IntersectionMgmtDashboard/components/TimeTitle.vue'
import { useIntersectionStore } from '@/store/intersection'
import ShadowCard from '@/components/ShadowCard.vue'

@Component({
  components: {
    ScaleChart,
    StatusText,
    TimeTitle,
    ShadowCard,
  },
})
export default class IndexInput extends Vue {
  intersectionStore = useIntersectionStore()

  get isInputOffline() {
    return this.intersectionStore.isInputOffline
  }

  /// 市電
  get inputInfo() {
    return this.intersectionStore.inputInfo
  }
}
