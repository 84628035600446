























import { useAppStore } from '@/store/app'
import { computed } from '@vue/composition-api'

export default {
  name: 'DefaultLayout',
  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar.vue'
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        './Drawer.vue'
      ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer.vue'
      ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './View.vue'
      ),
  },

  setup() {
    const store = useAppStore()
    const isPageLoading = computed({
      get: () => store.isPageLoading,
      set: (newVal) => {
        store.isPageLoading = newVal
      },
    })
    const isProgressing = computed({
      get: () => store.isProgressing,
      set: (newVal) => {
        store.isProgressing = newVal
      },
    })
    return { isPageLoading, isProgressing }
  },
}
