






































import { Component, Vue } from 'vue-property-decorator'
import ScaleChart from '@/views/IntersectionMgmtDashboard/components/ScaleChart.vue'
import PieChart from '@/views/IntersectionMgmtDashboard/components/PieChart.vue'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import TimeTitle from '@/views/IntersectionMgmtDashboard/components/TimeTitle.vue'
import { useIntersectionStore } from '@/store/intersection'
import ShadowCard from '@/components/ShadowCard.vue'

@Component({
  components: {
    ScaleChart,
    PieChart,
    StatusText,
    TimeTitle,
    ShadowCard,
  },
})
export default class IndexBattery extends Vue {
  intersectionStore = useIntersectionStore()

  isBatteryLevelCollapsed = false

  get isBatteryOffline() {
    return this.intersectionStore.isBatteryOffline
  }

  get isAllowCollapsed() {
    return this.$vuetify.breakpoint.mdAndUp
  }

  /// 路口
  get intersectionInfo() {
    return this.intersectionStore.intersectionInfo
  }

  /// 電池
  get batteryInfo() {
    return this.intersectionStore.batteryInfo
  }

  // 避免電量圖表在有電池情況下畫面仍顯示 NaN
  get intersectionBatteryLevel() {
    const batteryA = +this.batteryInfo.A.level || 0
    const batteryB = +this.batteryInfo.B.level || 0
    const avgBatteryLevel = this.intersectionStore.intersectionInfo.avgBatteryLevel

    return avgBatteryLevel ? (batteryA + batteryB) / 2 : 0
  }
}
