
import { Component, Vue, Prop } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
const _DatePicker = DatePicker as any
@Component
export default class DateTimePicker extends Vue {
  @Prop({ type: String, default: '資料時間' }) label!: string
  @Prop({ type: Boolean, default: false }) range!: boolean

  render() {
    return (
      <div>
        <div style='display:flex;'>
          <_DatePicker
            on={this.$listeners}
            props={this.$attrs}
            class='date-time-picker'
            value-type='timestamp'
            range={this.range}
            placeholder={`${this.label}`}
          />
          {this.$slots.append}
        </div>
      </div>
    )
  }
}
