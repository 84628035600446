



























































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import Combobox from '@/components/Combobox.vue'
import { IEditGroupRequest, IUserItem, IGetUserGroupItemResponse } from '@/types/api/user'
import { useAppStore } from '@/store/app'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    Label,
    LabelTextField,
    Combobox,
  },
})
export default class edit extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Array }) userList!: IUserItem[]
  @Prop({ type: Object }) editTemp!: IGetUserGroupItemResponse
  appStore = useAppStore()

  temp: IEditGroupRequest = {
    lineToken: this.editTemp.lineToken,
    projectId: this.editTemp.projectId,
    userGroupId: this.editTemp.userGroupId,
    userGroupLabel: this.editTemp.userGroupLabel,
    userIdList: this.editTemp.userIdList,
  }

  selectedUserIdList: IUserItem[] = this.editTemp.userDetailList

  filterUserId() {
    this.temp.userIdList = this.selectedUserIdList.map((i) => i.userId)
  }

  async checkUserEditData() {
    let status = false
    if (
      this.editTemp.userGroupId !== this.temp.userGroupId ||
      this.editTemp.userGroupLabel !== this.temp.userGroupLabel ||
      this.editTemp.userIdList !== this.temp.userIdList
    ) {
      status = true
    }

    if (status) {
      const { isConfirmed } = await this.$swal.fire({
        text: `群組已有編輯，是否要儲存變更`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
      })

      if (isConfirmed) {
        await this.submitEditUserGroup()
      }
    }

    await this.refreshList()
  }

  async submitEditUserGroup() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    try {
      this.isPageLoading = true
      await this.$api.user.editGroup(this.temp)
      await this.$swal.fire({
        title: '編輯成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      this.selectedUserIdList = []
      this.temp = {
        lineToken: '',
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        userGroupId: '',
        userGroupLabel: '',
        userIdList: [],
      }
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
      await this.refreshList()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  @Emit('refreshList')
  refreshList() {}
}
