
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VFadeTransition, VBtn, VProgressLinear } from 'vuetify/lib/components'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import { IntersectionConfigMap, IntersectionStatusLabelMap, INTERSECTION_LABEL, INTERSECTION_STATUS } from '@/constants/intersection'

@Component
export default class OverviewMapMarkerLabel extends Vue {
  @Prop({ type: String, default: 'intersectionLabel' }) intersectionLabel!: string
  @Prop({ type: String, default: 'unknown' }) intersectionStatus!: INTERSECTION_STATUS
  @Prop({ type: String, default: '0' }) batteryAvgLevel!: string
  @Prop({ type: String }) upsEnableTime!: string
  @Prop({ type: String }) upsEnableDuration!: string

  get batteryAvgLevelInt() {
    return parseInt(this.batteryAvgLevel) || '0'
  }

  render() {
    const statusLabel = IntersectionStatusLabelMap.get(this.intersectionStatus) || INTERSECTION_LABEL.連線異常
    const { color } = IntersectionConfigMap.get(statusLabel) || { color: 'red' }
    return (
      <VFadeTransition>
        <div class='mark-label'>
          <h2>{this.intersectionLabel}</h2>
          <div style='display:flex;'>
            <StatusText color={color}>{statusLabel}</StatusText>
          </div>
          {this.upsEnableTime && <span>啟動時間：{this.upsEnableTime}</span>}
          {this.upsEnableDuration && <span>已運作：{this.upsEnableDuration}</span>}
          <div style='display:flex;gap:1rem;align-items:center;'>
            <VProgressLinear value={this.batteryAvgLevelInt} />
            {this.batteryAvgLevelInt}%
          </div>
          <VBtn
            rounded
            x-small
            color='primary'
            on={{
              click: () => this.$emit('onMoreBtnClick'),
            }}>
            More
          </VBtn>
        </div>
      </VFadeTransition>
    )
  }
}
