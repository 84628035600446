
































import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import { IUserItem, IGetUserGroupItemResponse } from '@/types/api/user'
import { useAppStore } from '@/store/app'
import { IRoleGroup } from '@/types/api/setting'
import { WritableState } from '@/util/annotations'
import SearchResult from '@/components/SearchResult.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { DataTable, downloadCsv, SearchResult } })
export default class index extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  search = ''
  searchResultNum = 0

  userGroupList: IGetUserGroupItemResponse[] = []
  userList: IUserItem[] = []

  viewHeader = [
    { text: '使用者代號', value: 'userLabel', sortable: false, align: 'left' },
    { text: '角色', value: 'roleGroupDesc', sortable: false, align: 'left' },
    { text: '電子信箱', value: 'email', sortable: false, align: 'left' },
    { text: '群組', value: 'userGroupLabel', sortable: false, align: 'left' },
    { text: '新增時間', value: 'createTime', align: 'left' },
  ]

  get tableList() {
    const roleGroup: IRoleGroup[] = JSON.parse(localStorage.getItem('roleGroup')!)

    return this.userList.map((i) => ({
      ...i,
      createTime: i.createTime ? this.$dayjs(i.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
      updateTime: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
      roleGroupDesc: roleGroup.filter((x) => x.roleGroupName === i.userRoleGroupName)[0].roleGroupDesc,
      userGroupLabel: this.userGroupList.filter((x) => i.userGroupIdList?.includes(x.userGroupId)).map((u) => u.userGroupLabel),
    }))
  }

  get CSVTable() {
    return this.userList.map((i) => ({
      使用者代號: i.userLabel,
      角色: i.userRoleGroupName,
      電子信箱: i.email,
      群組: this.userGroupList.filter((x) => i.userGroupIdList?.includes(x.userGroupId)).map((u) => u.userGroupLabel),
      新增時間: i.createTime ? this.$dayjs(i.createTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
    }))
  }

  getFiltered(e: IGetUserGroupItemResponse[]) {
    this.searchResultNum = e.length
  }

  async getUserList(projectId: string) {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getUserList({ projectId })
      this.userList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getUserGroupList(projectId: string) {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getGroupList({ projectId })
      this.userGroupList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    const projectId = JSON.parse(localStorage.getItem('projectId')!)

    if (projectId) {
      await this.getUserList(`${projectId}`)
      await this.getUserGroupList(`${projectId}`)
    }
  }
}
