/* eslint-disable */
import { required, email, max, min, alpha_num, alpha, numeric } from 'vee-validate/dist/rules' // eslint-disable-next-line
import { extend, localize } from 'vee-validate'
import zh_TW from 'vee-validate/dist/locale/zh_TW.json' // eslint-disable-next-line

localize({ zh_TW })
localize('zh_TW')
extend('required', required) // 必填
extend('alpha_num', alpha_num) // 只能數字跟英文
extend('email', email) // email
extend('max', max) //最大用冒號 ex. max:8
extend('min', min) //最小用冒號 ex. max:8
extend('alpha', alpha) // 只能英文字母
extend('numeric', numeric) // 只能數字

extend('password', {
  validate: (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,20}$/.test(value),
  message: '密碼需至少8位數，必須包括大小寫英文及數字',
})

extend('inputLabel', {
  validate: (value) => /^[a-zA-Z0-9]{0,30}$/.test(value),
  message: '僅限英文、數字，最多 30 字',
})

extend('inputGroupLabel', {
  validate: (value) => /^[a-zA-Z0-9_]{0,30}$/.test(value),
  message: '僅限英文、數字，最多 30 字',
})

extend('projectLabel', {
  validate: (value) => /^[\u4e00-\u9fa5a-zA-Z0-9]{0,30}$/.test(value),
  message: '僅限中文、英文、數字，最多 30 字',
})

extend('counter', {
  validate: (value) => value.length < 501,
  message: '僅限 500 字',
})

extend('inputText', {
  validate: (value) => /^[a-z0-9_]*$/.test(value),
  message: '只能輸入小寫英文、數字、底線',
})

//  指定遠傳 mail
extend('FETMail', {
  validate: (value) => /^\w+((-\w+)|(\.\w+))*\@[f][a][r][e][a][s][t][o][n][e]+((\.)[c][o][m]+)*\.[t][w]+$/.test(value),
  message: '請確認格式是否為 FET MAIL',
})

//  5 個字元
extend('projectCodeName', {
  validate: (value) => {
    const hasSpecialText = [...value].includes('^')
    const checkStatus = /^[a-zA-z0-9_]{5,40}$/.test(value)

    if (hasSpecialText) {
      return false
    } else if (!hasSpecialText && checkStatus) {
      return true
    }

    // true 給過， false 不給過
  },
  message: '請使用英文開頭，需輸入至少 5 個字元，字元為英文、數字、底線',
})

//  指定遠傳 mail
extend('lineToken', {
  validate: (value) => /^[a-zA-Z0-9]{1,50}$/.test(value),
  message: '必須符合 Line Token 格式',
})

//  圖形驗證
extend('captcha', {
  validate: (value) => /^[a-zA-Z0-9]{4}$/.test(value),
  message: '驗證碼僅限英文',
})

extend('otp', {
  validate: (value) => /^\d{6}$/.test(value),
  message: '請確認 OTP 碼',
})

extend('projectUserEmail', {
  validate: (value) => /^[a-z0-9@.]*$/.test(value),
  message: '電子信箱格式僅包含小寫英文字母、數字、"@" 符號及點號(".")',
})
