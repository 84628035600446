
import { createChart } from '@/util/echartUtil'
import { getComputedColor } from '@/util/helpers'
import { BarSeriesOption, EChartsOption, LineSeriesOption } from 'echarts'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TimeText from '@/views/IntersectionMgmtDashboard/components/TimeText.vue'
import FixMgmtInstallThumbnails from '@/views/FixMgmtInstall/Components/FixMgmtInstallThumbnails.vue'

interface ILineSeriesOption extends LineSeriesOption {
  data: Array<[string, number]>
}
interface IBarSeriesOption extends BarSeriesOption {
  data: Array<[string, number]>
}

@Component({ components: { TimeText } })
export default class DashboardTimeChart extends Vue {
  @Prop({ type: Number, default: 300 }) height!: number
  @Prop({ type: String, default: '--v-secondary-lighten2' }) baseColor!: string
  @Prop({ type: String, default: 'title' }) title!: string

  @Prop({ type: Number, default: 8 }) maxLength!: number
  @Prop({ type: Array, default: () => [getComputedColor('--v-primary-base'), '#b94aff'] }) seriesColors!: Array<String>

  @Prop({ type: Boolean, default: false }) disableAvgValue!: boolean
  @Prop({ type: Boolean }) disabledXAxisLabelFormatter!: boolean
  @Prop({ type: Boolean }) disabledDataZoom!: boolean
  @Prop({ type: Boolean }) disabledTooltipTimeFormat!: boolean
  @Prop({ type: String }) time!: string
  @Prop({ type: String }) xAxisType?: 'time' | 'category'

  @Prop({
    type: Array,
    default: () => [
      {
        name: 'SeriesA',
        type: 'line',
      },
      {
        name: 'SeriesB',
        type: 'line',
      },
    ],
  })
  series!: Array<ILineSeriesOption | IBarSeriesOption>

  @Watch('series', { immediate: true })
  onSeriesChange(series: Array<ILineSeriesOption | IBarSeriesOption>) {
    series.forEach((v, i) => {
      if (this.opts.xAxis) {
        this.$set(this.opts.xAxis, 'boundaryGap', v.type === 'bar')
      }

      if (v.type === 'line') {
        Object.assign(v, { smooth: false, showSymbol: false })
      }

      if (v.color === undefined) {
        Object.assign(v, { color: this.seriesColors[i % this.seriesColors.length] })
      }

      if (v.data === undefined) {
        Object.assign(v, {
          data: [
            [1647877402324, Math.random() * 100],
            [1647877403324, Math.random() * 100],
            [1647877404324, Math.random() * 100],
            [1647877405324, Math.random() * 100],
          ],
        })
      }
      this.$set(this.seriesPtr, i, v)
    })
  }

  render() {
    return (
      <div>
        <h2 style='text-align:left;margin-bottom:1rem;'>{this.title}</h2>
        <TimeText>{this.time}</TimeText>
        <div class='line-chart'>
          <div class='line-chart__avg'>
            {this.disableAvgValue
              ? this.seriesPtr.map((series) => {
                  return (
                    <strong>
                      <small>累計 </small>
                      {this.calcSum(series).toFixed(2)}
                    </strong>
                  )
                })
              : this.seriesPtr.map((series) => {
                  return (
                    <strong>
                      <small>平均 </small>
                      {this.calcAvg(series).toFixed(2)}
                    </strong>
                  )
                })}
          </div>
          <div ref='chart' style={{ width: '100%', height: `${this.height}px` }} />
          {this.isEmpty && <div class='line-chart__empty'>沒有資料</div>}
        </div>
      </div>
    )
  }

  get isEmpty() {
    return this.seriesPtr.every((x) => x.data.length === 0)
  }

  calcSum(series: ILineSeriesOption | IBarSeriesOption) {
    let sum = 0
    for (const [, v] of series.data) {
      sum += isNaN(+v) ? 0 : +v
    }
    return sum
  }

  calcAvg(series: ILineSeriesOption | IBarSeriesOption) {
    return this.calcSum(series) / series.data.length
  }

  seriesPtr: Array<ILineSeriesOption | IBarSeriesOption> = []

  lineStyle = {
    width: 1,
    color: getComputedColor(this.baseColor),
  }

  opts: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    emphasis: {
      disabled: true,
    },
    grid: {
      bottom: 0,
      top: 85,
      right: 0,
      left: 0,
      containLabel: true,
      borderWidth: 2,
      borderColor: getComputedColor(this.baseColor),
      show: true,
    },
    xAxis: {
      type: 'time',
      axisTick: { show: false },
      // boundaryGap: true, /** @see https://echarts.apache.org/zh/option.html#xAxis.boundaryGap */
      axisLine: { show: false },
      splitLine: {
        show: true,
        lineStyle: this.lineStyle,
      },
      axisLabel: {
        color: this.lineStyle.color,
        // showMaxLabel: false,
        // showMinLabel: false
        hideOverlap: true,
      },
    },
    yAxis: {
      type: 'value',
      axisTick: { show: false },
      axisLine: { show: false },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: this.lineStyle.color,
      },
      max: ({ max }) => +(max + Math.abs(max) * 0.5).toFixed(0),
      min: ({ min }) => +(min - Math.abs(min) * 0.5).toFixed(0),
    },
    series: this.seriesPtr,
    legend: {
      show: true,
      left: 0,
      icon: 'rect',
      orient: 'vertical',
      textStyle: {
        color: getComputedColor(this.baseColor),
      },
      itemHeight: 8,
    },
  }

  mounted() {
    this.$nextTick(async () => {
      const chartRef = this.$refs.chart
      await createChart.call(this, chartRef, this.opts)
    })
    if (!this.disabledTooltipTimeFormat) {
      ;(this.opts.tooltip as any).formatter = (params: any) => {
        // console.log(params)
        return params
          .map((p: any) => {
            const [t, v, label] = p.data
            return `<div style="display:flex;align-items:center;gap:0.5rem;">
            <i style="border-radius:100%;;width:0.8rem;height:0.8rem;background-color:${p.color};"></i>
            ${label ? `<strong>${label}</strong>` : p.seriesName} ${this.$dayjs(t).format('MM/DD HH:mm:ss')}
            <strong>${v}</strong>
          </div>`
          })
          .join('<br/>')
      }
    }
    if (!this.disabledDataZoom) {
      this.opts.dataZoom = {
        start: 0,
        type: 'inside',
        filterMode: 'none',
      }
    }
    if (!this.disabledXAxisLabelFormatter) {
      ;(this.opts.xAxis as any).axisLabel.formatter = (value: string) => {
        if (value === '') {
          return value
        }
        return this.$dayjs(Number(value)).format('HH:mm:ss')
      }
    }

    if (this.xAxisType === 'category') {
      ;(this.opts.xAxis as any).type = 'category'
    }
  }
}
