








































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import DefaultSelect from '@/components/DefaultSelect.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import Combobox from '@/components/Combobox.vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { IGetProjectItem, IEditProjectUser, IGetProjectUserItem } from '@/types/api/project'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    Label,
    LabelTextField,
    DefaultSelect,
    LabelSelect,
    Combobox,
  },
})
export default class edit extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Object }) editTemp!: IGetProjectUserItem
  @Prop({ type: Array }) projectList!: IGetProjectItem[]
  @Prop({ type: Boolean }) hasOtpStatus!: boolean

  userStore = useUserStore()

  temp: IEditProjectUser = {
    userId: this.editTemp.userId,
    userLabel: this.editTemp.userLabel,
    userRoleGroupName: this.editTemp.userRoleGroupName,
    projectIdList: this.editTemp.projectIdList,
    defaultProjectId: this.editTemp.defaultProjectId,
  }

  userRoleGroupList = JSON.parse(localStorage.getItem('roleGroup')!)
  selectedProjectList: IGetProjectItem[] = this.projectList.filter((o) => this.editTemp.projectIdList?.includes(o.projectId))

  filterProjectIdList() {
    this.temp.projectIdList = this.selectedProjectList.map((i) => i.projectId)
  }

  async handleDeleteOtpSecret() {
    const { isConfirmed } = await this.$swal.fire({
      title: '確定要刪除 TOTP 設定',
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true

        await this.$api.otp.deleteOtpByAdmin({ userId: this.editTemp.userId })
        await this.$swal.fire({
          title: '已刪除 TOTP',
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })
        await this.$emit('checkUserTotp', this.editTemp.email)
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  async handleDeleteProjectUser() {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除 ${this.temp.userLabel}?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true
        await this.$api.project.deleteProjectUser({ userId: `${this.temp.userId}` })
        await this.$swal.fire({
          title: '刪除成功',
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })
        await this.$emit('refreshList')
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    if (!this.temp.projectIdList.includes(this.temp.defaultProjectId)) {
      this.temp.projectIdList = [this.temp.defaultProjectId, ...this.temp.projectIdList]
    }

    let text = ''
    if (this.temp.defaultProjectId !== this.editTemp.defaultProjectId) {
      text = '預設專案已變更請重新登入'
    }

    await this.submitEditUser(text)
  }

  async submitEditUser(text = '') {
    try {
      this.isPageLoading = true
      await this.$api.project.editProjectUser(this.temp)

      if (text) {
        await this.showLogOutDialog(text)
      } else {
        await this.showDialog()

        this.selectedProjectList = []
        this.temp = {
          projectIdList: [],
          userId: '',
          userLabel: '',
          userRoleGroupName: '',
          defaultProjectId: '',
        }
        ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
        await this.$emit('refreshList')
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  showDialog() {
    this.$swal.fire({
      title: '編輯成功',
      icon: 'success',
      toast: true,
      position: 'top',
      timer: 1500,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }

  async showLogOutDialog(text: string) {
    const { isConfirmed } = await this.$swal.fire({ title: `編輯成功`, text, icon: 'warning', showCancelButton: false })
    if (isConfirmed) {
      await this.userStore.logout()
    }
  }
}
