<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        dense
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        hide-details
        v-bind="attrs"
        :disabled="disabled"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      locale="zh"
      :type="type"
      :min="min"
      :max="max"
      no-title
      scrollable
      :day-format="(v) => +v.replace(/^\d+-\d+-/, '')"
      @input="updateDate(date)"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  data() {
    return {
      menu: false,
      date: '',
    }
  },
  watch: {
    date(val) {
      this.$emit('input', val)
    },
    value: {
      handler(date) {
        this.date = date
        this.updateDate(date)
      },
      immediate: true,
    },
  },
  methods: {
    updateDate(date) {
      if (this.$refs.menu === undefined) {
        return
      }
      this.menu = false
      this.$refs.menu.save(date)
    },
  },
  props: {
    value: String,
    label: String,
    min: String,
    max: String,
    disabled: Boolean,
    type: {
      type: String,
      default: () => 'date',
    },
  },
}
</script>
