
import { getComputedColor } from '@/util/helpers'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VIcon } from 'vuetify/lib'

@Component
export default class Label extends Vue {
  @Prop({ type: String, default: 'grey' }) iconColor!: string
  @Prop({ type: String }) icon?: string
  @Prop({ type: String }) title?: string

  render() {
    return (
      <div class='label' attrs={this.$attrs} on={this.$listeners}>
        {this.icon && <VIcon style={{ color: getComputedColor(this.iconColor) }}>{this.icon}</VIcon>}
        {this.title && <div class='labelTitle'>{this.title}</div>}
        {this.$slots.default && <div class='labelContent'>{this.$slots.default}</div>}
      </div>
    )
  }
}
