

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IEventInputErrorLogResponse } from '@/types/api/event'
import { useIntersectionStore } from '@/store/intersection'

@Component
export default class IndexInputErrorLog extends Vue {
  @Prop({ type: String }) time!: string

  inputErrorList: IEventInputErrorLogResponse[] = []

  tableHeader = [
    { text: '項次', value: 'index', sortable: false, align: 'left' },
    { text: '斷電路口', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '斷電開始時間', value: 'startTime', align: 'left' },
    { text: '市電恢復時間', value: 'endTime', sortable: false, align: 'left' },
    { text: '斷電事件持續時間', value: 'continueTime', sortable: false, align: 'left' },
    { text: '換電次數', value: 'changeTimes', sortable: false, align: 'left' },
    { text: '累積消耗電量', value: 'totalPower', sortable: false, align: 'right' },
  ]

  startTime = this.$dayjs().startOf('month').valueOf() // 取得當前月份的第一天
  endTime = this.$dayjs().endOf('month').valueOf() // 取得當前月份的最後一天

  @Watch('time')
  async onChange() {
    this.startTime = this.$dayjs(this.time).startOf('month').valueOf()
    this.endTime = this.$dayjs(this.time).endOf('month').valueOf()

    await this.setInputErrorLog()
  }

  get title() {
    return `${this.time ? this.$dayjs(this.time).format('YYYY 年 MM ') : this.time}月斷電事件資訊`
  }

  get intersectionList() {
    return this.intersectionStore.intersectionItems
  }

  get tableList() {
    return this.inputErrorList.map((i, index) => ({
      ...i,
      index: index + 1,
      intersectionLabel: this.intersectionList.filter((o) => o.intersectionId === i.intersectionId)[0]?.intersectionLabel,
      continueTime: this.calculateTime(i.startTime, i.endTime),
      totalPower: (i.totalPower ?? 0).toFixed(0),
    }))
  }

  calculateTime(startTime: number, endTime: number) {
    if (endTime > startTime) {
      const diffTime = endTime - startTime

      const hours = Math.floor(diffTime / (1000 * 60 * 60))
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60)
      const second = Math.floor((diffTime / 1000) % 60) + 1
      return `${hours}H : ${minutes}m :  ${second}s`
    }

    return ''
  }

  intersectionStore = useIntersectionStore()

  async setInputErrorLog() {
    try {
      const query = {
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime, // 取得當前月份的第一天
        endTime: this.endTime, // 取得當前月份的最後一天
        isFilterMobile: true,
      }
      const data = await this.$api.event.getInputErrorLog(query)
      this.inputErrorList = data!.filter(({ intersectionId }) => intersectionId === this.intersectionStore.intersectionIdActived)!
    } catch (error) {
      console.log(error)
    }
  }

  async mounted() {
    await this.setInputErrorLog()
  }
}
