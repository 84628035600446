













import { Component, Vue, Prop } from 'vue-property-decorator'
import { IEventInputErrorLogResponse } from '@/types/api/event'
import { IGetIntersectionItem } from '@/types/api/intersection'

@Component
export default class InputTableLog extends Vue {
  @Prop({ type: Array }) inputErrorList!: IEventInputErrorLogResponse[]
  @Prop({ type: Array }) intersectionList!: IGetIntersectionItem[]

  tableHeader = [
    { text: '項次', value: 'index', sortable: false, align: 'left' },
    { text: '斷電路口', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '斷電開始時間', value: 'startTime', align: 'left' },
    { text: '市電恢復時間', value: 'endTime', sortable: false, align: 'left' },
    { text: '斷電事件持續時間', value: 'continueTime', sortable: false, align: 'left' },
    { text: '換電次數', value: 'changeTimes', sortable: false, align: 'left' },
    { text: '累積消耗電量', value: 'totalPower', sortable: false, align: 'right' },
  ]

  get tableList() {
    return this.inputErrorList.map((i, index) => ({
      index: index + 1,
      intersectionId: i.intersectionId,
      intersectionLabel: this.intersectionList.filter((o) => o.intersectionId === i.intersectionId)[0]?.intersectionLabel,
      endTime: i.endTime,
      startTime: i.startTime,
      continueTime: this.calculateTime(i.startTime, i.endTime),
      changeTimes: i.changeTimes,
      totalPower: i.totalPower === null ? '-' : `${i.totalPower.toFixed(0)} %`,
    }))
  }

  calculateTime(startTime: number, endTime: number) {
    if (endTime > startTime) {
      const diffTime = endTime - startTime

      const hours = Math.floor(diffTime / (1000 * 60 * 60))
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60)
      const second = Math.floor((diffTime / 1000) % 60) + 1
      return `${hours}H : ${minutes}m :  ${second}s`
    }

    return ''
  }
}
