




















import { Component, Vue } from 'vue-property-decorator'
import TableList from './Views/tableList.vue'
import AddProject from './Views/add.vue'

@Component({
  components: {
    TableList,
    AddProject,
  },
})
export default class ProjectMgmt extends Vue {
  tabIndex = 0

  async changeTab() {
    if (this.$refs.TableList as TableList) {
      if (this.tabIndex === 1) (this.$refs.TableList as TableList).getProjectList()
      ;(this.$refs.TableList as TableList).showTableStatus = true
    }
  }
}
