import { render, staticRenderFns } from "./indexUps.vue?vue&type=template&id=4119941d&"
import script from "./indexUps.vue?vue&type=script&lang=tsx&"
export * from "./indexUps.vue?vue&type=script&lang=tsx&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports