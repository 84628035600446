var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-black"},[_vm._v("新增使用者")]),_c('ValidationObserver',{ref:"form"},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"userLabel",attrs:{"name":"使用者代號","rules":"required|inputLabel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"clearable":"","hide-details":"","label":"使用者代號","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.userLabel),callback:function ($$v) {_vm.$set(_vm.temp, "userLabel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.userLabel"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'角色'}}),_c('ValidationProvider',{ref:"userRoleGroupName",attrs:{"name":"角色","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"row","hide-details":""},model:{value:(_vm.temp.userRoleGroupName),callback:function ($$v) {_vm.$set(_vm.temp, "userRoleGroupName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.userRoleGroupName"}},_vm._l((_vm.userRoleGroupList),function(role,index){return _c('v-radio',{key:index,attrs:{"label":role.roleGroupDesc,"value":role.roleGroupName}})}),1),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('v-row',{staticClass:"d-flex justify-baseline align-baseline"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"name":"電子信箱","rules":"required|projectUserEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"clearable":"","hide-details":"","label":"電子信箱","outlined":"","solo":"","dense":""},model:{value:(_vm.temp.email),callback:function ($$v) {_vm.$set(_vm.temp, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.email"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Label',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"title":'選擇專案 (第一筆為預設專案)'}}),_c('ValidationProvider',{ref:"selectedProjectList",attrs:{"name":"專案","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Combobox',{attrs:{"label":"選擇專案","itemText":"projectLabel","itemValue":"projectId","items":_vm.projectList,"deletable-chips":"","hide-details":"","clearable":"","multiple":"","chips":"","returnObject":"","outlined":"","solo":"","dense":""},on:{"change":_vm.filterProjectIdList},model:{value:(_vm.selectedProjectList),callback:function ($$v) {_vm.selectedProjectList=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedProjectList"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-8"},[_c('v-btn',{staticClass:"submitBtn",attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("確認")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }