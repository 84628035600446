
































































































import { useAppStore } from '@/store/app'
import { Component, Vue } from 'vue-property-decorator'
import { WritableState } from '@/util/annotations'
import DateTimePicker from '@/components/DateTimePicker.vue'
import { IIntersectionMgmt } from '@/types/api/intersection'
import { ITelemetryGetStatusResponse } from '@/types/api/event'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class IntersectionHourlyStatusInfo extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  startTimeStatus = false
  endTimeStatus = false

  dateValue = +this.$dayjs().valueOf()
  startTime = '00:00'
  endTime = '23:59'
  CSVname = ''

  intersectionId = ''
  intersectionLabel = ''
  intersectionList: IIntersectionMgmt[] = []
  telemetryStatusList: ITelemetryGetStatusResponse['data'] = []

  get CSVTable() {
    return this.telemetryStatusList.map((i, index) => ({
      項次: index + 1,
      路口名稱: i.intersectionLabel,
      來源傳送時間: i.dataTime ? this.$dayjs(i.dataTime).format('YYYY年 MM月 DD日 HH時 mm分') : '---/--/-- --:--:--',
      [`市電電壓(V)`]: i.inputVoltage === 'NaN' || i.inputVoltage === null ? '-' : `${i.inputVoltage} V`,
      [`路口電量(%)`]: i.avgBatteryLevel === 'NaN' || i.avgBatteryLevel === null ? '-' : `${i.avgBatteryLevel} %`,
      [`UPS溫度(℃)`]: i.upsTemperature === 'NaN' || i.upsTemperature === null ? '-' : `${i.upsTemperature} ℃`,
      [`UPS功率(W)`]: i.upsOutput === 'NaN' || i.upsOutput === null ? '-' : `${i.upsOutput} W`,
      [`市電頻率(Hz)`]: i.inputFrequency === 'NaN' || i.inputFrequency === null ? '-' : `${i.inputFrequency} Hz`,
    }))
  }

  get mappingIntersectionList() {
    return this.intersectionList.map((i) => ({
      ...i,
      intersectionIndex: `${i.intersectionNumber} ${i.intersectionLabel}`,
    }))
  }

  disableDate(date: Date) {
    const today = new Date()

    return date > today || date < new Date(today.setMonth(today.getMonth() - 3))
  }

  getIntersectionLabel() {
    this.intersectionLabel = this.intersectionList.filter((i) => i.intersectionId === this.intersectionId)[0].intersectionLabel
  }

  checkTime() {
    const [startTimeHour] = this.startTime.split(':')
    const [endTimeHour] = this.endTime.split(':')

    if (+startTimeHour > +endTimeHour) {
      this.$swal.fire({
        title: '結束時間不能早於起始時間',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  checkIntersection() {
    if (this.intersectionId === '') {
      this.$swal.fire({
        title: '請選擇路口',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }

    return false
  }

  checkTelemetry(count: number) {
    if (count === 0) {
      this.$swal.fire({
        title: `${this.intersectionLabel}尚無資料`,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  async handleDownload() {
    if (this.checkTime() || this.checkIntersection()) return

    this.CSVname = `${this.$dayjs(this.dateValue).format('YYYY_MM_DD')}_${this.intersectionLabel}_路口逐時狀態資訊.csv`

    await this.getTelemetryStatus()
    this.doDownLoad()
  }

  async getTelemetryStatus(page = 0) {
    if (page === 0) {
      this.telemetryStatusList.splice(0)
    }

    const startTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.startTime}`
    const endTimeString = `${this.$dayjs(this.dateValue).format('YYYY-MM-DD')} ${this.endTime}`

    try {
      this.isPageLoading = true

      const data = await this.$api.event.getTelemetryStatus({
        intersectionId: this.intersectionId,
        startTime: `${this.$dayjs(startTimeString).valueOf()}`,
        endTime: `${this.$dayjs(endTimeString).valueOf()}`,
        sort: 'DESC',
        pageSize: 10000,
        page,
      })

      if (data === undefined) throw new TypeError()
      if (this.checkTelemetry(data.totalElements)) return

      this.telemetryStatusList = [...this.telemetryStatusList, ...data.data]

      if (data.hasNext) {
        this.getTelemetryStatus(page + 1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  doDownLoad() {
    const downloadCsv = document.getElementById('IntersectionHourlyStatusInfoDownloadCsv')
    downloadCsv && downloadCsv.click()
  }

  async getIntersectionCurrentList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.intersection.getIntersectionCurrentList({ projectId: JSON.parse(localStorage.getItem('projectId')!) })
      this.intersectionList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getIntersectionCurrentList()
  }
}
