












import { getComputedColor } from '@/util/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Dialog extends Vue {
  @Prop({ type: String, default: 'Dialog title' }) title?: string
  @Prop({ type: String, default: '--v-text-primary-base' }) headerBgColor!: string
  @Prop({ type: String, default: '--v-text-accent-base' }) headerTextColor!: string
  @Prop({ type: String, default: '--v-text-primary-lighten1' }) contentBgColor!: string

  get style() {
    const { left } = this.$vuetify.application
    return {
      top: 0,
      left: `${left}px`,
      right: 0,
      backgroundColor: getComputedColor(this.contentBgColor),
    }
  }

  get headerStyle() {
    return {
      backgroundColor: getComputedColor(this.headerBgColor),
      color: getComputedColor(this.headerTextColor),
    }
  }
}
