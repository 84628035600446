var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-overlay',{attrs:{"value":_vm.loading,"z-index":"999"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1),_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"wrap",style:(("background-image:url(" + _vm.bg + ")"))},[_c('div',{staticClass:"glass"},[_c('v-toolbar-title',{staticClass:"mb-5 glass__title"},[_vm._v(_vm._s(_vm.SYS_NAME))]),_c('ValidationProvider',{ref:"email",attrs:{"name":"信箱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dark":"","prepend-icon":"mdi-account","name":"Email","label":"信箱","type":"text","autocomplete":"off","error-messages":errors},on:{"focus":function($event){_vm.errMsg = ''},"input":_vm.inputChange},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})]}}])}),_c('ValidationProvider',{ref:"secret",attrs:{"name":"密碼","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"mdi-password","dark":"","prepend-icon":"mdi-lock","name":"Secret","label":"密碼","autocomplete":"off","error-messages":errors,"append-icon":_vm.passwordStatus ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.passwordStatus ? 'password' : 'text'},on:{"click:append":function($event){_vm.passwordStatus = !_vm.passwordStatus},"focus":function($event){_vm.errMsg = ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.hasOtpStatus ? _vm.doOTPLogin : _vm.handleLogin}},model:{value:(_vm.secret),callback:function ($$v) {_vm.secret=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"secret"}})]}}])}),_c('ValidationProvider',{ref:"totp",attrs:{"name":"TOTP","rules":_vm.hasOtpStatus ? 'required|otp' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hasOtpStatus)?_c('v-text-field',{attrs:{"id":"mdi-totp","maxlength":"6","dark":"","prepend-icon":"mdi-account-key","name":"Totp","label":"TOTP","autocomplete":"off","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doOTPLogin.apply(null, arguments)}},model:{value:(_vm.totp),callback:function ($$v) {_vm.totp=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"totp"}}):_vm._e()]}}])}),_c('div',[(_vm.loadingChangeCaptchaImg)?[_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"60px"},attrs:{"type":"image"}})]:[_c('img',{staticStyle:{"width":"100%","height":"60px"},attrs:{"src":_vm.captchaImgBase64Src,"alt":"captcha"}})],_c('ValidationProvider',{ref:"uppercaseInput",attrs:{"name":"圖形驗證碼","rules":"required|captcha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dark":"","name":"captcha","label":"請輸入驗證碼","type":"captcha","hide-details":"","append-outer-icon":"mdi-refresh"},on:{"hook:mounted":_vm.onChangeCaptcha,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleLogin.apply(null, arguments)},"click:append-outer":_vm.onChangeCaptcha},model:{value:(_vm.uppercaseInput),callback:function ($$v) {_vm.uppercaseInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"uppercaseInput"}}),_c('div',{staticClass:"errorAlert mb-8"},[_vm._v(_vm._s(errors[0]))])]}}])})],2),(_vm.errMsg)?_c('p',{staticStyle:{"text-align":"center","color":"var(--v-error-lighten1)"}},[_vm._v(_vm._s(_vm.errMsg))]):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[(_vm.hasOtpStatus)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.doOTPLogin}},[_vm._v("登入")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleLogin}},[_vm._v("登入")])],1),_c('v-btn',{staticClass:"mt-3 font-weight-black",attrs:{"disabled":_vm.loading,"color":"white","plain":"","x-large":""},on:{"click":_vm.handleForgetPassword}},[_vm._v(" 忘記密碼? ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }