






















































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import Upload from '../Upload/Upload.vue'
import UploadBatch from '../Upload/UploadBatch.vue'
import LabelTextField from '@/components/LabelTextField.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import AutoCombobox from '@/components/AutoCombobox.vue'
import { useAppStore } from '@/store/app'
import { IIntersectionRequest, IGetIntersectionItem, ICreateIntersectionList } from '@/types/api/intersection'
import { IFileData } from '@/types/api/uploadFile'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    Upload,
    UploadBatch,
    LabelTextField,
    LabelSelect,
    AutoCombobox,
  },
})
export default class DevicesMgmtCreateIntersectionUpload extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Array }) tableList!: IGetIntersectionItem[]

  intersectionGroup = JSON.parse(localStorage.getItem('intersectionGroup')!)

  intersectionLabel = ''
  intersectionGroupId = ''
  longitude = ''
  latitude = ''
  checkGroupStatus = false

  intersectionType = 'FIXED'

  filePayload: IIntersectionRequest = {
    projectId: JSON.parse(localStorage.getItem('projectId')!),
    createIntersectionList: [],
  }

  csvExample = [
    {
      路口名稱: '中山路',
      群組: '大安區',
      經度: '25.05186944868742',
      緯度: '121.54397289047647',
    },
  ]

  type: '' | 'batch' | 'manual' = ''

  get intersectionGroups() {
    return this.intersectionGroup && Object.entries(this.intersectionGroup).map(([key, value]) => ({ label: value, value: key }))
  }

  onBatchCreate(fileData: IFileData) {
    if (
      this.hasFileDuplicateIntersectionLabel(this.getFileData(fileData)) ||
      this.hasTableDuplicateIntersectionLabel(this.getFileData(fileData)) ||
      this.checkDownTownGroupId(this.getFileData(fileData))
    ) {
      ;(this.$refs.UploadBatch as UploadBatch).onFileRemove(0)
    } else {
      this.filePayload.createIntersectionList = this.transformGroupId(this.getFileData(fileData))
    }
  }

  getFileData(fileData: IFileData) {
    const createIntersectionList = []

    if (Array.isArray(fileData)) {
      for (const { result } of fileData) {
        const { Sheet1 } = result

        for (let i = 1; i < Sheet1.length; i++) {
          const row = Sheet1[i]
          const intersectionLabel = row[0]
          const groupId = row[1]
          const latitude = row[2]
          const longitude = row[3]

          createIntersectionList.push({ intersectionLabel, groupId, latitude, longitude })
        }
      }
    }

    return createIntersectionList
  }

  transformGroupId(createIntersectionList: ICreateIntersectionList[]) {
    createIntersectionList.forEach((i, index) => {
      for (const key in this.intersectionGroup) {
        if (i.groupId === this.intersectionGroup[key]) {
          createIntersectionList[index].groupId = key
          createIntersectionList[index].intersectionType = 'FIXED'
        }
      }
    })

    return createIntersectionList
  }

  hasFileDuplicateIntersectionLabel(createIntersectionList: ICreateIntersectionList[]) {
    const labelList = createIntersectionList.map((i) => i.intersectionLabel)
    const repeatList = labelList.filter((i, index) => labelList.indexOf(i) !== index)

    if ([...new Set(repeatList)].length > 0) {
      this.toggleSwalAlert('檔案含有重複的路口名稱', [...new Set(repeatList)])
      return true
    }
    return false
  }

  hasTableDuplicateIntersectionLabel(createIntersectionList: ICreateIntersectionList[], alertMessage = '') {
    const labelList = this.tableList.map((i) => i.intersectionLabel)
    const repeatList = createIntersectionList.filter((i) => labelList.includes(i.intersectionLabel)).map((o) => o.intersectionLabel)

    if ([...new Set(repeatList)].length > 0) {
      this.toggleSwalAlert(alertMessage, [...new Set(repeatList)])
      return true
    }

    return false
  }

  checkDownTownGroupId(createIntersectionList: ICreateIntersectionList[]) {
    const downTownGroupValueList = Object.values(this.intersectionGroup).map((item) => item)
    const failGroupIdList = createIntersectionList.filter((i) => !downTownGroupValueList.includes(`${i.groupId}`)).map((o) => o.groupId)

    if ([...new Set(failGroupIdList)].length > 0) {
      this.toggleSwalAlert('檔案有錯誤的群組', [...new Set(failGroupIdList)])
      return true
    }

    return false
  }

  toggleSwalAlert(title: string, uniqueList: string[]) {
    let htmlString = '<ol style="display: inline-block; text-align: left">'
    uniqueList.forEach((item) => {
      htmlString += `<li>${item}</li>`
    })
    htmlString += '</ol>'

    this.$swal.fire({ title, html: htmlString, icon: 'warning', showCancelButton: false })
  }

  async handleSubmitFile() {
    if (this.filePayload.createIntersectionList.length === 0) return

    try {
      this.isPageLoading = true

      await this.$api.intersection.create(this.filePayload)
      await this.$swal.fire({
        title: '新增成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      await this.refreshList()
      this.filePayload.createIntersectionList = []
      ;(this.$refs.UploadBatch as UploadBatch).files = []
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleSubmitInterSection() {
    const validateStatus = await (this.$refs.FIXEDForm as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    const payload: IIntersectionRequest = {
      projectId: JSON.parse(localStorage.getItem('projectId')!),
      createIntersectionList: [
        {
          groupId: this.intersectionGroupId,
          intersectionLabel: this.intersectionLabel,
          intersectionType: 'FIXED',
          latitude: this.latitude,
          longitude: this.longitude,
        },
      ],
    }

    if (this.hasTableDuplicateIntersectionLabel(payload.createIntersectionList, '與列表的路口名稱已重複')) return
    await this.doSubmit(payload)
  }

  async handleSubmitBatteryCar() {
    const validateStatus = await (this.$refs.MOBILEForm as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    const payload: IIntersectionRequest = {
      projectId: JSON.parse(localStorage.getItem('projectId')!),
      createIntersectionList: [
        {
          groupId: this.intersectionGroupId,
          intersectionLabel: this.intersectionLabel,
          intersectionType: 'MOBILE',
          latitude: '',
          longitude: '',
        },
      ],
    }

    if (this.hasTableDuplicateIntersectionLabel(payload.createIntersectionList, '與列表的電車已重複')) return
    await this.doSubmit(payload)
  }

  async doSubmit(payload: IIntersectionRequest) {
    try {
      this.isPageLoading = true

      await this.$api.intersection.create(payload)
      await this.$swal.fire({
        title: '新增成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      await this.refreshList()
      this.intersectionLabel = ''
      this.intersectionGroupId = ''
      this.longitude = ''
      this.latitude = ''
      ;(this.$refs[`${this.intersectionType}Form`] as Vue & { reset: () => boolean }).reset()
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }

  @Emit('refreshList')
  refreshList() {}
}
