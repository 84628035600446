













































































































































import { Component, Vue } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import Combobox from '@/components/Combobox.vue'
import FixMgmtStatus from './Components/FixMgmtStatus.vue'
import ConstructionImage from './Components/ConstructionImage.vue'
import ShowImage from './Components/ShowImage.vue'
import Textarea from './Components/Textarea.vue'
import { IIntersectionMgmt } from '@/types/api/intersection'
import { IDeviceInstallFile } from '@/types/device'
import { IDeviceInstallDetailResponse } from '@/types/api/device'
import { useAppStore } from '@/store/app'
import { IUserItem } from '@/types/api/user'
import { IDeviceStatusItem } from '@/types/api/setting'
import { WritableState } from '@/util/annotations'

const checkDeviceInfo = {
  checkBatteryInfoList: [null, null],
  gatewayLabel: null,
  inputStatus: null,
  inputVoltage: null,
  upsLabel: null,
  upsStatus: null,
  upsTemperature: null,
  updateTime: null,
}

const intersectionTemp = {
  batteryLabelList: [],
  gatewayLabel: '',
  installDeviceStatus: '',
  intersectionId: '',
  intersectionLabel: '',
  intersectionNumber: '',
  updateTime: 0,
  upsLabel: '',
  intersectionType: 'FIXED',
}

const installTemp = {
  intersectionId: '',
  checkDeviceInfo: {
    checkBatteryInfoList: [
      {
        batteryLabel: '',
        batteryLevel: 0,
        batteryStatus: '',
      },
    ],
    gatewayLabel: '',
    inputStatus: '',
    inputVoltage: '',
    upsLabel: '',
    upsStatus: '',
    upsTemperature: '',
  },
}

const installDetailTemp = {
  imageBase64List: [],
  installDeviceStatus: '',
  intersectionId: '',
  remark: '',
}

@Component({
  components: { LabelTextField, Label, Combobox, FixMgmtStatus, ConstructionImage, Textarea, ShowImage },
})
export default class FixMgmtInstall extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  intersectionId = ''
  intersectionTemp = JSON.parse(JSON.stringify(intersectionTemp))
  installTemp = JSON.parse(JSON.stringify(installTemp))
  installDetailTemp = JSON.parse(JSON.stringify(installDetailTemp))
  beforeImageBase64ListSize = 0
  afterImageBase64ListSize = 0
  duringImageBase64ListSize = 0

  btnStatusList: IDeviceStatusItem[] = []
  userList: IUserItem[] = []
  intersectionList: IIntersectionMgmt[] = []
  installDetailLogList: IDeviceInstallDetailResponse[] = []
  checkDeviceInfo = JSON.parse(JSON.stringify(checkDeviceInfo))

  afterImageBase64List: string[] = []
  beforeImageBase64List: string[] = []
  duringImageBase64List: string[] = []

  get showUploadBtn() {
    return this.afterImageBase64List.length > 0 || this.beforeImageBase64List.length > 0 || this.duringImageBase64List.length > 0
  }

  get ADMIN_ROLE() {
    const userRoleGroupName = JSON.parse(localStorage.getItem('userRoleGroupName')!)

    return userRoleGroupName === 'BMS_PROJECT_ADMIN' || userRoleGroupName === 'BMS_SYSTEM_ADMIN' || userRoleGroupName === 'BMS_ENGINEER'
  }

  get installStatus() {
    return this.intersectionTemp?.installDeviceStatus === 'INSTALL'
  }

  get mappingInstallDetailLog() {
    return this.installDetailLogList.map((i) => ({
      ...i,
      btnStatus: this.btnStatusList.filter((o) => o.status === i.installDeviceStatus)[0],
      actionUserLabel: this.userList.filter((o) => o.userId === i.actionUserId)[0]?.userLabel,
    }))
  }

  get mappingIntersectionList() {
    return this.intersectionList.map((i) => ({
      ...i,
      intersectionIndex: `${i.intersectionNumber} ${i.intersectionLabel}`,
    }))
  }

  async selectInstallLogs() {
    this.intersectionId = this.intersectionTemp?.intersectionId

    if (!this.intersectionId) return
    await this.initData()
    await this.getInstallImages()
    await this.getInstalledLogs()
    await this.getInstallDetailLog()
    await this.updateStatus()
  }

  initImages() {
    this.beforeImageBase64List = []
    this.afterImageBase64List = []
    this.duringImageBase64List = []
    ;(this.$refs.beforeImageFiles as ConstructionImage).files = []
    ;(this.$refs.duringImageFiles as ConstructionImage).files = []
    ;(this.$refs.afterImageFiles as ConstructionImage).files = []
    ;(this.$refs.Textarea as Textarea).clearImageBase64List()
  }

  initData() {
    this.checkDeviceInfo = JSON.parse(JSON.stringify(checkDeviceInfo))
    this.installDetailTemp = JSON.parse(JSON.stringify(installDetailTemp))
    this.initImages()
  }

  getTotalBase64SizeInMB(base64Array: string[]) {
    // 計算 base64 字符串的大小（以字節為單位）
    function base64ToSize(base64: string) {
      const padding = (base64.match(/=+$/) || []).length
      const base64Length = base64.length
      const sizeInBytes = (base64Length * 3) / 4 - padding
      return sizeInBytes
    }

    // 將字節大小轉換為 MB 大小
    function sizeInBytesToMB(sizeInBytes: number) {
      return sizeInBytes / (1024 * 1024) // 轉換為 MB
    }

    // 計算所有 base64 字符串的總大小
    const totalSizeInBytes = base64Array.reduce((total, base64) => {
      return total + base64ToSize(base64)
    }, 0)

    return +sizeInBytesToMB(totalSizeInBytes).toFixed(2) // 返回 MB 總大小，保留兩位小數
  }

  checkTemporaryStorage() {
    const isMissingImages = [...this.beforeImageBase64List, ...this.duringImageBase64List, ...this.afterImageBase64List].length === 0

    if (isMissingImages) {
      this.toggleSwalDialog('請確認上傳施工前、中、後照片至少各 1 張')
    }
  }

  onBeforeImagesChange(files: string[]) {
    if (this.ADMIN_ROLE) {
      this.beforeImageBase64ListSize = this.getTotalBase64SizeInMB(files)
      this.beforeImageBase64List = files

      this.checkTemporaryStorage()
    }
  }

  onAfterImagesChange(files: string[]) {
    if (this.ADMIN_ROLE) {
      this.afterImageBase64ListSize = this.getTotalBase64SizeInMB(files)
      this.afterImageBase64List = files

      this.checkTemporaryStorage()
    }
  }

  onDuringImagesChange(files: string[]) {
    if (this.ADMIN_ROLE) {
      this.duringImageBase64ListSize = this.getTotalBase64SizeInMB(files)
      this.duringImageBase64List = files

      this.checkTemporaryStorage()
    }
  }

  uploadRemarkImage(files: string[]) {
    this.installDetailTemp.imageBase64List = files
  }

  inputRemark(remark: string) {
    this.installDetailTemp.remark = remark
  }

  selectBtnType(installDeviceStatus: string) {
    this.installDetailTemp.installDeviceStatus = installDeviceStatus
  }

  async updateStatus() {
    if (this.checkIntersectionId()) return

    try {
      this.isPageLoading = true
      const data = await this.$api.device.getInstallCheck({ intersectionId: this.intersectionId })
      this.checkDeviceInfo = data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  toggleSwalDialog(title: string) {
    this.$swal({ title, icon: 'warning', showConfirmButton: true, showCancelButton: false })
  }

  checkIntersectionId() {
    if (!this.intersectionId) {
      this.toggleSwalDialog('請選擇路口名稱')
      return true
    }
    return false
  }

  checkUploadImages() {
    const { intersectionType } = this.intersectionTemp

    const isMissingImages =
      this.beforeImageBase64List.length === 0 || this.duringImageBase64List.length === 0 || this.afterImageBase64List.length === 0

    if (intersectionType === 'FIXED' && isMissingImages) {
      this.toggleSwalDialog('請確認上傳施工前、中、後照片至少各 1 張')
      return true
    }

    if (intersectionType === 'MOBILE' && this.afterImageBase64List.length === 0) {
      this.toggleSwalDialog('請確認上傳供電車整備後照片至少 1 張')
      return true
    }

    return false
  }

  checkRemarkInfo() {
    if (this.installDetailTemp.installDeviceStatus === '') {
      this.toggleSwalDialog('請選擇備註狀態')
      return true
    }
    return false
  }

  checkDeviceInfoStatus() {
    const checkList: any = {
      batteryALabel: this.checkDeviceInfo.checkBatteryInfoList,
      batteryBLabel: this.checkDeviceInfo.checkBatteryInfoList,
      inputStatus: this.checkDeviceInfo.inputStatus,
      upsLabel: this.checkDeviceInfo.upsLabel,
      inputVoltage: this.checkDeviceInfo.inputVoltage,
      upsTemperature: this.checkDeviceInfo.upsTemperature,
    }

    for (const key in checkList) {
      if (checkList[key] === null) {
        this.toggleSwalDialog('請確認設備主要資訊已連線取得')
        return true
      }
    }

    return false
  }

  hasInstallDetails() {
    return this.installDetailTemp.installDeviceStatus || this.installDetailTemp.remark || this.installDetailTemp.imageBase64List.length > 0
  }

  async handleInstallSubmit() {
    if (!this.hasInstallDetails()) return

    if (!this.checkRemarkInfo()) {
      await this.handleSubmitInstallDetail()
    }
  }

  async submitFile() {
    try {
      this.isPageLoading = true

      await this.$api.device.updateImages({
        intersectionId: this.intersectionId,
        afterImageBase64List: this.afterImageBase64List,
        beforeImageBase64List: this.beforeImageBase64List,
        duringImageBase64List: this.duringImageBase64List,
      })

      await this.$swal.fire({
        title: '已暫存照片',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  // 確認是否已完成
  async handleSubmit() {
    if (this.checkIntersectionId()) return

    if (this.intersectionTemp.installDeviceStatus === 'INSTALL') {
      await this.handleInstallSubmit()
      return
    }

    if (this.intersectionTemp.installDeviceStatus === 'NOT_INSTALL') {
      this.handleValidateNotInstallSubmit()
    }
  }

  async handleValidateNotInstallSubmit() {
    const checkDeviceStatus = !this.checkDeviceInfoStatus() && !this.checkUploadImages() // 確認有設備狀態、確認有上傳照片

    //有備註
    if (checkDeviceStatus && this.hasInstallDetails()) {
      await this.submitFile()
      await this.handleSubmitInstall()
      await this.handleSubmitInstallDetail()

      return
    }

    // 沒填備註
    if (checkDeviceStatus && !this.hasInstallDetails()) {
      await this.submitFile()
      await this.handleSubmitInstall()

      return
    }

    // 只填備註，沒上檢查狀態、前中後照照片
    if (!checkDeviceStatus && this.hasInstallDetails()) {
      if (!this.checkRemarkInfo()) {
        await this.handleSubmitInstallDetail()
      }
    }
  }

  // 送出第一次上傳的圖片
  async handleSubmitInstall() {
    const imagesListSize = this.beforeImageBase64ListSize + this.afterImageBase64ListSize + this.duringImageBase64ListSize
    if (imagesListSize > 30 * 1024 * 1024) {
      console.log('imagesListSize 過大', imagesListSize)
      return
    }

    try {
      this.isPageLoading = true
      await this.$api.device.install({ intersectionId: this.intersectionId, checkDeviceInfo: this.checkDeviceInfo })
      await this.getIntersectionCurrentList()
      this.initImages()

      this.intersectionTemp = this.mappingIntersectionList.filter((i) => i.intersectionId === this.intersectionId)[0]
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  // 送出備註
  async handleSubmitInstallDetail() {
    if (this.installDetailTemp.remark.length > 500) return

    const query = {
      intersectionId: this.intersectionId,
      imageBase64List: this.installDetailTemp.imageBase64List,
      remark: this.installDetailTemp.remark,
      installDeviceStatus: this.installDetailTemp.installDeviceStatus,
    }

    try {
      this.isPageLoading = true
      await this.$api.device.addInstallDetail(query)
      await this.getInstallDetailLog()

      this.installDetailTemp = JSON.parse(JSON.stringify(installDetailTemp))
      ;(this.$refs.Textarea as Textarea).clearImageBase64List()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleCancel() {
    this.installDetailTemp = JSON.parse(JSON.stringify(installDetailTemp))
    ;(this.$refs.Textarea as Textarea).clearImageBase64List()

    if (this.installStatus) return
    this.installTemp = JSON.parse(JSON.stringify(installTemp))
    this.intersectionTemp = JSON.parse(JSON.stringify(intersectionTemp))
    this.initImages()
  }

  async getInstalledLogs() {
    try {
      this.isPageLoading = true
      const data = await this.$api.device.getInstalledLogs({ intersectionId: this.intersectionId })
      this.installTemp = data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getInstallImages() {
    try {
      this.isPageLoading = true
      const data = await this.$api.device.getInstallImages({ intersectionId: this.intersectionId })

      if (data) {
        this.beforeImageBase64List = data.beforeImageBase64List ? data.beforeImageBase64List : []
        this.duringImageBase64List = data.duringImageBase64List ? data.duringImageBase64List : []
        this.afterImageBase64List = data.afterImageBase64List ? data.afterImageBase64List : []
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getInstallDetailLog() {
    try {
      this.isPageLoading = true
      const data = await this.$api.device.getInstallDetailLog({ intersectionId: this.intersectionId })
      this.installDetailLogList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getIntersectionCurrentList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.intersection.getIntersectionCurrentList({ projectId: JSON.parse(localStorage.getItem('projectId')!) })
      this.intersectionList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getUserList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getUserList({ projectId: JSON.parse(localStorage.getItem('projectId')!) })
      this.userList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getDeviceStatusList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.setting.getDeviceStatusList()
      this.btnStatusList = data as []

      this.btnStatusList.forEach((i, index) => {
        switch (i.statusDesc) {
          case '施工':
            this.btnStatusList[index].btnStyle = 'construction'
            this.btnStatusList[index].btnSelectedStyle = 'constructionActive'
            break
          case '換電池':
            this.btnStatusList[index].btnStyle = 'battery'
            this.btnStatusList[index].btnSelectedStyle = 'batteryActive'
            break
          case '檢查':
            this.btnStatusList[index].btnStyle = 'check'
            this.btnStatusList[index].btnSelectedStyle = 'checkActive'
            break
          case '維修':
            this.btnStatusList[index].btnStyle = 'repair'
            this.btnStatusList[index].btnSelectedStyle = 'repairActive'
            break

          default:
            break
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getIntersectionCurrentList()
    await this.getUserList()
    await this.getDeviceStatusList()
  }
}
