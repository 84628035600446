
































































import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import { Component, Vue } from 'vue-property-decorator'
import Label from '@/components/Label.vue'
import LabelTextField from '@/components/LabelTextField.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import DataTable from '@/components/DataTable.vue'
import { useIntersectionStore } from '@/store/intersection'
import { INTERSECTION_LABEL, IntersectionConfigMap, IntersectionStatusLabelMap } from '@/constants/intersection'
import { InputStatusCodeLabelMap, InputStatusConfigMap } from '@/constants/input'
import { TIME_PLACEHOLDER } from '@/constants/constants'

@Component({
  components: {
    StatusText,
    Label,
    LabelTextField,
    LabelSelect,
    DataTable,
  },
})
export default class IntersectionMgmt extends Vue {
  intersectionStore = useIntersectionStore()

  detailVisibility = false
  historyVisibility = false

  /** search */
  intersectionGroup = ''
  intersectionSearchingKeyword = ''
  intersectionStatusSelected: INTERSECTION_LABEL[] = Object.values(INTERSECTION_LABEL)
  intersectionStatusItems = Object.values(INTERSECTION_LABEL).map((intersectionStatusLabel) => {
    const { icon, color } = IntersectionConfigMap.get(intersectionStatusLabel)!
    return {
      label: intersectionStatusLabel,
      value: intersectionStatusLabel,
      icon,
      color,
    }
  })

  intersectionItemsViewHeader = [
    { text: '編號', value: 'intersectionNumber', sortable: false, align: 'center' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'center' },
    { text: '市電狀態', value: 'inputStatus', sortable: false, align: 'center' },
    { text: '路口狀態', value: 'intersectionStatus', sortable: false, align: 'center', width: '100px' },
    { text: '路口電量', value: 'batteryLevel', sortable: false, align: 'center' },
    { text: '電池啟用時間', value: 'enabledTime', sortable: false, align: 'center' },
    { text: '已運作的時間', value: 'upsEnableTimeMinutes', sortable: false, align: 'center' },
    { text: '電池預估使用時間', value: 'batteryRemainingTime', sortable: false, align: 'center' },
    { text: 'UPS功率(W)', value: 'avgUpsOutput', sortable: false, align: 'center' },
    { text: 'UPS溫度(℃)', value: 'upsTemperature', sortable: false, align: 'center' },
    { text: '電池A溫度(℃)', value: 'batteryATemperature', sortable: false, align: 'center' },
    { text: '電池B溫度(℃)', value: 'batteryBTemperature', sortable: false, align: 'center' },
    { text: '市電電壓(V)', value: 'inputVoltage', sortable: false, align: 'center' },
    { text: '', value: 'actions', sortable: false, align: 'end' },
  ]

  get intersectionStatusSelectedAll() {
    return this.intersectionStatusSelected.length === Object.values(INTERSECTION_LABEL).length
  }

  set intersectionStatusSelectedAll(selectAll) {
    this.intersectionStatusSelected = selectAll ? Object.values(INTERSECTION_LABEL) : []
  }

  get intersectionStatusSelectedIndeterminate() {
    return this.intersectionStatusSelected.length > 0 && !this.intersectionStatusSelectedAll
  }

  get IntersectionLabelList() {
    return this.intersectionStore.intersectionInstalled.map((i) => ({
      ...i,
      intersectionIndex: `${i.intersectionNumber} ${i.intersectionLabel}`,
    }))
  }

  get intersectionItemsView() {
    let intersectionItemsFiltered = this.intersectionStore.intersectionInstalled

    // filter by group
    if (this.intersectionGroup) {
      intersectionItemsFiltered = intersectionItemsFiltered.filter((i) => i.groupId === this.intersectionGroup)
    }

    // filter by status
    if (this.intersectionStatusSelected.length > 0) {
      intersectionItemsFiltered = intersectionItemsFiltered.filter((i) => {
        return this.intersectionStatusSelected.includes(IntersectionStatusLabelMap.get(i.intersectionStatus)!)
      })
    }

    return intersectionItemsFiltered.map((intersection) => {
      const intersectionStatusLabel = IntersectionStatusLabelMap.get(intersection.intersectionStatus)!
      const { color: IntersectionStatusColor, icon: IntersectionStatusIcon } = IntersectionConfigMap.get(intersectionStatusLabel)!

      const inputStatusLabel = InputStatusCodeLabelMap.get(+intersection.inputStatus)!
      const actions = [
        {
          name: '詳細資訊',
          on: {
            click: () => this.$router.push('intersection-mgmt/dashboard?id=' + intersection.intersectionId),
          },
          text: true,
          rounded: true,
          elevation: 0,
          color: 'primary',
          // disabled: !this.isGroupAdmin
        },
      ]
      if (JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN') {
        actions.push({
          name: '歷史資料',
          on: {
            click: () => this.$router.push('record-query?id=' + intersection.intersectionId),
          },
          text: true,
          rounded: true,
          elevation: 0,
          color: 'primary',
          // disabled: !this.isGroupAdmin
        })
      }
      return {
        ...intersection,
        intersectionLabel: intersection.intersectionLabel,
        batteryLevel: typeof intersection.avgBatteryLevel === 'number' ? `${intersection.avgBatteryLevel.toFixed(0)}%` : '-',
        avgUpsOutput: intersection.avgUpsOutput ? +intersection.avgUpsOutput.toFixed(0) : '-',
        upsTemperature: intersection.upsTemperature ? (+intersection.upsTemperature).toFixed(0) : '-',
        batteryATemperature: intersection.batteryATemperature ? (+intersection.batteryATemperature).toFixed(0) : '-',
        batteryBTemperature: intersection.batteryBTemperature ? (+intersection.batteryBTemperature).toFixed(0) : '-',
        inputVoltage: intersection.inputVoltage ? (+intersection.inputVoltage).toFixed(0) : '-',
        inputStatus: {
          ...InputStatusConfigMap.get(inputStatusLabel),
          text: inputStatusLabel,
        },
        enabledTime: intersection.upsEnableTime
          ? this.$dayjs(intersection.upsEnableTime).format('YYYY/MM/DD HH:mm:ss')
          : `${TIME_PLACEHOLDER}`,
        intersectionStatus: {
          color: IntersectionStatusColor,
          icon: IntersectionStatusIcon,
          text: intersectionStatusLabel,
        },
        upsEnableTimeMinutes:
          typeof intersection.upsEnableTimeMinutes === 'number'
            ? this.formatMinutes(intersection.upsEnableTimeMinutes)
            : `${TIME_PLACEHOLDER}`,

        batteryRemainingTime:
          typeof intersection.batteryRemainingTime === 'number'
            ? this.formatMinutes(intersection.batteryRemainingTime)
            : `${TIME_PLACEHOLDER}`,
        actions,
      }
    })
  }

  formatMinutes(minutes: number) {
    const duration = this.$dayjs.duration(minutes, 'minutes')
    const days = duration.days()
    const hours = duration.hours()
    const mins = duration.minutes()

    let result = ''

    if (days > 0) {
      result += `${days}D : `
    }

    if (days > 0 || hours > 0) {
      result += `${hours}H : `
    }

    result += `${mins}m`

    return result.trim()
  }

  intersectionGroupLabelList: Array<{ label: string; value: string }> = []

  mounted() {
    try {
      const intersectionGroup = JSON.parse(localStorage.getItem('intersectionGroup')!)
      this.intersectionGroupLabelList = [
        { text: '全部', value: '' },
        ...Object.entries(intersectionGroup).map(([value, text]) => ({ text, value } as any)),
      ]
      this.intersectionGroup = ''
    } catch (e) {
      console.error(e)
    }
  }
}
