import Vue, { VNode } from 'vue'
import vuetify from '@/plugins/vuetify'
import { VCard, VNavigationDrawer } from 'vuetify/lib'

const drawer = () => {
  let vm: null | Vue = null
  const getRootElm = () => document.getElementById('app')

  const close = () => {
    const elm = getRootElm()
    if (vm && elm) {
      elm.removeChild(vm.$el)
      vm.$destroy()
      vm = null
    }
  }

  const open = (content: VNode) => {
    if (vm) {
      return
    }

    vm = new Vue({
      name: 'Drawer',
      vuetify,
      data: () => ({
        visible: false,
      }),
      mounted() {
        setTimeout(() => {
          this.visible = true
        }, 100)
      },
      render: function (h) {
        return (
          <VNavigationDrawer
            style='height:100vh;overflow-y:auto;position:fixed;'
            value={this.visible}
            on={{
              input: (v: boolean) => {
                !v && setTimeout(() => close(), 100)
              },
            }}
            temporary
            absolute
            right
          >
            {content}
          </VNavigationDrawer>
        )
      },
    }).$mount()

    const elm = getRootElm()
    elm && elm.append(vm.$el)
  }
  return {
    open,
    close,
  }
}

export default (() => drawer())()
