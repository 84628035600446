import { axiosIns } from '../axiosIns'
import {
  IIntersectionPostCreateRequest,
  IIntersectionPutUpdateRequest,
  IGetIntersectionListRequest,
  IGetIntersectionListResponse,
  IDeleteIntersectionRequest,
  IIntersectionMgmt,
  IGetIntersectionBatteryLevelResponse,
  IGetIntersectionItem,
  IGetIntersectionStatusResponse,
} from '@/types/api/intersection'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  // 更新路口
  update: (payload: IIntersectionPutUpdateRequest) => {
    return handleResponse<IGetIntersectionItem[]>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection`, payload))
  },

  // 新增路口
  create: (payload: IIntersectionPostCreateRequest) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection`, payload))
  },

  getIntersectionCurrentList: (params: { projectId: string }) => {
    return handleResponse<IIntersectionMgmt[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/device/current`, { params })
    )
  },

  getIntersectionHistory: (params: { intersectionId: string }) => {
    return handleResponse<IIntersectionMgmt[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/device/history`, { params })
    )
  },

  getIntersectionList: (params: IGetIntersectionListRequest) => {
    return handleResponse<IGetIntersectionListResponse>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/list`, { params })
    )
  },

  deleteIntersection: (params: IDeleteIntersectionRequest) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection`, { params }))
  },

  getBatteryLevel: (params: { projectId: string }) => {
    return handleResponse<IGetIntersectionBatteryLevelResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/battery/level`, { params })
    )
  },
  disableIntersection: ({ intersectionId }: { intersectionId: string }) => {
    return handleResponse<void>(
      axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/disable?intersectionId=${intersectionId}`)
    )
  },
  enableIntersection: ({ intersectionId }: { intersectionId: string }) => {
    return handleResponse<void>(
      axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/enable?intersectionId=${intersectionId}`)
    )
  },

  getIntersectionStatus: (params: { projectId: string; startTime: string | number; endTime: string | number }) => {
    return handleResponse<IGetIntersectionStatusResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/intersection/stats`, { params })
    )
  },
}
