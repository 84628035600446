










import { Component, Vue, Prop } from 'vue-property-decorator'
import vueJsonEditor from 'vue-json-editor'

@Component({
  components: {
    vueJsonEditor,
  },
})
export default class editorJson extends Vue {
  @Prop({ type: String }) objectString!: string
  @Prop({ type: String }) title!: string

  errorStatus = false

  json = JSON.parse(this.objectString)

  checkKeyLowerCase(key: string): boolean {
    return /^[a-z0-9]+$/.test(key)
  }

  checkAllKeys(object: { [key: string]: string | number }): boolean {
    return Object.keys(object).every(this.checkKeyLowerCase)
  }

  checkJsonValue(object: { [key: string]: string | number }): boolean {
    return Object.keys(object).length === 0
  }

  onJsonChange(value: { [key: string]: string | number }) {
    this.errorStatus = false
    this.$emit('inputJson', value, this.errorStatus, !this.checkAllKeys(value), this.checkJsonValue(value))
  }

  onError() {
    this.errorStatus = true
    this.$emit('onError', this.errorStatus)
  }

  hiddenMenu() {
    document.querySelector('.jsoneditor-menu')
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = '.jsoneditor-menu { display: none !important; }'
    document.head.appendChild(style)
  }

  mounted() {
    this.hiddenMenu()
  }
}
