










































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import { IDeviceStatusItem } from '@/types/api/setting'
import { IDeviceInstallFile } from '@/types/device'
import { IDeviceInstallDetailResponse, IDeviceAddInstallDetailRequest } from '@/types/api/device'
import ImageDialog from '@/components/ImageDialog.vue'
import UploadImageFile from './UploadImageFile.vue'
import { WritableState } from '@/util/annotations'

@Component({ components: { ImageDialog, UploadImageFile } })
export default class Textarea extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: String }) intersectionId!: string
  @Prop({ type: Array }) btnStatusList!: IDeviceStatusItem
  @Prop({ type: Object }) installDetailTemp!: IDeviceAddInstallDetailRequest
  @Prop({ type: Array }) mappingInstallDetailLog!: IDeviceInstallDetailResponse

  dialogStatus = false
  installDeviceStatus = ''
  remark = ''

  imageBase64List: string[] = []
  textareaImages: string[] = []

  get userLabel() {
    return JSON.parse(localStorage.getItem('userLabel')!)
  }

  get BMS_SYSTEM_ADMIN() {
    return JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN'
  }

  get checkRemarkLength() {
    return this.remark?.length > 500
  }

  @Watch('installDetailTemp', { immediate: true, deep: true })
  onPersonChanged() {
    this.installDeviceStatus = this.installDetailTemp.installDeviceStatus
    this.remark = this.installDetailTemp.remark
    this.imageBase64List = this.installDetailTemp.imageBase64List
  }

  openDialog(item: string[]) {
    this.dialogStatus = true
    this.textareaImages = item
  }

  clearImageBase64List() {
    ;(this.$refs.imageBase64List as UploadImageFile).files = []
  }

  @Emit('selectBtnType')
  selectBtnType(i: IDeviceStatusItem) {
    this.installDeviceStatus = i.status
    return this.installDeviceStatus
  }

  @Emit('uploadRemarkImage')
  uploadImage(files: IDeviceInstallFile[]) {
    this.imageBase64List = files.map((f) => f.base64)
    return this.imageBase64List
  }

  @Emit('inputRemark')
  inputRemark() {
    return this.remark
  }

  async handleDeleteLogs(item: IDeviceInstallDetailResponse) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除這則留言?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true
        await this.$api.device.deleteInstallDetailLog({
          intersectionId: item.intersectionId,
          installDeviceDetailNumber: `${item.installDeviceDetailNumber}`,
        })
        await this.refresh()
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  @Emit('refresh')
  refresh() {}
}
