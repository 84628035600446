

















































import { Component, Vue } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import { WritableState } from '@/util/annotations'
import { IGetIntersectionStatusResponse } from '@/types/api/intersection'
import DateTimePicker from '@/components/DateTimePicker.vue'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class EventErrorLog extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  dateRangeValue: number[] = []
  startTime = 0
  endTime = 0
  intersectionStatusList: IGetIntersectionStatusResponse[] = []

  tableHeader = [
    { text: '編號', value: 'intersectionNumber', sortable: true, align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: true, align: 'left' },
    { text: 'UPS溫度(Max)', value: 'upsTemperatureMax', sortable: true, align: 'left' },
    { text: 'UPS溫度(min)', value: 'upsTemperatureMin', sortable: true, align: 'left' },
    { text: '電池A溫度(Max)', value: 'batteryATemperatureMax', sortable: true, align: 'left' },
    { text: '電池A溫度(min)', value: 'batteryATemperatureMin', sortable: true, align: 'left' },
    { text: '電池B溫度(Max)', value: 'batteryBTemperatureMax', sortable: true, align: 'left' },
    { text: '電池B溫度(min)', value: 'batteryBTemperatureMin', sortable: true, align: 'left' },
    { text: 'UPS功率(Max)', value: 'upsOutputMax', sortable: true, align: 'left' },
    { text: 'UPS功率(min)', value: 'upsOutputMin', sortable: true, align: 'left' },
    { text: '路口電量(Max)', value: 'avgBatteryLevelMax', sortable: true, align: 'left' },
    { text: '路口電量(min)', value: 'avgBatteryLevelMin', sortable: true, align: 'left' },
  ]

  get CSVTable() {
    return this.intersectionStatusList.map((i) => ({
      編號: i.intersectionNumber,
      路口名稱: i.intersectionLabel,
      ['UPS溫度(Max)']: i.upsTemperatureMax,
      ['UPS溫度(min)']: i.upsTemperatureMin,
      ['電池A溫度(Max)']: i.batteryATemperatureMax,
      ['電池A溫度(min)']: i.batteryATemperatureMin,
      ['電池B溫度(Max)']: i.batteryBTemperatureMax,
      ['電池B溫度(min)']: i.batteryBTemperatureMin,
      ['UPS功率(Max)']: i.upsOutputMax,
      ['UPS功率(min)']: i.upsOutputMin,
      ['路口電量(Max)']: i.avgBatteryLevelMax,
      ['路口電量(min)']: i.avgBatteryLevelMin,
    }))
  }

  get tableItems() {
    const formatValue = (value: number) => (value !== null || value !== undefined ? Math.round(value) : '-')

    return this.intersectionStatusList.map((item) => ({
      ...item,
      upsTemperatureMax: formatValue(item.upsTemperatureMax),
      upsTemperatureMin: formatValue(item.upsTemperatureMin),
      batteryATemperatureMax: formatValue(item.batteryATemperatureMax),
      batteryATemperatureMin: formatValue(item.batteryATemperatureMin),
      batteryBTemperatureMax: formatValue(item.batteryBTemperatureMax),
      batteryBTemperatureMin: formatValue(item.batteryBTemperatureMin),
      upsOutputMax: formatValue(item.upsOutputMax),
      upsOutputMin: formatValue(item.upsOutputMin),
      avgBatteryLevelMax: formatValue(item.avgBatteryLevelMax),
      avgBatteryLevelMin: formatValue(item.avgBatteryLevelMin),
    }))
  }

  disableDate(date: Date) {
    return date > new Date()
  }

  async handleMaxTimeRange() {
    const firstTime = new Date(new Date(this.dateRangeValue[0]))
    const secondTime = new Date(new Date(this.dateRangeValue[1]))
    const firstTimeValue = this.$dayjs(firstTime).startOf('day').valueOf()
    const secondTimeEndValue = this.$dayjs(secondTime).endOf('day').valueOf()

    this.startTime = +firstTimeValue
    this.endTime = +secondTimeEndValue
  }

  async getIntersectionStatusList(page = 0) {
    try {
      this.isPageLoading = true

      const data = await this.$api.intersection.getIntersectionStatus({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
      })

      this.intersectionStatusList = data!
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.startTime = +this.$dayjs().subtract(30, 'day').valueOf()
    this.endTime = +this.$dayjs().valueOf()
    this.dateRangeValue = [this.startTime, this.endTime]

    await this.getIntersectionStatusList()
  }
}
