// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify'
import * as directives from 'vuetify/lib/directives'

Vue.use(Vuetify, { directives: { ripple: directives.Ripple } })

const theme = {
  anchor: '',

  primary: '#556fff',
  secondary: '#3b4769',
  accent: '#9cb2ed',

  error: '#eb1414',
  info: '#bbc2d6',
  success: '#00ce8e',
  warning: '#ff9800',

  'text-primary': '#00103c',
  'text-secondary': '#f5f8ff',
  'text-accent': '#e7edfd',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
    // Material Design Icons
    // iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
