



























































import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import { IGetProjectItem, IGetProjectUserItem, IEditProjectUser } from '@/types/api/project'
import EditUser from './edit.vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { IRoleGroup } from '@/types/api/setting'
import { WritableState } from '@/util/annotations'

const downloadCsv = require('vue-json-csv')

@Component({
  components: {
    DataTable,
    EditUser,
    downloadCsv,
  },
})
export default class tableList extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  userStore = useUserStore()

  showTableStatus = true
  search = ''
  hasOtpStatus = false

  projectList: IGetProjectItem[] = []
  projectUserList: IGetProjectUserItem[] = []
  editTemp: IEditProjectUser = {
    defaultProjectId: '',
    projectIdList: [],
    userId: '',
    userLabel: '',
    userRoleGroupName: '',
  }

  viewHeader = [
    { text: '使用者代號', value: 'userLabel', sortable: false, align: 'left' },
    { text: '角色', value: 'roleGroupDesc', sortable: false, align: 'left' },
    { text: '電子信箱', value: 'email', sortable: false, align: 'left' },
    { text: '所屬專案', value: 'projectLabel', sortable: false, align: 'left' },
    { text: '新增時間', value: 'createTime', align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  get tableList() {
    const roleGroup: IRoleGroup[] = JSON.parse(localStorage.getItem('roleGroup')!)

    return this.projectUserList.map((i) => ({
      ...i,
      projectLabel: this.projectList.filter((o) => i.projectIdList?.includes(o.projectId)).map((p) => p.projectLabel),
      roleGroupDesc: roleGroup.filter((x) => x.roleGroupName === i.userRoleGroupName)[0].roleGroupDesc,
    }))
  }

  get CSVTable() {
    return this.tableList.map((i) => ({
      使用者代號: i.userLabel,
      角色: i.userRoleGroupName,
      電子信箱: i.email,
      所屬專案: i.projectLabel,
      新增時間: i.createTime ? this.$dayjs(i.createTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
    }))
  }

  async checkUserTotp(email: string) {
    try {
      this.isPageLoading = true

      const data = await this.$api.otp.checkUserOtp({ email })

      this.hasOtpStatus = data as boolean
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleEditUser(item: IGetProjectUserItem) {
    await this.checkUserTotp(item.email)

    this.showTableStatus = false
    this.editTemp = JSON.parse(JSON.stringify(item))
  }

  async refreshList() {
    this.showTableStatus = true

    await this.getProjectUserList()
  }

  async handleDeleteUser(item: IGetProjectUserItem) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除 ${item.userLabel}?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true

        await this.$api.project.deleteProjectUser({ userId: `${item.userId}` })
        await this.$swal.fire({
          title: '刪除成功',
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })
        await this.getProjectUserList()
      } catch (error) {
        console.log(error)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  async getProjectUserList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.project.gteProjectUserList()

      this.projectUserList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectList() {
    try {
      this.isPageLoading = true

      const { data } = await this.$api.project.getProjectList()

      this.projectList = data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getProjectUserList()
    await this.getProjectList()
  }
}
