
import { createChart } from '@/util/echartUtil'
import { getComputedColor } from '@/util/helpers'
import { EChartsOption } from 'echarts'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DashboardHalfPieChart extends Vue {
  @Prop({ type: Number, default: 50 }) value!: number
  @Prop({ type: Number, default: 100 }) maxValue!: number
  @Prop({ type: Number, default: 0 }) minValue!: number

  @Prop({ type: Number, default: 200 }) height!: number
  @Prop({ type: String, default: '--v-primary-base' }) activeColor!: string
  @Prop({ type: String, default: '--v-secondary-base' }) baseColor!: string
  @Prop({ type: String, default: 'title' }) title!: string

  @Watch('value', { immediate: true })
  onValueChange() {
    ;(this.opts.series as any)[0].data = [this.value || 0]
  }

  opts: EChartsOption = {
    emphasis: {
      disabled: true,
    },
    series: [
      {
        type: 'gauge',
        radius: '100%',
        center: ['50%', '80%'],
        progress: {
          show: true,
          width: 25,
          itemStyle: {
            color: getComputedColor(this.activeColor),
          },
        },
        axisLine: {
          lineStyle: {
            color: [[1, getComputedColor(this.baseColor)]],
            width: 25,
          },
        },
        splitNumber: 1,
        splitLine: {
          show: false,
        },
        axisLabel: {
          distance: -10,
          formatter: (v) => `{styleName|${v}}`,
          rich: {
            styleName: {
              color: 'white',
              fontSize: '1.2rem',
              align: 'center',
              width: 10,
              padding: [40, 0, 0, 0],
            },
          },
        },
        axisTick: {
          show: false,
        },
        pointer: {
          show: false,
        },
        detail: {
          show: false,
        },
        startAngle: 180,
        endAngle: 0,
        data: [this.value],
        min: this.minValue,
        max: this.maxValue,
      },
    ],
  }

  render() {
    return (
      <div style='width:100%;'>
        <h3 class='mt-3' style='color: var(--v-secondary-lighten2);'>
          {this.title}
        </h3>
        <div class='pie-half'>
          <strong class='pie-half__value'>{this.value}</strong>
          <div ref='chart' style={{ height: `${this.height}px` }} />
        </div>
      </div>
    )
  }

  mounted() {
    this.$nextTick(async () => {
      const chartRef = this.$refs.chart
      await createChart.call(this, chartRef, this.opts)
    })
  }
}
