import { axiosIns } from '../axiosIns'
import {
  ITelemetryCurGetRequest,
  ITelemetryGetRequest,
  IEventPutSettingRequest,
  IEventSettingResponse,
  IEventWorkLogRequest,
  IEventLogRequest,
  IEventGetWorkLogResponse,
  IEventInputErrorLogRequest,
  IEventInputErrorLogResponse,
  ITelemetryCurGetBatteryResponse,
  ITelemetryCurGetUpsResponse,
  ITelemetryCurGetGatewayResponse,
  ITelemetryGetResponse,
  IEventLogResponse,
  IEventErrorLogGetResponse,
  IEventErrorStatisticsRequest,
  IEventErrorStatisticsResponse,
  ITelemetryGetStatusRequest,
  ITelemetryGetStatusResponse,
  IEventErrorLogDetailRequest,
  IEventErrorLogDetailResponse,
  IEventErrorLogOverOneHourRequest,
  IEventErrorLogOverOneHourResponse,
} from '@/types/api/event'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  // 取得 Telemetry 時序資料
  getTelemetry: (params: ITelemetryGetRequest) => {
    return handleResponse<ITelemetryGetResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/telemetry/time-series`, { params }))
  },

  getTelemetryCur: (params: ITelemetryCurGetRequest) => {
    return handleResponse<Array<ITelemetryCurGetBatteryResponse | ITelemetryCurGetUpsResponse | ITelemetryCurGetGatewayResponse>>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/telemetry/time-series/current`, { params })
    )
  },

  getTelemetryStatus: (params: ITelemetryGetStatusRequest) => {
    return handleResponse<ITelemetryGetStatusResponse>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/telemetry/time-series/status`, { params })
    )
  },

  getEventSetting: (params: { projectId: string }) => {
    return handleResponse<IEventSettingResponse[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/setting`, { params }))
  },

  updateEventSetting: (payload: IEventPutSettingRequest) => {
    return handleResponse<IEventSettingResponse[]>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/event/setting`, payload))
  },

  getUpsWorkLog: (params: IEventWorkLogRequest) => {
    return handleResponse<IEventGetWorkLogResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/ups/work/log`, { params })
    )
  },

  getEventLog: (params: IEventLogRequest) => {
    return handleResponse<IEventLogResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/log`, { params }))
  },

  getEventErrorLog: (params: IEventLogRequest) => {
    return handleResponse<IEventErrorLogGetResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/error/log`, { params }))
  },

  getEventErrorLogDetail: (params: IEventErrorLogDetailRequest) => {
    return handleResponse<IEventErrorLogDetailResponse>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/input/error/log/detail`, { params })
    )
  },

  getEventErrorLogOverOneHour: (params: IEventErrorLogOverOneHourRequest) => {
    return handleResponse<IEventErrorLogOverOneHourResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/input/error/log/over-one-hour`, { params })
    )
  },

  getInputErrorLog: (params: IEventInputErrorLogRequest) => {
    return handleResponse<IEventInputErrorLogResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/input/error/log`, { params })
    )
  },

  getEventErrorStatistics: (params: IEventErrorStatisticsRequest) => {
    return handleResponse<IEventErrorStatisticsResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/event/error/statistics`, { params })
    )
  },
}
