




















import { IGetIntersectionItem } from '@/types/api/intersection'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { INTERSECTION_STATUS } from '@/constants/intersection'

@Component
export default class OverviewDevicesDrawer extends Vue {
  @Prop({ type: String }) title!: string // 已安裝總數 | 市電異常路口 | 連線異常路口
  @Prop({ type: Array }) intersectionList!: IGetIntersectionItem[]
  INTERSECTION_STATUS = INTERSECTION_STATUS
}
