










import { useAppStore } from '@/store/app'
import { computed } from '@vue/composition-api'
export default {
  name: 'DefaultFooter',
  setup() {
    const store = useAppStore()
    const footerVisibility = computed(() => store.footerVisibility)
    return { footerVisibility }
  },
}
