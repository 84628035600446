













































import { Component, Vue } from 'vue-property-decorator'
import ScaleChart from '@/views/IntersectionMgmtDashboard/components/ScaleChart.vue'

import IndexBattery from '@/views/IntersectionMgmtDashboard/indexBattery.vue'
import IndexInput from '@/views/IntersectionMgmtDashboard/indexInput.vue'
import IndexUps from '@/views/IntersectionMgmtDashboard/indexUps.vue'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import ShadowCard from '@/components/ShadowCard.vue'

import { useIntersectionStore } from '@/store/intersection'
import { useAppStore } from '@/store/app'

@Component({
  components: {
    ScaleChart,
    StatusText,
    IndexBattery,
    IndexInput,
    IndexUps,
    ShadowCard,
  },
})
export default class MapMgmtDrawer extends Vue {
  intersectionStore = useIntersectionStore()

  get currentStatus() {
    return useAppStore().currentStatus
  }

  get intersectionLabel() {
    return this.intersectionStore.intersectionItemActived?.intersectionLabel ?? '-'
  }

  // battery
  get isBatteryOffline() {
    return this.intersectionStore.isBatteryOffline
  }

  get batteryInfo() {
    return this.intersectionStore.batteryInfo
  }

  // input : from gateway
  get isInputOffline() {
    return this.intersectionStore.isInputOffline
  }

  get inputInfo() {
    return this.intersectionStore.inputInfo
  }

  // ups
  get isUpsOffline() {
    return this.intersectionStore.isUpsOffline
  }

  get upsInfo() {
    return this.intersectionStore.upsInfo
  }
}
