































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { IGetIntersectionBatteryLevelResponse } from '@/types/api/intersection'
import Modal from '@/components/Modal.vue'
import { getBatteryLevelColor } from '@/constants/battery'

@Component({
  components: {
    Modal,
  },
})
export default class OverviewPagedWorkingIntersection extends Vue {
  @Prop({ type: Array }) batteryLevelList!: Array<IGetIntersectionBatteryLevelResponse>

  getBatteryLevelColor = getBatteryLevelColor

  workingIntersectionsPaged: IGetIntersectionBatteryLevelResponse[] = []

  dialogStatus = false
  workingIntersectionsCurPage = 1
  workingIntersectionsPageSize = 5

  get workingIntersectionBatteryList() {
    return this.batteryLevelList
      .sort((a, b) => a.batteryLevel - b.batteryLevel)
      .map(({ intersectionId, intersectionLabel, batteryLevel, batteryRemainingTime, upsEnableTime, upsEnableTimeMinutes }) => ({
        intersectionLabel,
        batteryLevel,
        intersectionId,
        batteryRemainingTime,
        upsEnableTime,
        upsEnableTimeMinutes,
      }))
  }

  get workingIntersectionsTotalPages() {
    return Math.ceil(this.workingIntersectionBatteryList.length / this.workingIntersectionsPageSize)
  }

  @Watch('workingIntersectionsCurPage', { immediate: true })
  @Watch('workingIntersectionBatteryList', { immediate: true })
  pageWorkingIntersections() {
    const paged: IGetIntersectionBatteryLevelResponse[][] = []

    let cnt = 0
    while (cnt < this.workingIntersectionsTotalPages) {
      paged.push(
        this.workingIntersectionBatteryList.slice(this.workingIntersectionsPageSize * cnt, this.workingIntersectionsPageSize * (cnt + 1))
      )
      cnt += 1
    }

    this.workingIntersectionsPaged = paged[this.workingIntersectionsCurPage - 1]
  }

  formattedTime(minutes: number) {
    if (minutes < 0) {
      return '0'
    }

    const duration = this.$dayjs.duration(minutes, 'minutes')
    const days = duration.days()
    const hours = duration.hours()
    const mins = duration.minutes()

    let result = ''

    if (days > 0) {
      result += `${days}D : `
    }

    if (days > 0 || hours > 0) {
      result += `${hours}H : `
    }

    result += `${mins}m`

    return result.trim()
  }
}
