var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 pb-8"},[_c('ValidationObserver',{ref:"form"},[_c('v-form',[_c('v-container',[_c('v-row',{staticClass:"d-flex align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.temp.intersectionType === 'FIXED' ? '路口' : '供電車')+"名稱 : ")]),_c('v-col',{staticClass:"font-weight-black pl-2",attrs:{"cols":"9"}},[_c('ValidationProvider',{ref:"intersectionLabel",attrs:{"name":"路口名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"hide-details":"","placeholder":"(必填)"},model:{value:(_vm.temp.intersectionLabel),callback:function ($$v) {_vm.$set(_vm.temp, "intersectionLabel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.intersectionLabel"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.temp.intersectionType === 'FIXED')?_c('v-row',{staticClass:"d-flex align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_vm._v(" 群組 : ")]),_c('v-col',{staticClass:"font-weight-black pl-2",attrs:{"cols":"9"}},[_c('ValidationProvider',{ref:"intersectionGroupId",attrs:{"name":"群組","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"id":"combobox","items":_vm.intersectionGroups,"item-text":'label',"item-value":'value',"hide-details":"","placeholder":"(必填)"},model:{value:(_vm.temp.groupId),callback:function ($$v) {_vm.$set(_vm.temp, "groupId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.groupId"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4118445067)})],1)],1):_vm._e(),(_vm.temp.intersectionType === 'FIXED')?_c('v-row',{staticClass:"d-flex align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_vm._v(" 緯度 : ")]),_c('v-col',{staticClass:"font-weight-black pl-2",attrs:{"cols":"9"}},[_c('ValidationProvider',{ref:"latitude",attrs:{"name":"緯度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"hide-details":"","placeholder":"(必填)"},model:{value:(_vm.temp.latitude),callback:function ($$v) {_vm.$set(_vm.temp, "latitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.latitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2723668227)})],1)],1):_vm._e(),(_vm.temp.intersectionType === 'FIXED')?_c('v-row',{staticClass:"d-flex align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_vm._v(" 經度 : ")]),_c('v-col',{staticClass:"font-weight-black pl-2",attrs:{"cols":"9"}},[_c('ValidationProvider',{ref:"longitude",attrs:{"name":"經度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"hide-details":"","placeholder":"(必填)"},model:{value:(_vm.temp.longitude),callback:function ($$v) {_vm.$set(_vm.temp, "longitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"temp.longitude"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4195722192)})],1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 確認 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }