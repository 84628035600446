<template>
  <v-main style="background-color: var(--v-text-secondary-base)">
    <v-container fluid>
      <v-slide-x-transition mode="out-in" duration="500">
        <router-view :key="$route.path" />
      </v-slide-x-transition>

      <Dialog :title="'TOTP QR Code'" :dialogStatus="dialogStatus" :width="400" @closeDialog="closeDialog">
        <div class="px-2 pb-4">
          <img style="width: 100%; height: 100%" :src="qrCodeImgBase64Src" alt="totp" />

          <div class="px-6">
            <ValidationObserver ref="form">
              <ValidationProvider ref="code" v-slot="{ errors }" name="TOTP" rules="required|otp">
                <v-text-field
                  v-model.trim="code"
                  outlined
                  type="text"
                  :error-messages="errors"
                  placeholder="(必填) 請輸入 OTP"
                  maxlength="6"
                  @keyup.enter="submit"
                />
              </ValidationProvider>
            </ValidationObserver>
          </div>

          <div class="d-flex align-center justify-center pt-2">
            <v-btn class="mx-2" color="primary" @click="submit"> 確認 </v-btn>
            <v-btn class="mx-2" color="error" @click="closeDialog"> 取消 OTP </v-btn>
          </div>
        </div>
      </Dialog>
    </v-container>
  </v-main>
</template>

<script>
import Dialog from '@/components/ImageDialog.vue'
import { ref, watch } from '@vue/composition-api'
import { useUserStore } from '@/store/user'
import { useAppStore } from '@/store/app'

const appStore = useAppStore()
const userStore = useUserStore()

export default {
  name: 'DefaultView',

  components: {
    Dialog,
  },

  setup() {
    const qrCodeImgBase64Src = ref('')

    watch(
      () => userStore.qrCodeImgBase64Src,
      (newVal) => {
        if (newVal) {
          qrCodeImgBase64Src.value = newVal
        }
      },
      { immediate: true }
    )

    return { qrCodeImgBase64Src }
  },

  data() {
    return {
      code: '',
      dialogStatus: false,
    }
  },

  watch: {
    qrCodeImgBase64Src: {
      handler() {
        if (this.qrCodeImgBase64Src) {
          this.dialogStatus = true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async checkUserTotp() {
      await userStore.checkUserTotp({ email: JSON.parse(JSON.stringify(localStorage.getItem('email'))) })
    },

    async closeDialog() {
      try {
        appStore.isPageLoading = true
        userStore.qrCodeImgBase64Src = ''

        await this.$api.otp.deleteOtpSecret()
        await this.checkUserTotp()

        this.code = ''
        this.dialogStatus = false
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async submit() {
      const validateStatus = await this.$refs.form.validate()
      if (!validateStatus) return

      try {
        appStore.isPageLoading = true

        await this.$api.otp.verifyCode(this.code)
        await this.checkUserTotp()
        await this.$swal.fire({
          title: '已設定 TOTP',
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })

        userStore.qrCodeImgBase64Src = ''
        this.code = ''
        this.dialogStatus = false
      } catch (error) {
        console.error(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async beforeUnload(event) {
      if (this.dialogStatus) {
        event.preventDefault()
        event.returnValue = '您確定要離開此頁面嗎？'
      }
    },
  },

  mounted() {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },
}
</script>
