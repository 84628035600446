import { render, staticRenderFns } from "./indexBattery.vue?vue&type=template&id=61c1d1fe&scoped=true&"
import script from "./indexBattery.vue?vue&type=script&lang=tsx&"
export * from "./indexBattery.vue?vue&type=script&lang=tsx&"
import style0 from "./indexBattery.vue?vue&type=style&index=0&id=61c1d1fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c1d1fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
