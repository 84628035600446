













import { Component, Vue } from 'vue-property-decorator'
import ScaleChart from '@/views/IntersectionMgmtDashboard/components/ScaleChart.vue'
import PieHalfChart from '@/views/IntersectionMgmtDashboard/components/PieHalfChart.vue'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'
import TimeTitle from '@/views/IntersectionMgmtDashboard/components/TimeTitle.vue'
import { useIntersectionStore } from '@/store/intersection'
import ShadowCard from '@/components/ShadowCard.vue'

@Component({
  components: {
    ScaleChart,
    PieHalfChart,
    StatusText,
    TimeTitle,
    ShadowCard,
  },
})
export default class extends Vue {
  intersectionStore = useIntersectionStore()

  get isUpsOffline() {
    return this.intersectionStore.isUpsOffline
  }

  /// UPS
  get upsInfo() {
    return this.intersectionStore.upsInfo
  }
}
