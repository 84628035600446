// #region UPS 狀態
export enum UpsStatusLabel {
  待機中 = '待機中',
  電池模式 = '電池模式',
  市電模式 = '市電模式',
  電量不足關機 = '電量不足關機',
}
export const UpsStatusCodeLabelMap = new Map([
  [0, UpsStatusLabel.待機中],
  [2, UpsStatusLabel.電池模式],
  [3, UpsStatusLabel.市電模式],
  [4, UpsStatusLabel.電量不足關機],
])
export const UpsStatusLabelCodeConfigMap = new Map([
  [UpsStatusLabel.待機中, { color: '#fc7f7f', icon: 'mdi-chat-sleep ' }],
  [UpsStatusLabel.電池模式, { color: '#f0db1d', icon: 'mdi-checkbox-marked-circle' }],
  [UpsStatusLabel.市電模式, { color: '#00ce8e', icon: 'mdi-battery-low' }],
  [UpsStatusLabel.電量不足關機, { color: '#fc7f7f', icon: 'mdi-alert-circle' }],
])
// #endregion

// #region UPS 連線狀態
export enum UpsConnectStatusLabel {
  正常 = '正常',
  異常 = '異常',
}
export const UpsConnectStatusCodeLabelMap = new Map([
  [0, UpsConnectStatusLabel.正常],
  [1, UpsConnectStatusLabel.異常],
])
export const UpsConnectStatusLabelConfigMap = new Map([
  [UpsConnectStatusLabel.正常, { color: '--v-success-base', icon: 'mdi-check-bold' }],
  [UpsConnectStatusLabel.異常, { color: '--v-error-base', icon: 'mdi-close' }],
])
// #endregion

// #region UPS 負載狀態
export enum UpsOverloadStatusLabel {
  正常 = '正常',
  異常 = '異常',
}
export const UpsOverloadStatusCodeLabelMap = new Map([
  [0, UpsOverloadStatusLabel.正常],
  [1, UpsOverloadStatusLabel.異常],
])
export const UpsOverloadStatusLabelConfigMap = new Map([
  [UpsOverloadStatusLabel.正常, { color: '--v-success-base' }],
  [UpsOverloadStatusLabel.異常, { color: '--v-error-base' }],
])
// #endregion
