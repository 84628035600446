<template>
  <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition" :close-on-content-click="false">
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        style="background-color: var(--v-text-primary-base); color: var(--v-text-accent-base)"
        class="ml-2"
        min-width="0"
        fab
        x-small
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon style="font-size: 1.5rem">mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" nav>
      <v-list-item v-if="SYSTEM_ADMIN && INTERNAL">
        <div class="switch">
          <div class="switch__items" :class="{ selected: !switchValue }">專案管理</div>
          <v-switch v-model.trim="switchValue" hide-details></v-switch>
          <div class="switch__items" :class="{ selected: switchValue }">資訊查看</div>
        </div>
      </v-list-item>
      <v-divider v-if="SYSTEM_ADMIN && INTERNAL" />

      <template v-for="(p, i) in profile">
        <v-divider v-if="i > 0" :key="i" />
        <HoverListItem :key="`item-${i}`" @click="onItemClick(p)">
          <v-list-item-title class="titleList">{{ p.title }}</v-list-item-title>
        </HoverListItem>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import HoverListItem from '@/components/HoverListItem'
import { useUserStore } from '@/store/user'
import { useAppStore } from '@/store/app'
import { computed, ref, watch } from '@vue/composition-api'

const userStore = useUserStore()
const appStore = useAppStore()

export default {
  name: 'DefaultAccount',

  components: {
    HoverListItem,
  },

  setup() {
    const { switchMenu } = appStore
    const hasOtpStatus = ref(userStore.hasOtpStatus)
    const commonProfile = [{ title: '使用者更改密碼' }, { title: '登出' }]

    const profile = computed(() => {
      const otpProfile = hasOtpStatus.value ? { title: '刪除 TOTP' } : { title: '設定 TOTP' }
      return [...commonProfile.slice(0, 1), otpProfile, ...commonProfile.slice(1)]
    })

    const switchValue = computed({
      get: () => appStore.switchValue,
      set: (v) => {
        appStore.switchValue = !appStore.switchValue
      },
    })

    const SYSTEM_ADMIN = computed(() => JSON.parse(localStorage.getItem('userRoleGroupName')) === 'BMS_SYSTEM_ADMIN')
    const INTERNAL = computed(() => process.env.VUE_APP_NETWORK === 'internal')

    watch(
      () => appStore.switchValue,
      () => {
        switchMenu()
      }
    )

    watch(
      () => userStore.hasOtpStatus,
      (newValue) => {
        hasOtpStatus.value = newValue
      },
      { immediate: true }
    )

    return { profile, switchValue, SYSTEM_ADMIN, INTERNAL }
  },

  methods: {
    async checkUserTotp() {
      await userStore.checkUserTotp({ email: JSON.parse(JSON.stringify(localStorage.getItem('email'))) })
    },

    async handleLogout() {
      await userStore.logout()
    },

    async sendPasswordResetEmail() {
      try {
        appStore.isPageLoading = true

        await this.$api.user.updateSecretEmail()
        await this.$swal.fire({
          title: '已發送電子郵件',
          text: '重設密碼電子郵件已發送，請到電子郵件中確認',
          icon: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } catch (error) {
        console.log(error)
      } finally {
        appStore.isPageLoading = false
      }
    },

    async handleDeleteOtpSecret() {
      const { isConfirmed } = await this.$swal.fire({
        title: '確定要刪除 TOTP 設定',
        icon: 'warning',
        showCancelButton: true,
      })

      if (isConfirmed) {
        try {
          appStore.isPageLoading = true

          await this.$api.otp.deleteOtpSecret()
          await this.checkUserTotp()
          await this.$swal.fire({
            title: '已刪除 TOTP',
            icon: 'success',
            toast: true,
            position: 'top',
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          })
        } catch (error) {
          console.log(error)
        } finally {
          appStore.isPageLoading = false
        }
      }
    },

    async postOtpSecret() {
      await userStore.getTOTPQrCode()
      await this.checkUserTotp()
    },

    async onItemClick(p) {
      switch (p.title) {
        case '登出':
          await this.handleLogout()
          break
        case '使用者更改密碼':
          await this.sendPasswordResetEmail()
          break
        case '刪除 TOTP':
          await this.handleDeleteOtpSecret()
          break
        case '設定 TOTP':
          await this.postOtpSecret()
          break
        default:
          await this.handleLogout()
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

  &__items {
    margin: 0 12px;
    white-space: nowrap;
  }

  .selected {
    color: #556fff;
  }

  ::v-deep .v-label {
    margin: 0 12px !important;
  }

  padding: 20px 0 !important;
}

.titleList {
  margin: 24px 0;
  text-align: center;
  font-weight: bold;
  color: #8c98ba;
  font-size: 16px;
}
</style>
