<template>
  <div class="text-center">
    <v-dialog v-if="dialogStatus" :value="dialogStatus" :width="width" persistent>
      <v-card>
        <v-card-title class="text-h4 lighten-2 d-flex align-center justify-space-between px-4 font-weight-black sticky mb-4">
          <h3>{{ title }}</h3>
          <span class="closeBtn" @click="$emit('closeDialog', false)">
            <v-icon size="32" color="primary">mdi-close-circle-outline</v-icon>
          </span>
        </v-card-title>

        <slot></slot>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    width: {
      type: Number,
      default: () => 700,
    },
    title: {
      type: String,
      default: '',
    },
    dialogStatus: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.closeBtn {
  cursor: pointer;
  color: #0070f2;
}
.sticky {
  background: #ffff;
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
