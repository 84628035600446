import axios from 'axios'
import { ResponseInterceptors } from '@/util/interceptor'
import { useUserStore } from '@/store/user'
import Vue from 'vue'
import api from '@/api/api'

let controller = new AbortController()
export const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: true,
  signal: controller.signal,
})

export const abortRequest = () => {
  controller.abort()
  controller = new AbortController()
  axiosIns.defaults.signal = controller.signal
}

const responseInterceptorsIns = new ResponseInterceptors(axiosIns, {
  onReject: async (error: any) => {
    // console.log('[ResponseInterceptors - error.response]', error.response)

    if (error.message === 'Network Error') {
      await Vue.prototype.$swal.fire({
        title: `連線異常`,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return Promise.reject(error)
    }

    const userStore = useUserStore()

    switch (error.response?.status) {
      case 401:
        if (!sessionStorage.getItem('token')) throw error.response.data // 防止登入帳密錯誤 ( 401 ) 時又 refresh token

        try {
          responseInterceptorsIns.pause()

          const token = await api.auth.getRefreshedAccessToken()

          axiosIns.defaults.headers.common.Authorization = token as string
          error.config.headers.Authorization = token

          return await axiosIns.request(error.config)
        } catch (error) {
          console.log('Log Out ===>', error)

          await userStore.logout()
          throw error
        } finally {
          responseInterceptorsIns.use()
        }

      case 403:
        console.log('權限不足')
        break

      case 413:
        await Vue.prototype.$swal.fire({
          text: `圖片容量過大，請縮小圖片容量`,
          icon: 'warning',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        break

      case 503:
        await Vue.prototype.$swal.fire({
          text: `伺服器維護中`,
          icon: 'warning',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        await userStore.logout()
        break
      default:
        break
    }

    return Promise.reject(error.response)
  },
})

responseInterceptorsIns.use()
