<template>
  <v-app>
    <v-overlay :value="loading" z-index="999">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </v-overlay>
    <div class="wrap" :style="`background-image:url(${bg})`">
      <div class="glass">
        <div v-if="sendEmailStatus">
          <p class="text__size-14">重設密碼連結已發送至電子郵件中，請點擊電子郵件連結進行重設。</p>
          <p class="text__size-14">若您未收到電子郵件，可能有以下原因 :</p>
          <ol>
            <li class="text__size-14 mb-1">您輸入的電子郵件不正確。</li>
            <li class="text__size-14 mb-1">電子郵件送到垃圾信資料夾。</li>
            <li class="text__size-14 mb-1 breakWrap">您已封鎖來自 「{{ this.userEmail }}」 這個位址的電子郵件。</li>
          </ol>

          <div class="mt-8 d-flex flex-column justify-center">
            <v-btn class="mb-4 font-weight-black" color="white" plain x-large @click="sendEmailStatus = false">
              更改電子郵件信箱，請點擊此處
            </v-btn>

            <v-btn v-if="seconds === 0" class="text__size-14" color="primary" @click="submitEmail">重新發送電子郵件</v-btn>

            <v-btn v-else class="text__size-14" color="primary">需過 30 秒後才可再次發送 ( {{ seconds }} s )</v-btn>
          </div>
        </div>

        <div v-else class="glass__sendEmail">
          <v-toolbar-title class="mb-5 glass__sendEmail--title">請輸入 Email</v-toolbar-title>

          <v-text-field
            v-model.trim="userEmail"
            dark
            prepend-icon="mdi-account"
            name="Email"
            label="Email"
            type="text"
            autocomplete="off"
            @focus="errMsg = ''"
            @keyup.enter="submitEmail"
          />

          <p v-if="errMsg" style="text-align: center; color: var(--v-error-lighten1)">錯誤 : {{ errMsg }}</p>

          <div class="d-flex justify-center">
            <v-btn class="text__size-14" color="primary" @click="submitEmail">發送 </v-btn>
          </div>
        </div>

        <v-btn class="mt-3 font-weight-black" color="white" plain x-large @click="backLogin"> 回登入頁 </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import bg from '@/assets/bg.png'

export default {
  name: 'SendEmail',
  props: {
    source: String,
  },
  data: () => ({
    userEmail: '',
    errMsg: '',
    bg,
    loading: false,
    passwordStatus: true,
    sendEmailStatus: false,
    reSubmitStatus: false,
    seconds: 30,
  }),
  methods: {
    startCountdown() {
      setTimeout(() => {
        this.updateCountdown()
      }, 1000)
    },

    updateCountdown() {
      if (this.seconds > 0) {
        this.seconds--
        if (this.seconds === 0) {
          this.reSubmitStatus = false
        }
        this.startCountdown()
      }
    },

    async submitEmail() {
      if (!this.userEmail) {
        this.errMsg = '請輸入 Email'
        return
      }

      if (this.reSubmitStatus) return

      try {
        this.loading = true

        await this.$api.user.forgotSecretEmail({ userEmail: this.userEmail })
        this.seconds = 30
        await this.startCountdown()

        this.reSubmitStatus = true
        this.sendEmailStatus = true
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    backLogin() {
      this.$router.push({ path: '/login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.glass {
  width: 320px;

  @media screen and (min-width: 425px) {
    width: 360px;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }

  background-color: rgba(white, 0.25);
  backdrop-filter: blur(5px);
  padding: 3rem 2rem;
  border: 2px solid var(--v-text-accent-base);
  color: var(--v-text-accent-base);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .breakWrap {
    word-break: break-all;
  }

  &__sendEmail {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--title {
      text-align: center;
    }
  }
}
</style>
