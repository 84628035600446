import { axiosIns } from '@/api/axiosIns'
import { IAdminPostUserLogRequest, IAdminGetUserLogResponse } from '@/types/api/admin'

export const admin = {
  getVersion: () => {
    return axiosIns.get<string>(`${process.env.VUE_APP_API_PATH}/v1/auth/admin/version`)
  },

  getUserLoginLog: (params: IAdminPostUserLogRequest) => {
    return axiosIns.get<IAdminGetUserLogResponse>(`${process.env.VUE_APP_API_PATH}/v1/auth/admin/user/login/log`, { params })
  },
}

export default admin
