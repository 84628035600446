









import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EChartsOption } from 'echarts'
import { IEventErrorLogGetResponse } from '@/types/api/event'
import { createChart } from '@/util/echartUtil'
import { EVENT_TYPE } from '@/constants/event'

@Component
export default class OverviewChartErr extends Vue {
  @Prop({ type: Array }) errEventLogs!: IEventErrorLogGetResponse['data']

  echartOpts: EChartsOption = {
    grid: {
      left: 0,
      right: 0,
      width: '100%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (param: any) => {
        return `<div>
          <strong>${param[0].name}日</strong>
          ${param.map((x: any) => `<div>${x.marker} ${x.seriesName} ${x.data[1]} 次</div>`).join('')}
          </div>`
      },
    },
    legend: { width: '100%', bottom: 0, orient: 'horizontal' },
    xAxis: {
      type: 'category',
      axisLabel: { hideOverlap: false, align: 'center' },
    },
    yAxis: { type: 'value' },
    series: [
      {
        name: '市電異常事件',
        color: '#556fff',
        type: 'bar',
        // stack: 'e',
        data: Array.from(Array(this.$dayjs().daysInMonth())).map((_, i) => [i + 1, 0]),
      },
    ],
  }

  initSeries() {
    const [series] = this.echartOpts.series as any
    series.data = Array.from(Array(this.$dayjs().daysInMonth())).map((_, i) => [i + 1, 0])
  }

  async mounted() {
    const ref = await new Promise((resolve) => this.$nextTick(() => resolve(this.$refs.errEventsChart)))
    if (!(ref instanceof Element)) {
      return
    }
    await createChart.call(this, ref, this.echartOpts)
    this.initSeries()
  }

  @Watch('errEventLogs', { immediate: true })
  async setData() {
    this.initSeries()
    const [{ data: inputErrs }] = this.echartOpts.series as any
    for (const log of this.errEventLogs) {
      const idx = this.$dayjs(log.createTime).date() - 1
      if (log.eventType === EVENT_TYPE.input_error) {
        inputErrs[idx][1] += 1
      }
    }
  }
}
