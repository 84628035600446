











































































import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { WritableState, State } from '@/util/annotations'
import { Component, Vue } from 'vue-property-decorator'
import { IGetProjectItem } from '@/types/api/project'
import { useIntersectionStore } from '@/store/intersection'

@Component({
  components: {
    HoverListItem: () => import('@/components/HoverListItem.vue'),
    AppBarToggle: () => import('./AppBarToggle.vue'),
    DefaultAccount: () => import('./AppBarAccount.vue'),
  },
})
export default class DefaultBar extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  userStore = useUserStore()
  intersectionStore = useIntersectionStore()
  toolbarTitle = JSON.parse(localStorage.getItem('toolbarTitle')!)
  defaultTitle = JSON.parse(localStorage.getItem('toolbarTitle')!)
  moment = ''
  projectList: IGetProjectItem[] = JSON.parse(localStorage.getItem('userProjectList')!)

  get smAndDown() {
    // console.log(this.$vuetify.breakpoint)
    return this.$vuetify.breakpoint.smAndDown
  }

  get showSelectIcon() {
    return this.projectList === null ? false : this.projectList.length > 1
  }

  get breakpointToolBarTitle() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return this.toolbarTitle.substring(0, 10) + '...'
    }
    return this.toolbarTitle
  }

  async setDefaultProject(item: IGetProjectItem) {
    if (!item.enabled) return

    try {
      this.isPageLoading = true

      await this.$api.user.updateDefaultProject({ defaultProjectId: item.projectId })
      const data = await this.$api.project.gteProjectUserList()

      const { defaultProjectId } = data!.filter((i) => i.userId === JSON.parse(localStorage.getItem('userId')!))[0]
      const { projectLabel } = this.projectList.filter((i) => i.projectId === defaultProjectId)[0]
      this.defaultTitle = projectLabel

      localStorage.setItem('defaultTitle', JSON.stringify(projectLabel))

      this.$swal.fire({
        title: `已將 ${item.projectLabel} 設為 預設專案`,
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 2000,
        width: 500,
        showConfirmButton: false,
        timerProgressBar: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async changeProject(item: IGetProjectItem) {
    if (!item.enabled || item.projectLabel === this.toolbarTitle) return

    try {
      await this.userStore.getProjectList()
      await this.userStore.handleChangeProject(item.projectId)

      /** @todo 以上執行完 this.isPageLoading 被改為 false */
      this.isPageLoading = true

      this.toolbarTitle = item.projectLabel || JSON.parse(localStorage.getItem('toolbarTitle')!)

      await this.$swal.fire({
        title: `專案已切換成 ${item.projectLabel}`,
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 2000,
        width: 500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      if (this.$route.name === 'Overview') {
        await this.intersectionStore.setAllIntersection()
        await this.intersectionStore.getInputErrorLog()
        await this.intersectionStore.getWorkLogList()
        await this.intersectionStore.getErrEventLogList()
        await this.intersectionStore.getBatteryLevelList()
      }

      await this.userStore.getRouterMenu()
      await this.$router.replace(this.userStore.defaultPath).catch((error) => error)

      /** @todo 再改變專案後且所有資料取得完後，改變 userStore.projectId 狀態 map watch 這個來做改變 */
      this.userStore.projectId = JSON.parse(localStorage.getItem('projectId')!)
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  mounted() {
    this.moment = this.$dayjs().format('YYYY/MM/DD HH:mm:ss')
    setInterval(() => {
      this.moment = this.$dayjs().format('YYYY/MM/DD HH:mm:ss')
    }, 1000)
  }

  @State(useAppStore, 'weather')
  weather!: string

  @WritableState(useAppStore, 'drawerVisibility')
  drawerVisibility!: boolean

  // @Watch('$route', { immediate: true })
  // onRouterChange(route: { meta: { name: string } }) {
  //   this.toolbarTitle = '交通號誌不斷電系統'
  // }
}
