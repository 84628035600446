<template>
  <v-app>
    <v-overlay :value="loading" z-index="999">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </v-overlay>
    <div class="wrap" :style="`background-image:url(${bg})`">
      <div class="glass">
        <div v-if="resetPassword" class="glass__resetPassword">
          <h2>
            <v-icon icon="mdi-checkbox-marked-circle" color="success">mdi-checkbox-marked-circle</v-icon>
            密碼已重設。
          </h2>
        </div>

        <div v-else class="glass__inputPassword">
          <ValidationObserver ref="form">
            <v-toolbar-title class="mb-5 glass__inputPassword--title">請輸入新密碼</v-toolbar-title>

            <div class="mb-6">
              <ValidationProvider ref="secret" v-slot="{ errors }" name="Secret" rules="required|password">
                <v-text-field
                  v-model.trim="secret"
                  dark
                  prepend-icon="mdi-lock"
                  name="Secret"
                  label="密碼"
                  autocomplete="off"
                  :type="passwordStatus ? 'password' : 'text'"
                  hide-details
                  @click:append="passwordStatus = !passwordStatus"
                  @focus="errMsg = ''"
                />
                <div class="errorAlert">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <ValidationProvider ref="confirmSecret" v-slot="{ errors }" name="ConfirmSecret" rules="required|password">
              <v-text-field
                v-model.trim="confirmSecret"
                dark
                prepend-icon="mdi-lock"
                name="ConfirmSecret"
                label="確認密碼"
                autocomplete="off"
                :append-icon="passwordStatus ? 'mdi-eye-off' : 'mdi-eye'"
                :type="passwordStatus ? 'password' : 'text'"
                hide-details
                @click:append="passwordStatus = !passwordStatus"
                @focus="errMsg = ''"
              />
              <div class="errorAlert">{{ errors[0] }}</div>
            </ValidationProvider>
          </ValidationObserver>

          <p v-if="errMsg" style="text-align: center; color: var(--v-error-lighten1)">錯誤 : {{ errMsg }}</p>

          <div class="mt-4 d-flex justify-center">
            <v-btn class="text__size-14" color="primary" @click="handleReset"> 重設密碼 </v-btn>
          </div>
        </div>

        <v-btn class="mt-3 font-weight-black" color="white" plain x-large @click="handleBack"> 回登入頁 </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import bg from '@/assets/bg.png'
import { useUserStore } from '@/store/user'

export default {
  name: 'ResetPassword',
  props: {
    source: String,
  },
  data: () => ({
    secret: '',
    confirmSecret: '',
    errMsg: '',
    bg,
    loading: false,
    passwordStatus: true,
    resetPassword: false,
    updateToken: '',
  }),
  mounted() {
    this.getResetTokenFromURL()
  },
  methods: {
    getResetTokenFromURL() {
      const urlParams = new URLSearchParams(window.location.search)
      this.updateToken = urlParams.get('token')
    },

    async handleReset() {
      const status = await this.$refs.form.validate()
      if (!status) return

      if (this.secret !== this.confirmSecret) {
        this.errMsg = '請確認密碼'
        return
      }

      try {
        this.loading = true

        await this.$api.user.updatePassword({ updateToken: this.updateToken, value: this.confirmSecret })

        this.resetPassword = true
      } catch (error) {
        this.errMsg = error.errorMsg
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    handleBack() {
      this.$router.push({ path: '/login' })

      if (this.resetPassword) {
        const { logout: userLogout } = useUserStore()

        userLogout()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.errorAlert {
  font-weight: bold;
  font-size: 14px;
}

.glass {
  width: 320px;

  @media screen and (min-width: 425px) {
    width: 360px;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }

  background-color: rgba(white, 0.25);
  backdrop-filter: blur(5px);
  padding: 3rem 2rem;
  border: 2px solid var(--v-text-accent-base);
  color: var(--v-text-accent-base);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .breakWrap {
    word-break: break-all;
  }

  &__resetPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__inputPassword {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--title {
      text-align: center;
    }
  }
}
</style>
