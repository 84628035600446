import { axiosIns } from '../axiosIns'
import {
  IPostProjectRequest,
  IPutProjectRequest,
  IGetProjectItem,
  IGetProjectUserItem,
  IDeleteProjectUser,
  ICreateProjectUser,
  IEditProjectUser,
} from '@/types/api/project'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  createProject: (payload: IPostProjectRequest) => {
    return handleResponse<IGetProjectItem>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/project`, payload))
  },

  editProject: (payload: IPutProjectRequest) => {
    return handleResponse<IGetProjectItem>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/project`, payload))
  },

  deleteProject: (params: { projectId: string }) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/project`, { params }))
  },

  getProjectList: () => {
    return axiosIns.get<IGetProjectItem[]>(`${process.env.VUE_APP_API_PATH}/v1/auth/project/list`)
  },

  editProjectUser: (payload: IEditProjectUser) => {
    return handleResponse<void>(axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/project/user`, payload))
  },

  createProjectUser: (payload: ICreateProjectUser) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/project/user`, payload))
  },

  deleteProjectUser: (params: IDeleteProjectUser) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/project/user`, { params }))
  },

  gteProjectUserList: () => {
    return handleResponse<IGetProjectUserItem[]>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/project/user/list`))
  },
}
