var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-x-transition',{attrs:{"mode":"out-in","duration":"500"}},[(_vm.showTableStatus)?_c('div',[_c('h2',{staticClass:"font-weight-black mb-4"},[_vm._v("所有群組列表 ("+_vm._s(_vm.tableList.length)+")")]),_c('div',{staticClass:"IntersectionTable"},[_c('div',{staticClass:"searchInput"},[_c('v-text-field',{attrs:{"placeholder":"搜尋群組或使用者","outlined":"","dense":"","height":"32","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.search === '')?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',{on:{"click":function($event){_vm.search = ''}}},[_vm._v("mdi-close")])]},proxy:true}],null,false,3487067220),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('SearchResult',{attrs:{"search":_vm.search,"searchResultNum":_vm.searchResultNum},on:{"clearSearch":function($event){_vm.search = ''}}}),_c('v-data-table',{staticClass:"mt-5 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableList,"search":_vm.search},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.userLabel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userLabel.length)+" ")]}},{key:"item.email",fn:function(){return undefined},proxy:true},{key:"item.userDetailList",fn:function(ref){
var item = ref.item;
return [_c('ol',_vm._l((item.userDetailList),function(i,index){return _c('li',{key:index},[_c('div',{staticClass:"userDetailList"},[_c('div',{staticClass:"userDetailList__title"},[_c('span',{staticClass:"userDetailList__title--label"},[_vm._v("使用者代號 : ")]),_vm._v(_vm._s(i.userLabel)+" ")]),_c('div',{staticClass:"userDetailList__title"},[_c('span',{staticClass:"userDetailList__title--label"},[_vm._v("電子信箱 : ")]),_vm._v(_vm._s(i.email))])])])}),0)]}},{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"handle"},[(item.lineToken !== '')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"mr-3",on:{"click":function($event){return _vm.copyLineToken(item)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("複製 Line Token")])]):_vm._e(),_c('button',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('button',{on:{"click":function($event){return _vm.handleDeleteGroup(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}}],null,true)})],1)]):_c('EditGroup',{attrs:{"userList":_vm.userList,"editTemp":_vm.editTemp},on:{"refreshList":_vm.refreshList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }