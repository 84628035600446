
















































































































import { useAppStore } from '@/store/app'
import { IGetIntersectionItem } from '@/types/api/intersection'
import { Component, Vue } from 'vue-property-decorator'
import DevicesMgmtCreateIntersectionUpload from './DevicesMgmtCreateIntersectionUpload.vue'
import { WritableState } from '@/util/annotations'
import SearchResult from '@/components/SearchResult.vue'
import EditIntersection from './Component/EditIntersectionInfo.vue'
import Dialog from '@/components/ImageDialog.vue'

const downloadCsv = require('vue-json-csv')
const temp = {
  intersectionId: '',
  inputStatus: '',
  intersectionNumber: '',
  intersectionLabel: '',
  groupId: '',
  latitude: '',
  longitude: '',
  createTime: 0,
  updateTime: 0,
  intersectionStatus: '',
  upsEnableTime: 0,
  upsStatus: '',
  avgBatteryLevel: 0,
}

@Component({
  components: {
    DevicesMgmtCreateIntersectionUpload,
    downloadCsv,
    SearchResult,
    EditIntersection,
    Dialog,
  },
})
export default class DevicesMgmtCreateIntersection extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  search = ''
  searchResultNum = 0
  totalElements = 0

  dialogStatus = false
  editTemp = JSON.parse(JSON.stringify(temp))

  tableList: IGetIntersectionItem[] = []
  tableHeader = [
    { text: '編號', value: 'intersectionNumber', align: 'left' },
    { text: '路口/供電車 名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '所屬群組', value: 'groupName', sortable: false, align: 'left' },
    { text: '經緯度', value: 'latitude', sortable: false, align: 'left' },
    { text: '建檔時間', value: 'createTime', align: 'left' },
    { text: '上次更新時間', value: 'updateTime', align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  get ADMIN_ROLE() {
    return this.isAdminRole('BMS_SYSTEM_ADMIN') || this.isAdminRole('BMS_PROJECT_ADMIN')
  }

  isAdminRole(roleName: string) {
    const userRoleGroupName = JSON.parse(localStorage.getItem('userRoleGroupName') || 'null')
    return userRoleGroupName === roleName
  }

  get dialogTitle() {
    return `編輯編號 ${this.editTemp.intersectionNumber} ${this.editTemp.intersectionType === 'FIXED' ? '路口' : '供電車'}`
  }

  get tableItems() {
    const groupNameList = JSON.parse(localStorage.getItem('intersectionGroup') as string)

    if (groupNameList) {
      const groupMap = new Map(Object.entries(groupNameList))

      this.tableList.forEach((item) => {
        if (groupMap.has(item.groupId)) {
          item.groupName = groupMap.get(item.groupId) as string
        } else if (item.groupId === 'MOBILE') {
          item.groupName = '供電車'
        } else {
          item.groupName = ''
        }
      })
    }

    return this.tableList
  }

  get CSVTable() {
    return this.tableItems.map((i) => ({
      編號: i.intersectionNumber,
      [`路口 ID`]: i.intersectionId,
      路口名稱: i.intersectionLabel,
      所屬群組: i.groupName,
      經度: i.longitude,
      緯度: i.latitude,
      建檔時間: i.createTime ? this.$dayjs(i.createTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
      上次更新時間: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY年MM月DD日 HH時mm分ss') : '----/--/-- --:--:--',
    }))
  }

  async mounted() {
    await (JSON.parse(localStorage.getItem('projectId')!) && this.getIntersectionList())
  }

  async getIntersectionList(page = 0) {
    if (page === 0) {
      this.tableList.splice(0)
    }

    try {
      this.isPageLoading = true

      const data = await this.$api.intersection.getIntersectionList({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        sort: 'DESC',
        pageSize: 100,
        page,
      })

      this.totalElements = data!.totalElements
      const filteredArray = data!.data.filter((x) => !this.tableList.some((i) => i.intersectionId === x.intersectionId))
      this.tableList = [...this.tableList, ...filteredArray]

      if (data!.hasNext) {
        await this.getIntersectionList(page + 1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  getFiltered(e: IGetIntersectionItem[]) {
    this.searchResultNum = e.length
  }

  async showSwalFireMessage(title: string) {
    await this.$swal.fire({
      title,
      icon: 'success',
      toast: true,
      position: 'top',
      timer: 1500,
      showConfirmButton: false,
      timerProgressBar: true,
    })
  }

  async copyLineToken(item: IGetIntersectionItem) {
    await navigator.clipboard.writeText(item.intersectionId)
    await this.showSwalFireMessage('已複製 Intersection ID')
  }

  async handleDelete(item: IGetIntersectionItem) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要刪除 ${item.intersectionLabel}?`,
      icon: 'warning',
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: '請輸入「確認刪除」',
      inputValidator: (value: string) => {
        if (value !== '確認刪除') {
          return '輸入文字不正確'
        }

        return null
      },
    })

    if (isConfirmed) {
      await this.doDelete(item)
    }
  }

  async doDelete(item: IGetIntersectionItem) {
    try {
      this.isPageLoading = true

      await this.$api.intersection.deleteIntersection({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        intersectionId: item.intersectionId,
      })
      await this.showSwalFireMessage('刪除成功')
      await this.getIntersectionList()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async closeDialogAndRefreshList() {
    await this.toggleDialog()
    await this.getIntersectionList()
  }

  toggleDialog() {
    this.dialogStatus = !this.dialogStatus
    if (!this.dialogStatus) {
      this.editTemp = JSON.parse(JSON.stringify(temp))
    }
  }

  async handleEdit(item: IGetIntersectionItem) {
    this.editTemp = JSON.parse(JSON.stringify(item))

    this.toggleDialog()
  }

  async enabledIntersection(intersectionLabel: string, intersectionId: string) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要啟用${intersectionLabel} ?`,
      icon: 'warning',
      showCancelButton: true,
    })
    if (isConfirmed) {
      try {
        await this.$api.intersection.enableIntersection({ intersectionId })
        await this.getIntersectionList()
        await this.showSwalFireMessage(`已啟用${intersectionLabel}`)
      } catch (error) {
        console.log(error)
      }
    }
  }

  async disabledIntersection(intersectionLabel: string, intersectionId: string) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要停用${intersectionLabel} ?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        await this.$api.intersection.disableIntersection({ intersectionId })
        await this.getIntersectionList()
        await this.showSwalFireMessage(`已停用${intersectionLabel}`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
