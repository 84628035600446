

























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { useIntersectionStore } from '@/store/intersection'
import { useAppStore } from '@/store/app'
// import { abortRequest } from '@/api/axiosIns'
import Dialog from '@/components/Dialog.vue'
import DatePicker from '@/components/DatePicker.vue'
import ShadowCard from '@/components/ShadowCard.vue'

import api from '@/api/api'
import { DeviceType } from '@/constants/device'

import IndexBattery from './indexBattery.vue'
import IndexInput from './indexInput.vue'
import IndexUps from './indexUps.vue'

import TimeChart from '@/views/IntersectionMgmtDashboard/components/TimeChart.vue'
import StatusText from '@/views/IntersectionMgmtDashboard/components/StatusText.vue'

import IndexInputErrorLog from './indexInputErrorLog.vue'
import IndexErrEventStatics from './indexErrEventStatics.vue'
import IndexErrEventList from './indexErrEventList.vue'
import { DATE_PLACEHOLDER, DATE_TIME_FORMATTER, TIME_PLACEHOLDER } from '@/constants/constants'

import { abortRequest } from '@/api/axiosIns'

/** POC 中用來 電池電量轉換(棄用) */
/*
const translateBatteryLevel = (level?: number | string) => {
  const _level = Number(level) * 1.37 - 22
  return Number((_level < 0 ? 0 : _level > 100 ? 100 : _level).toFixed(1))
}
*/

/** POC 中用來 維持資料順序(棄用) */
/*
const appendTimeSeriesData = (dest: number[][], src: number[]) => {
  if (dest[dest.length - 1]) {
    if (src[0] < dest[dest.length - 1][0]) {
      const i = dest.findIndex(([ts]) => ts > src[0])
      dest.splice(i, 0, src)
      return
    }
  }
  dest.push(src)
}
*/

const getSeriesLastDataTime = (series: any) => {
  return Math.max(
    ...series.map((x: any) => {
      const [t] = x.data.slice(-1)
      if (t === undefined) {
        return 0
      }
      return t[0]
    })
  )
}

let intervalTimer: any = null
const interval = 10000

interface IStaticTimeSeriesData {
  batterACurrentData: [number, number, string][]
  batterBCurrentData: [number, number, string][]
  batterALevelData: [number, number, string][]
  batterBLevelData: [number, number, string][]
  batterAVoltageData: [number, number, string][]
  batterBVoltageData: [number, number, string][]
  intersectionLevelData: [number, number][]
  inputVoltageData: [number, number][]
  inputFrequencyData: [number, number][]
  upsTemperatureData: [number, number][]
  upsOutputData: [number, number][]
  batterATemperatureData: [number, number, string][]
  batterBTemperatureData: [number, number, string][]
}

@Component({
  components: {
    TimeChart,
    Dialog,
    DatePicker,
    IndexInputErrorLog,
    IndexErrEventStatics,
    IndexErrEventList,
    IndexBattery,
    IndexInput,
    IndexUps,
    ShadowCard,
    StatusText,
  },
})
export default class IntersectionMgmtDashboard extends Vue {
  intersectionStore = useIntersectionStore()
  appStore = useAppStore()

  get currentStatus() {
    return useAppStore().currentStatus
  }

  /// 路口
  get intersectionInfo() {
    return this.intersectionStore.intersectionInfo
  }

  /// 電池
  get batteryInfo() {
    return this.intersectionStore.batteryInfo
  }

  get isBatteryOffline() {
    return this.intersectionStore.isBatteryOffline
  }

  /// 市電
  get inputInfo() {
    return this.intersectionStore.inputInfo
  }

  get isInputOffline() {
    return this.intersectionStore.isInputOffline
  }

  /// UPS
  get upsInfo() {
    return this.intersectionStore.upsInfo
  }

  get isUpsOffline() {
    return this.intersectionStore.isUpsOffline
  }

  /// 圖表

  voltageSeries = [
    {
      name: '電池A電壓',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '電池B電壓',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '市電電壓',
      type: 'line',
      connectNulls: true,
      color: '#ff9800',
      data: [] as Array<[number, number]>,
    },
  ]

  batteryCurrentSeries = [
    {
      name: '電池A電流',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '電池B電流',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
  ]

  batteryLevelSeries = [
    {
      name: '電池A電量',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '電池B電量',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '路口電量',
      type: 'line',
      connectNulls: true,
      color: '#ff9800',
      data: [] as Array<[number, number]>,
    },
  ]

  batterTemperatureSeries = [
    {
      name: 'UPS溫度',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number]>,
    },
    {
      name: '電池A溫度',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number, string]>,
    },
    {
      name: '電池B溫度',
      type: 'line',
      connectNulls: true,
      color: '#ff9800',
      data: [] as Array<[number, number, string]>,
    },
  ]

  upsPowerSeries = [
    {
      name: 'UPS功率',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number]>,
    },
  ]

  inputFreqSeries = [
    {
      name: '市電頻率',
      color: '#b94aff',
      type: 'line',
      connectNulls: true,
      data: [] as Array<[number, number]>,
    },
  ]

  get voltageSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.voltageSeries)
    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  get batteryLevelSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.batteryLevelSeries)
    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  get batteryCurrentSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.batteryCurrentSeries)

    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  get batterTemperatureSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.batterTemperatureSeries)
    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  get upsPowerSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.upsPowerSeries)
    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  get inputFreqSeriesLastDataTime() {
    const t = getSeriesLastDataTime(this.inputFreqSeries)
    return t ? this.$dayjs(t).format(DATE_TIME_FORMATTER) : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`
  }

  anyOneDate = '' //  this.$dayjs().format('YYYY-MM-DD')
  anyOneDateMax: string = this.$dayjs().toISOString(true)
  anyOneDateMin: string = this.$dayjs().subtract(1, 'month').toISOString(true)
  realTimeRange = '8hr' // '24hr' | '8hr' | '3hr' | '1hr'

  asyncTasks: any[] = []
  isTaskRunning = false

  minDate = this.$dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD') // 當前月份往前三個月的第一天
  maxDate = this.$dayjs().endOf('month').format('YYYY-MM-DD') // 當前月份的最後一天
  monthTime = ''

  /** 依起迄從 API 取回資料存入狀態中來呈現給定起迄的圖表資料 */
  async setStaticTimeSeriesData(s: number, e: number) {
    const staticTimeSeriesData = await this.getTelemetryTimeSeriesData(s, e, 0, 10000)
    // console.log({ staticTimeSeriesData })

    const [aVoltageSeries, bVoltageSeries, iVoltageSeries] = this.voltageSeries
    const [aCurrentSeries, bCurrentSeries] = this.batteryCurrentSeries
    const [aLevelSeries, bLevelSeries, iLevelSeries] = this.batteryLevelSeries
    const [upsTemperatureSeries, batterATemperatureSeries, batterBTemperatureSeries] = this.batterTemperatureSeries
    const [upsPowerSeries] = this.upsPowerSeries
    const [inputFreqSeries] = this.inputFreqSeries

    aVoltageSeries.data = staticTimeSeriesData.batterAVoltageData
    bVoltageSeries.data = staticTimeSeriesData.batterBVoltageData
    iVoltageSeries.data = staticTimeSeriesData.inputVoltageData
    aCurrentSeries.data = staticTimeSeriesData.batterACurrentData
    bCurrentSeries.data = staticTimeSeriesData.batterBCurrentData
    aLevelSeries.data = staticTimeSeriesData.batterALevelData
    bLevelSeries.data = staticTimeSeriesData.batterBLevelData
    iLevelSeries.data = staticTimeSeriesData.intersectionLevelData

    upsTemperatureSeries.data = staticTimeSeriesData.upsTemperatureData
    upsPowerSeries.data = staticTimeSeriesData.upsOutputData
    inputFreqSeries.data = staticTimeSeriesData.inputFrequencyData

    batterATemperatureSeries.data = staticTimeSeriesData.batterATemperatureData
    batterBTemperatureSeries.data = staticTimeSeriesData.batterBTemperatureData
  }

  /** 取得指定時間段的圖表資料 */
  async getTelemetryTimeSeriesData(
    startTime: number,
    endTime: number,
    page = 0,
    pageSize = 10000,
    ref: IStaticTimeSeriesData = {
      batterACurrentData: [],
      batterBCurrentData: [],
      batterALevelData: [],
      batterBLevelData: [],
      batterAVoltageData: [],
      batterBVoltageData: [],
      intersectionLevelData: [],
      inputVoltageData: [],
      inputFrequencyData: [],
      upsTemperatureData: [],
      upsOutputData: [],
      batterATemperatureData: [],
      batterBTemperatureData: [],
    }
  ) {
    try {
      const telemetry = await api.event.getTelemetry({
        sort: 'ASC',
        intersectionId: this.intersectionStore.intersectionIdActived,
        startTime,
        endTime,
        page,
        pageSize,
      })

      const batteryTsLevelMap = new Map<number, number>()
      for (const telemetryData of telemetry!.data) {
        if (telemetryData.deviceType === DeviceType.battery) {
          const position = +telemetryData.position === 0 ? 'A' : 'B'

          const exBatteryLevel = batteryTsLevelMap.get(telemetryData.dataTime)
          if (exBatteryLevel === undefined) {
            batteryTsLevelMap.set(telemetryData.dataTime, +telemetryData.payload.batteryLevel)
          } else {
            batteryTsLevelMap.set(telemetryData.dataTime, (exBatteryLevel + +telemetryData.payload.batteryLevel) / 2)
          }

          Reflect.get(ref, `batter${position}LevelData`).push([
            telemetryData.dataTime,
            +telemetryData.payload.batteryLevel,
            telemetryData.deviceLabel,
          ])
          Reflect.get(ref, `batter${position}CurrentData`).push([
            telemetryData.dataTime,
            +telemetryData.payload.batteryCurrent,
            telemetryData.deviceLabel,
          ])
          Reflect.get(ref, `batter${position}VoltageData`).push([
            telemetryData.dataTime,
            +telemetryData.payload.batteryVoltage,
            telemetryData.deviceLabel,
          ])
          Reflect.get(ref, `batter${position}TemperatureData`).push([
            telemetryData.dataTime,
            +telemetryData.payload.batteryTemperature,
            telemetryData.deviceLabel,
          ])
        }
        if (telemetryData.deviceType === DeviceType.gateway) {
          ref.inputVoltageData.push([telemetryData.dataTime, +telemetryData.payload.inputVoltage])
          ref.inputFrequencyData.push([telemetryData.dataTime, +telemetryData.payload.inputFrequency])
        }
        if (telemetryData.deviceType === DeviceType.ups) {
          ref.upsTemperatureData.push([telemetryData.dataTime, +telemetryData.payload.upsTemperature])
          ref.upsOutputData.push([telemetryData.dataTime, +telemetryData.payload.upsOutput])
        }
      }

      // 取回路口電量 (2粒平均)
      // console.log({ batteryTsLevelMap })
      for (const [ts, avgLevel] of batteryTsLevelMap) {
        ref.intersectionLevelData.push([+ts, avgLevel])
      }

      if (telemetry!.hasNext) {
        await this.getTelemetryTimeSeriesData(startTime, endTime, page + 1, pageSize, ref)
      }
    } catch (e) {
      console.error(e)
    }
    return ref
  }

  async mounted() {
    this.monthTime = this.$dayjs().format('YYYY-MM')
    // 上面三張的，是存在狀態裡面
    const { id } = this.$route.query
    if (id !== undefined && typeof id === 'string') {
      try {
        this.appStore.currentStatus = true

        await this.intersectionStore.setActivedIntersectionId(id)
      } catch {
        this.$router.push('/intersection-mgmt')
      } finally {
        this.appStore.currentStatus = false
      }
    }
    await this.onTimeRangeChange()
  }

  async beforeDestroy() {
    await abortRequest()
    await this.intervalStop()
  }

  /**
   * 停止請求、定時任務
   */
  intervalStop() {
    // abortRequest()
    this.asyncTasks.splice(0)
    intervalTimer && clearInterval(intervalTimer)
  }

  /**
   * 開始定時任務
   */
  intervalStart() {
    intervalTimer && clearInterval(intervalTimer)
    intervalTimer = setInterval(async () => {
      if (this.isTaskRunning) {
        throw new Error('busy')
      }
      if (this.asyncTasks.length === 0) {
        this.intervalStop()
        throw new Error('without tasks')
      }
      this.isTaskRunning = true
      await this.asyncTasks.shift()()
      this.isTaskRunning = false
    }, interval)
  }

  @Watch('anyOneDate')
  async onAnyOneDateChange() {
    /** @todo */
    this.realTimeRange = ''

    this.intervalStop()
    try {
      this.appStore.currentStatus = true
      const s = Number(this.$dayjs(this.anyOneDate).startOf('day').valueOf())
      const e = Number(this.$dayjs(this.anyOneDate).endOf('day').valueOf())
      await this.setStaticTimeSeriesData(s, e)
    } catch (e) {
      console.error(e)
    } finally {
      this.appStore.currentStatus = false
    }
  }

  @Watch('realTimeRange')
  async onTimeRangeChange() {
    /** @todo */
    if (this.realTimeRange === '') {
      return
    }
    this.intervalStop()
    try {
      this.appStore.currentStatus = true
      const [h] = this.realTimeRange.match(/^\d+/g)!
      const s = Number(this.$dayjs().subtract(h, 'hour').valueOf())
      const e = Number(this.$dayjs().valueOf())
      await this.setStaticTimeSeriesData(s, e)
      this.queueAsyncTasks()
    } catch (e) {
      console.error(e)
    } finally {
      this.appStore.currentStatus = false
    }
  }

  /**
   * 排入定時任務
   */
  queueAsyncTasks() {
    // 即時的資料處理、任務
    let instantStartTime = Number(this.$dayjs().valueOf()) // onTimeRangeChange 執行完的時間 = 即時資料開始的時間

    const updater = async () => {
      const staticTimeSeriesData = await this.getTelemetryTimeSeriesData(instantStartTime, Number(this.$dayjs().valueOf()), 0, 100)

      // console.log('updater', { staticTimeSeriesData })

      const [aVoltageSeries, bVoltageSeries, iVoltageSeries] = this.voltageSeries
      const [aCurrentSeries, bCurrentSeries] = this.batteryCurrentSeries
      const [aLevelSeries, bLevelSeries, iLevelSeries] = this.batteryLevelSeries
      const [upsTemperatureSeries, batterATemperatureSeries, batterBTemperatureSeries] = this.batterTemperatureSeries
      const [upsPowerSeries] = this.upsPowerSeries
      const [inputFreqSeries] = this.inputFreqSeries

      aVoltageSeries.data.push(...(staticTimeSeriesData.batterAVoltageData as any))
      bVoltageSeries.data.push(...(staticTimeSeriesData.batterBVoltageData as any))
      iVoltageSeries.data.push(...(staticTimeSeriesData.inputVoltageData as any))
      aCurrentSeries.data.push(...staticTimeSeriesData.batterACurrentData)
      bCurrentSeries.data.push(...staticTimeSeriesData.batterBCurrentData)
      aLevelSeries.data.push(...(staticTimeSeriesData.batterALevelData as any))
      bLevelSeries.data.push(...(staticTimeSeriesData.batterBLevelData as any))
      iLevelSeries.data.push(...(staticTimeSeriesData.intersectionLevelData as any))

      upsTemperatureSeries.data.push(...(staticTimeSeriesData.upsTemperatureData as any))
      upsPowerSeries.data.push(...staticTimeSeriesData.upsOutputData)
      inputFreqSeries.data.push(...staticTimeSeriesData.inputFrequencyData)
      batterATemperatureSeries.data.push(...(staticTimeSeriesData.batterATemperatureData as any))
      batterBTemperatureSeries.data.push(...(staticTimeSeriesData.batterBTemperatureData as any))

      instantStartTime = Number(this.$dayjs().valueOf())

      this.asyncTasks.push(updater)
    }

    this.asyncTasks.push(updater)

    this.intervalStart()
  }
}
