<template>
  <div class="text-center">
    <v-dialog v-if="dialogStatus" :value="dialogStatus" :width="width" persistent>
      <v-card>
        <v-card-title class="text-h4 grey lighten-2 d-flex align-center justify-space-between px-4 font-weight-black sticky">
          {{ title }}
          <VIcon @click="$emit('closeDialog', false)">mdi-close-circle</VIcon>
        </v-card-title>

        <slot></slot>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VIcon } from 'vuetify/lib'
export default {
  components: {
    VIcon,
  },
  props: {
    width: {
      type: Number,
      default: () => 700,
    },
    title: {
      type: String,
    },
    dialogStatus: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
