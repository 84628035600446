

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeChart from '@/views/IntersectionMgmtDashboard/components/TimeChart.vue'
import api from '@/api/api'
import { useIntersectionStore } from '@/store/intersection'
import { EVENT_TYPE } from '@/constants/event'
import { IEventErrorLogGetResponse } from '@/types/api/event'

const seriesTemp = [
  {
    name: 'UPS斷線',
    color: '#b94aff',
    type: 'bar',
    data: [] as Array<Array<string | number>>,
  },
  {
    name: '電池斷線',
    color: '#556fff',
    type: 'bar',
    data: [] as Array<Array<string | number>>,
  },
  {
    name: '市電異常',
    color: '#ff9800',
    type: 'bar',
    data: [] as Array<Array<string | number>>,
  },
]

@Component({
  components: { TimeChart },
})
export default class IndexErrEventStatics extends Vue {
  @Prop({ type: String }) time!: string

  intersectionStore = useIntersectionStore()

  startTime = this.$dayjs().startOf('month').valueOf() // 取得當前月份的第一天
  endTime = this.$dayjs().endOf('month').valueOf() // 取得當前月份的最後一天

  timer: any = null

  series = JSON.parse(JSON.stringify(seriesTemp))

  get title() {
    return `${this.time ? this.$dayjs(this.time).format('YYYY 年 MM ') : this.time}月異常事件統計`
  }

  @Watch('time')
  async onChange() {
    this.startTime = this.$dayjs(this.time).startOf('month').valueOf()
    this.endTime = this.$dayjs(this.time).endOf('month').valueOf()

    this.series = JSON.parse(JSON.stringify(seriesTemp))
    await this.setSeriesData()
  }

  async setData(page = 0, countingMap: Map<EVENT_TYPE, IEventErrorLogGetResponse['data']>) {
    try {
      const data = await api.event.getEventErrorLog({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.startTime,
        endTime: this.endTime,
        sort: 'DESC',
        pageSize: 100,
        page,
        intersectionId: `${this.$route.query.id}`,
      })

      for (const x of data!.data) {
        const val = countingMap.get(x.eventType)
        if (val === undefined) {
          continue
        }
        countingMap.set(x.eventType, [...val, x])
      }

      if (data!.hasNext) {
        await this.setData(page + 1, countingMap)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async setSeriesData() {
    // init series data : fill dates
    const [upsErr, batteryErr, inputErr] = this.series
    const dateIdxArr = []
    let ts = this.startTime

    while (ts < this.endTime) {
      const date = this.$dayjs(ts).format('MM/DD')
      dateIdxArr.push(date)
      upsErr.data.push([date, 0])
      batteryErr.data.push([date, 0])
      inputErr.data.push([date, 0])
      ts += 1000 * 60 * 60 * 24
    }

    // retrieval error event into map
    const countingMap = new Map<EVENT_TYPE, IEventErrorLogGetResponse['data']>([
      [EVENT_TYPE.battery_disconnect, []],
      [EVENT_TYPE.input_error, []],
      [EVENT_TYPE.ups_disconnect, []],
    ])
    await this.setData(0, countingMap)

    // map to series data
    for (const evt of countingMap.get(EVENT_TYPE.battery_disconnect)!) {
      const idx = dateIdxArr.indexOf(this.$dayjs(evt.createTime).format('MM/DD'))
      const ptr = batteryErr.data[idx]
      this.$set(ptr, 1, +ptr[1] + 1)
    }
    for (const evt of countingMap.get(EVENT_TYPE.input_error)!) {
      const idx = dateIdxArr.indexOf(this.$dayjs(evt.createTime).format('MM/DD'))
      const ptr = inputErr.data[idx]
      this.$set(ptr, 1, +ptr[1] + 1)
    }
    for (const evt of countingMap.get(EVENT_TYPE.ups_disconnect)!) {
      const idx = dateIdxArr.indexOf(this.$dayjs(evt.createTime).format('MM/DD'))
      const ptr = upsErr.data[idx]
      this.$set(ptr, 1, +ptr[1] + 1)
    }

    await this.startTimer()
  }

  async startTimer() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    this.timer = setInterval(async () => {
      this.series = JSON.parse(JSON.stringify(seriesTemp))
      await this.setSeriesData()
    }, 60 * 1000)
  }

  async mounted() {
    await this.setSeriesData()
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
