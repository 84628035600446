
import { menuItem } from '@/store/app'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DrawerListItem from './DrawerListItem.vue'

@Component({
  components: {
    DrawerListItem,
  },
})
export default class DrawerList extends Vue {
  @Prop({ default: [] }) readonly items!: Array<menuItem>
  renderChildrenVNode(items: menuItem[]) {
    return items.map((item, idx) => {
      if (item.children !== undefined) {
        return (
          <v-list-group value={false}>
            <template slot='activator'>
              <v-list-item-icon>{item.icon && <v-icon>{item.icon}</v-icon>}</v-list-item-icon>
              <v-list-item-title>{item.title}</v-list-item-title>
            </template>
            {this.renderChildrenVNode(item.children)}
          </v-list-group>
        )
      }
      return <drawer-list-item key={idx} item={item} />
    })
  }

  render() {
    return (
      <v-list expand nav {...this.$attrs} on={this.$listeners}>
        {this.renderChildrenVNode(this.items)}
      </v-list>
    )
  }
}
