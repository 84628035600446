







































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import DefaultCombobox from '@/components/Combobox.vue'
import { IPutProjectRequest, IGetProjectItem, IGetProjectUserItem } from '@/types/api/project'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { IUserItem } from '@/types/api/user'
import { IRoleGroup } from '@/types/api/setting'
import { WritableState } from '@/util/annotations'
import LabelSelect from '@/components/LabelSelect.vue'
import Editor from '../component/editorJson.vue'

@Component({
  components: {
    Label,
    LabelTextField,
    LabelSelect,
    DefaultCombobox,
    Editor,
  },
})
export default class edit extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean
  @Prop({ type: Object }) tableItem!: IGetProjectItem
  @Prop({ type: Array }) projectUserList!: IGetProjectUserItem[]

  userStore = useUserStore()

  mapInitZoomList = ['6', '8', '10', '12', '14', '16', '18']

  intersectionFormatStatus = false
  batteryFormatStatus = false
  gatewayFormatStatus = false
  upsFormatStatus = false

  search = ''
  selectedUserList: IUserItem[] = []
  userIdList: string[] = JSON.parse(JSON.stringify(this.tableItem.userIdList))

  temp: IPutProjectRequest = {
    intersectionGroup: this.tableItem.intersectionGroup,
    batteryVendorGroup: this.tableItem.batteryVendorGroup,
    gatewayVendorGroup: this.tableItem.gatewayVendorGroup,
    upsVendorGroup: this.tableItem.upsVendorGroup,
    enabled: this.tableItem.enabled,
    mapInitLatitude: this.tableItem.mapInitLatitude,
    mapInitLongitude: this.tableItem.mapInitLongitude,
    mapInitZoom: this.tableItem.mapInitZoom,
    projectId: this.tableItem.projectId,
    projectLabel: this.tableItem.projectLabel,
    userIdList: this.tableItem.userIdList,
  }

  viewHeader = [
    { text: '使用者代號', value: 'userLabel', sortable: false, align: 'left' },
    { text: '角色', value: 'userRoleGroupName', sortable: false, align: 'left' },
    { text: '電子信箱', value: 'email', sortable: false, align: 'left' },
    { text: '帳號新增時間', value: 'createTime', align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  get SYSTEM_ADMIN() {
    return JSON.parse(localStorage.getItem('userRoleGroupName')!) === 'BMS_SYSTEM_ADMIN'
  }

  get tableList() {
    const roleGroup: IRoleGroup[] = JSON.parse(localStorage.getItem('roleGroup')!)
    return this.projectUserList
      .filter((i) => this.userIdList.includes(i.userId))
      .map((i) => ({
        ...i,
        createTime: i.createTime ? this.$dayjs(i.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
        updateTime: i.updateTime ? this.$dayjs(i.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
        userRoleGroupName: roleGroup.filter((x) => x.roleGroupName === i.userRoleGroupName)[0].roleGroupDesc,
      }))
  }

  get projectUserSelectionList() {
    const tableUserIDList = this.tableList.map((i) => i.userId)
    return this.projectUserList.filter((i) => !tableUserIDList.includes(i.userId))
  }

  setInputValue(
    value: { [key: string]: string | number },
    errorStatus: boolean,
    isValidKeyProperty: boolean,
    noJsonValueProperty: boolean,
    formatStatusProperty: string,
    tempProperty: string
  ) {
    this[formatStatusProperty] = errorStatus || isValidKeyProperty || noJsonValueProperty

    if (!this[formatStatusProperty]) {
      this.temp[tempProperty] = JSON.stringify(value)
    }
  }

  onError(errorStatus: boolean, formatStatusProperty: string) {
    this[formatStatusProperty] = errorStatus
  }

  inputIntersectionValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'intersectionFormatStatus', 'intersectionGroup')
  }

  intersectionOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'intersectionFormatStatus')
  }

  inputBatteryValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'batteryFormatStatus', 'batteryVendorGroup')
  }

  batteryOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'batteryFormatStatus')
  }

  inputGatewayValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'gatewayFormatStatus', 'gatewayVendorGroup')
  }

  gatewayOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'gatewayFormatStatus')
  }

  inputUpsValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'upsFormatStatus', 'upsVendorGroup')
  }

  upsOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'upsFormatStatus')
  }

  async checkUserEditData() {
    let status = false
    if (this.tableItem.projectLabel !== this.temp.projectLabel || this.tableItem.userIdList !== this.temp.userIdList) {
      status = true
    }

    const newArr = this.tableItem.userIdList.filter((i) => !this.userIdList.includes(i))

    if (newArr.length > 0 || status) {
      const { isConfirmed } = await this.$swal.fire({
        text: `專案已有編輯，是否要儲存變更`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
      })

      if (isConfirmed) {
        await this.submitEditProject()
      }
    }

    await this.$emit('refreshList')
  }

  async changeProjectStatus() {
    if (JSON.parse(localStorage.getItem('projectId')!) === this.temp.projectId) {
      await this.$swal.fire({
        title: `禁止停用當前登入專案`,
        icon: 'warning',
        showCloseButton: true,
      })

      return
    }
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要${this.temp.enabled ? '停用' : '啟用'} ?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      try {
        this.isPageLoading = true
        this.temp.enabled = !this.temp.enabled
        await this.$api.project.editProject(this.temp)
        await this.$swal.fire({
          title: `${this.temp.enabled ? '已啟用' : '已停用'}`,
          icon: 'success',
          toast: true,
          position: 'top',
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        })
        await this.userStore.getProjectList()
        await this.userStore.getProjectUserList()
      } catch (e) {
        console.error(e)
      } finally {
        this.isPageLoading = false
      }
    }
  }

  async addNewProjectUser() {
    const selectedUserIDList = this.selectedUserList.map((i) => i.userId)
    this.userIdList = [...this.userIdList, ...selectedUserIDList]
    this.temp.userIdList = this.userIdList
    this.selectedUserList = []
  }

  async handleDeleteUser(item: IGetProjectUserItem) {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定要移除 ${item.userLabel} ?`,
      icon: 'warning',
      showCancelButton: true,
    })
    if (isConfirmed) {
      const getItem = this.userIdList.findIndex((user) => user === item.userId)
      this.userIdList.splice(getItem, 1)
      this.temp.userIdList = this.userIdList
    }
  }

  async cancelEdit() {
    const { isConfirmed } = await this.$swal.fire({
      text: `確定取消編輯 ?`,
      icon: 'warning',
      showCancelButton: true,
    })

    if (isConfirmed) {
      await this.$emit('refreshList')
    }
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()

    if (!validateStatus || this.intersectionFormatStatus || this.batteryFormatStatus || this.gatewayFormatStatus || this.upsFormatStatus) {
      return
    }

    await this.submitEditProject()
  }

  async submitEditProject() {
    try {
      this.isPageLoading = true
      await this.$api.project.editProject(this.temp)
      await this.$swal.fire({
        title: `編輯成功`,
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      this.temp = {
        batteryVendorGroup: '',
        enabled: true,
        gatewayVendorGroup: '',
        intersectionGroup: '',
        mapInitLatitude: '',
        mapInitLongitude: '',
        mapInitZoom: '',
        projectId: '',
        projectLabel: '',
        upsVendorGroup: '',
        userIdList: [''],
      }
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
      await this.userStore.getGroupParams(JSON.parse(localStorage.getItem('projectId')!))
      await this.$emit('refreshList')
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }
}
