

































import { Component, Vue } from 'vue-property-decorator'
import DevicesMgmtCreateIntersection from './Views/Intersection/DevicesMgmtCreateIntersection.vue'
import DevicesMgmtCreateBattery from './Views/Battery/DevicesMgmtCreateBattery.vue'
import DevicesMgmtCreateGateway from './Views/Gateway/DevicesMgmtCreateGateway.vue'
import DevicesMgmtCreateUps from './Views/UPS/DevicesMgmtCreateUps.vue'

@Component({
  components: {
    DevicesMgmtCreateIntersection,
    DevicesMgmtCreateBattery,
    DevicesMgmtCreateGateway,
    DevicesMgmtCreateUps,
  },
})
export default class DevicesMgmtCreate extends Vue {
  tabIdx = 0
}
