/**
 * @see https://axiosIns-http.com/zh/docs/interceptors
 */

import { AxiosInstance, AxiosResponse } from 'axios'

export class ResponseInterceptors {
  onFullFilled?: (value: AxiosResponse<any, any>) => any | Promise<void>
  onReject?: (error: any) => any | Promise<any>
  axiosIns: AxiosInstance
  ptr?: number

  constructor(
    axiosIns: AxiosInstance,
    {
      onFullFilled,
      onReject,
    }: { onFullFilled?: ResponseInterceptors['onFullFilled']; onReject?: ResponseInterceptors['onReject'] }
  ) {
    this.onFullFilled = onFullFilled
    this.onReject = onReject
    this.axiosIns = axiosIns
  }

  use() {
    this.ptr = this.axiosIns.interceptors.response.use(this.onFullFilled, this.onReject)
  }

  pause() {
    if (this.ptr === undefined) {
      return
    }
    this.axiosIns.interceptors.response.eject(this.ptr)
  }
}
