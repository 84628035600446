













































import { Component, Vue } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import OverviewPagedWorkingIntersection from './OverviewPagedWorkingIntersection.vue'
import { useIntersectionStore } from '@/store/intersection'
import { INTERSECTION_STATUS } from '@/constants/intersection'
import OverviewChartUPS from './OverviewChartUPS.vue'
import OverviewChartErr from './OverviewChartErr.vue'
import OverviewMap from './OverviewMap.vue'
import OverviewMonthly from './OverviewMonthly.vue'
import OverviewDevicesDrawer from './OverviewDevicesDrawer.vue'
import OverviewDevices from './OverviewDevices.vue'
import InputErrorLog from './table/InputErrorLog.vue'
import duration from 'dayjs/plugin/duration'
import { TIME_PLACEHOLDER } from '@/constants/constants'

@Component({
  components: {
    InputErrorLog,
    OverviewChartUPS,
    OverviewChartErr,
    OverviewMonthly,
    OverviewMap,
    OverviewPagedWorkingIntersection,
    OverviewDevices,
  },
})
export default class Overview extends Vue {
  appStore = useAppStore()
  intersectionStore = useIntersectionStore()

  get inputErrorList() {
    return this.intersectionStore.inputErrorList
  }

  get workLogList() {
    return this.intersectionStore.workLogList
  }

  get errEventLogs() {
    return this.intersectionStore.errEventLogs
  }

  get batteryLevelList() {
    return this.intersectionStore.batteryLevelList
  }

  // 地圖綁定
  get mapActivedIntersectionModel() {
    return this.intersectionStore.intersectionIdActived
  }

  set mapActivedIntersectionModel(intersectionId: string) {
    this.intersectionStore.setActivedIntersectionId(intersectionId)
  }

  /* 全部路口 */
  get intersectionList() {
    return this.intersectionStore.intersectionItems
  }

  /* 移動式供電車總數 */
  get mobileCarCnt() {
    return this.intersectionStore.intersectionItems.filter((i) => i.intersectionType === 'MOBILE')
  }

  /* 移動式供電車使用中數量 */
  get mobileCarUsedCnt() {
    return this.mobileCarCnt.filter((i) => i.intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL)
  }

  /* 已安裝路口 */
  get checkInstallIntersections() {
    return this.intersectionList.filter((i) => i.intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL)
  }

  /* 市電異常路口 */
  get workingIntersections() {
    return this.intersectionList.filter(
      (i) => i.intersectionStatus === INTERSECTION_STATUS.UPS_ACTIVE || i.intersectionStatus === INTERSECTION_STATUS.LOW_BATTERY_UPS_ACTIVE
    )
  }

  /* 異常路口 */
  get errorIntersections() {
    return this.intersectionList.filter(
      (i) => i.intersectionStatus === INTERSECTION_STATUS.ABNORMAL || i.intersectionStatus === INTERSECTION_STATUS.LONG_TIME_NO_DATA
    )
  }

  onDeviceStatusCardClick(title: '已安裝總數' | '市電異常路口' | '連線異常路口' | '移動式供電車使用中數量/總數') {
    let intersections = this.checkInstallIntersections

    if (title === '市電異常路口') {
      intersections = this.workingIntersections
    } else if (title === '連線異常路口') {
      intersections = this.errorIntersections
    } else if (title === '移動式供電車使用中數量/總數') {
      intersections = this.mobileCarUsedCnt
    }

    if (intersections.length === 0) return

    this.$drawer.open(
      <OverviewDevicesDrawer
        intersectionList={intersections}
        on={{
          onClick: (iId: string, intersectionType: string) => {
            this.$router.push({
              path: '/map-mgmt',
              query: {
                id: iId,
                intersectionType,
              },
            })
            this.$drawer.close()
          },
        }}
      />
    )
  }

  get intersectionUpsEnableTimeActived() {
    if (typeof this.intersectionStore.intersectionItemActived?.upsEnableTime !== 'number') {
      return TIME_PLACEHOLDER
    }
    return this.$dayjs(this.intersectionStore.intersectionItemActived?.upsEnableTime).format('HH:mm:ss')
  }

  get intersectionUpsEnableDurationActived() {
    const { updateTime, upsEnableTime } = this.intersectionStore.intersectionItemActived || {}
    if (typeof updateTime !== 'number') {
      return TIME_PLACEHOLDER
    }
    if (typeof upsEnableTime !== 'number') {
      return TIME_PLACEHOLDER
    }

    this.$dayjs.extend(duration)

    return this.$dayjs.duration(Math.abs(upsEnableTime - updateTime), 'milliseconds').format('HH:mm:ss', { trim: false })
  }

  async mounted() {
    /** 測試狀態變更
    setTimeout(() => {
      this.intersectionStore.intersectionItems.find(
        (x) => x.intersectionId === '6722ec42-c986-4895-aea7-c247d3a5d625'
      )!.intersectionStatus = INTERSECTION_STATUS.LOW_BATTERY_UPS_ACTIVE

      setTimeout(() => {
        this.intersectionStore.intersectionItems.find(
          (x) => x.intersectionId === '6722ec42-c986-4895-aea7-c247d3a5d625'
        )!.intersectionStatus = INTERSECTION_STATUS.LONG_TIME_NO_DATA
      }, 10000)
    }, 10000)
    */

    await this.intersectionStore.getInputErrorLog()
    await this.intersectionStore.getWorkLogList()
    await this.intersectionStore.getErrEventLogList()
    await this.intersectionStore.getBatteryLevelList()
  }
}
