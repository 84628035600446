































import { Component, Vue } from 'vue-property-decorator'
import { IItemLog } from '@/types/api/admin'
import { WritableState } from '@/util/annotations'
import { useAppStore } from '@/store/app'
import TimePicker from '@/components/DateTimePicker.vue'

@Component({
  components: {
    TimePicker,
  },
})
export default class index extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  dateRangeValue: number[] = []
  startTimestamp = 0
  endTimestamp = 0

  itemView: IItemLog[] = []
  viewHeader = [
    { text: '使用者 ID', value: 'userId', sortable: false, align: 'center' },
    { text: '使用者 Email', value: 'email', sortable: false, align: 'center', width: '10%' },
    { text: '裝置 IP', value: 'clientIp', sortable: false, align: 'center' },
    { text: '登入時間', value: 'createTime', sortable: false, align: 'center' },
    { text: '錯誤代碼', value: 'errorCode', sortable: false, align: 'center' },
    { text: '錯誤訊息', value: 'errorMessage', sortable: false, align: 'center' },
  ]

  get formatItemView() {
    return this.itemView.map((item) => ({
      ...item,
      createTime: item.createTime ? this.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
    }))
  }

  async handleSearch() {
    const firstTime = new Date(new Date(this.dateRangeValue[0]))
    const secondTime = new Date(new Date(this.dateRangeValue[1]))
    const secondTimeEndValue = this.$dayjs(secondTime).endOf('day').valueOf()

    const dayDiff = (+secondTime - +firstTime) / 86400000
    const maxTimeRange = (31 * 24 * 60 * 60 * 1000) / 86400000

    if (dayDiff < 0 || dayDiff > maxTimeRange) {
      this.$swal.fire({
        title: '日期範圍最多 31 天',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    this.startTimestamp = +firstTime
    this.endTimestamp = +secondTimeEndValue

    await this.getUserLog()
  }

  async getUserLog(page = 0) {
    if (page === 0) {
      this.itemView.splice(0)
    }

    try {
      this.isPageLoading = true

      const { data } = await this.$api.admin.getUserLoginLog({
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
        sort: 'DESC',
        pageSize: 100,
        page,
      })

      if (data) {
        const filteredArray = data.data.filter((item) => !this.itemView.some((exitingItem) => exitingItem.userId === item.userId))
        this.itemView = [...this.itemView, ...filteredArray]

        if (data.hasNext) {
          await this.getUserLog(page + 1)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.startTimestamp = +this.$dayjs().startOf('month').valueOf()
    this.endTimestamp = +this.$dayjs().endOf('month').valueOf()
    this.dateRangeValue = [this.startTimestamp, this.endTimestamp]

    await this.getUserLog()
  }
}
