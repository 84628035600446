






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ShadowCard extends Vue {
  @Prop({ type: Boolean, default: false }) isOverlay!: boolean
  @Prop({ type: Boolean, default: false }) isPlain!: boolean

  get classList() {
    if (this.isPlain) {
      if (this.isOverlay) {
        return `card-plain card-plain--disabled`
      }
      return `card-plain`
    }
    if (this.isOverlay) {
      return `card card--disabled`
    }
    return `card`
  }
}
