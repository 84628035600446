




















































import { Component, Vue } from 'vue-property-decorator'
import { useAppStore } from '@/store/app'
import { WritableState } from '@/util/annotations'
import DateTimePicker from '@/components/DateTimePicker.vue'
import type { IEventErrorLogOverOneHourResponse, IEventErrorLogDetailResponse } from '@/types/api/event'

const downloadCsv = require('vue-json-csv')

@Component({ components: { downloadCsv, DateTimePicker } })
export default class EventAndAlarmInfo extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  dateRangeValue: number[] = []
  queryUuid = ''

  tableHeader = [
    { text: '項次', value: 'index', align: 'left' },
    { text: '路口名稱', value: 'intersectionLabel', sortable: false, align: 'left' },
    { text: '行政區/移動式供電車', value: 'intersectionGroupLabel', sortable: false, align: 'left' },
    { text: '開始時間', value: 'startTime', sortable: false, align: 'left' },
    { text: '結束時間', value: 'endTime', sortable: false, align: 'left' },
    { text: '持續時間', value: 'totalTime', sortable: false, align: 'left' },
    { text: '期間平均功率', value: 'avgUpsOutput', sortable: false, align: 'left' },
    { text: '換電次數', value: 'changeTimes', sortable: false, align: 'left' },
    { text: '消耗電量', value: 'totalPower', sortable: false, align: 'left' },
    { text: '檔案下載', value: 'handler', sortable: false, align: 'center' },
  ]

  eventErrorLogOverOneHourList: IEventErrorLogOverOneHourResponse[] = []
  eventErrorLogDetailList: IEventErrorLogDetailResponse['data'] = []

  CSVname = ''

  get tableItems() {
    return this.eventErrorLogOverOneHourList
      .map((i, index) => ({
        ...i,
        index: index + 1,
        totalTime: this.calculateRangeTime(i.startTime, i.endTime),
        totalPower: i.totalPower ? `${Math.round(i.totalPower)} %` : '-',
        avgUpsOutput: i.avgUpsOutput ? i.avgUpsOutput : '-',
        sortTime: +(i.endTime - i.startTime),
      }))
      .sort((a, b) => b.sortTime - a.sortTime)
  }

  get CSVTable() {
    return this.eventErrorLogDetailList.map((i, index) => ({
      項次: index + 1,
      路口名稱: i.intersectionLabel,
      來源傳送時間: i.dataTime ? this.$dayjs(i.dataTime).format('YYYY年 MM月 DD日 HH時 mm分 ss秒') : '---/--/-- --:--:--',
      '電池A電壓(V)': i.batteryAVoltage ? i.batteryAVoltage : '-',
      '電池B電壓(V)': i.batteryBVoltage ? i.batteryBVoltage : '-',
      '市電電壓(V)': i.inputVoltage ? i.inputVoltage : '-',
      '電池A電量(%)': i.batteryALevel ? i.batteryALevel : '-',
      '電池B電量(%)': i.batteryBLevel ? i.batteryBLevel : '-',
      '路口電量(%)': i.avgBatteryLevel ? i.avgBatteryLevel : '-',
      '電池A電流(A)': i.batteryACurrent ? i.batteryACurrent : '-',
      '電池B電流(A)': i.batteryBCurrent ? i.batteryBCurrent : '-',
      'UPS溫度(℃)': i.upsTemperature ? i.upsTemperature : '-',
      '電池A溫度(℃)': i.batteryATemperature ? i.batteryATemperature : '-',
      '電池B溫度(℃)': i.batteryBTemperature ? i.batteryBTemperature : '-',
      'UPS功率(W)': i.upsOutput ? i.upsOutput : '-',
      '市電頻率(Hz)': i.inputFrequency ? i.inputFrequency : '-',
    }))
  }

  disableDate(date: Date) {
    const currentMonth = this.$dayjs().startOf('month').format('YYYY-MM')
    const selectedMonth = this.$dayjs(date).startOf('month').format('YYYY-MM')

    return selectedMonth > currentMonth
  }

  calculateRangeTime(startTime: number, endTime: number) {
    if (endTime > startTime) {
      const diffTime = endTime - startTime

      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diffTime / (1000 * 60)) % 60)

      return days > 0 ? `${days}D : ${hours}H : ${minutes}M` : `${hours}H : ${minutes}M`
    }
  }

  async handleSearch() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus) return

    await this.getEventErrorLogOverOneHourList()
  }

  async getEventErrorLogOverOneHourList() {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventErrorLogOverOneHour({
        projectId: JSON.parse(localStorage.getItem('projectId')!),
        startTime: this.$dayjs(this.dateRangeValue[0]).startOf('month').valueOf(),
        endTime: this.$dayjs(this.dateRangeValue[1]).endOf('month').valueOf(),
      })

      this.eventErrorLogOverOneHourList = data as IEventErrorLogOverOneHourResponse[]
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  checkTelemetry(count: number) {
    if (count === 0) {
      this.$swal.fire({
        title: `${this.CSVname}尚無資料`,
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return true
    }
    return false
  }

  async getEventErrorLogDetailList(page = 0) {
    if (page === 0) {
      this.eventErrorLogDetailList = []
    }

    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventErrorLogDetail({
        queryUuid: this.queryUuid,
        sort: 'DESC',
        pageSize: 100,
        page,
      })

      if (!data) throw new TypeError()
      if (this.checkTelemetry(data.totalElements)) return

      this.eventErrorLogDetailList = [...this.eventErrorLogDetailList, ...data.data]

      if (data.hasNext) {
        await this.getEventErrorLogDetailList(page + 1)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async handleDownload(item: IEventErrorLogOverOneHourResponse) {
    this.queryUuid = item.queryUuid
    this.CSVname = `${this.$dayjs(item.startTime).format('YYYY年 MM月')} ${item.intersectionLabel} 長時斷電資訊`
    await this.getEventErrorLogDetailList(0)
    this.doDownLoad()
  }

  doDownLoad() {
    const downloadCsv = document.getElementById('eventErrorLogDetailDownloadCsv')
    downloadCsv && downloadCsv.click()
  }
}
