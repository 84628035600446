

































































import { Component, Vue } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import Combobox from '@/components/Combobox.vue'
import { useAppStore } from '@/store/app'
import { ICreateProjectUser, IGetProjectItem, IGetProjectUserItem } from '@/types/api/project'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    LabelTextField,
    Label,
    Combobox,
  },
})
export default class add extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  temp: ICreateProjectUser = {
    userLabel: '',
    userRoleGroupName: '',
    email: '',
    projectIdList: [],
    defaultProjectId: '',
  }

  selectedProjectList: IGetProjectItem[] = []
  projectList: IGetProjectItem[] = []
  projectUserList: IGetProjectUserItem[] = []
  userRoleGroupList = JSON.parse(localStorage.getItem('roleGroup')!)

  filterProjectIdList() {
    this.temp.projectIdList = this.selectedProjectList.map((i) => i.projectId)
    this.temp.defaultProjectId = this.selectedProjectList.map((i) => i.projectId)[0]
  }

  async checkRepeatEmail() {
    let status = false
    const newArr = this.projectUserList.filter((i) => i.email === this.temp.email)
    if (newArr.length > 0) {
      status = true
      await this.$swal.fire({
        title: 'Email 已重複',
        icon: 'warning',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }

    return status
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!validateStatus || (await this.checkRepeatEmail())) return

    if (this.temp.defaultProjectId === null) {
      this.temp.defaultProjectId = this.temp.projectIdList[0]
    }

    try {
      this.isPageLoading = true
      await this.$api.project.createProjectUser(this.temp)
      await this.$api.user.updateDefaultProject({ defaultProjectId: this.temp.defaultProjectId })
      await this.$swal.fire({
        title: '新增成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })

      this.selectedProjectList = []
      this.temp = {
        userLabel: '',
        userRoleGroupName: '',
        email: '',
        projectIdList: [],
        defaultProjectId: '',
      }
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectList() {
    try {
      this.isPageLoading = false
      const { data } = await this.$api.project.getProjectList()
      this.projectList = data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectUserList() {
    try {
      this.isPageLoading = false
      const data = await this.$api.project.gteProjectUserList()
      this.projectUserList = data as []
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getProjectList()
    await this.getProjectUserList()
  }
}
