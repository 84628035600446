import { GMAP_API_KEY } from '@/constants/constants'
import { Loader } from '@googlemaps/js-api-loader'

export default class GMap extends Loader {
  static MarkerLibrary: google.maps.MarkerLibrary
  static MapsLibrary: google.maps.MapsLibrary
  static CoreLibrary: google.maps.CoreLibrary
  static isLibLoaded = false
  static isMapLoaded = false

  async init() {
    GMap.MarkerLibrary = await this.importLibrary('marker')
    GMap.MapsLibrary = await this.importLibrary('maps')
    GMap.CoreLibrary = await this.importLibrary('core')
    GMap.isLibLoaded = true
    return GMap
  }

  static mapIdMap: Map<string, google.maps.Map> = new Map()

  static async createMap(mapDiv: HTMLElement, opts?: google.maps.MapOptions) {
    GMap.isMapLoaded = false
    if (!GMap.isLibLoaded) {
      await new GMap({
        apiKey: GMAP_API_KEY!,
        version: 'weekly',
      }).init()
    }
    const gmapIns = new GMap.MapsLibrary.Map(mapDiv, opts)
    GMap.mapIdMap.set(opts!.mapId!, gmapIns)
    await new Promise<void>((resolve) => gmapIns.addListener('tilesloaded', () => resolve()))
    GMap.isMapLoaded = true
    return gmapIns
  }

  static get isLoading() {
    return !GMap.isMapLoaded || !GMap.isLibLoaded
  }

  static flyTo(map: google.maps.Map, latLngLiteral: google.maps.LatLngLiteral, zoom: number) {
    map.panTo(latLngLiteral)
    map.setZoom(zoom)
  }
}
