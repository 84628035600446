











import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class SearchResult extends Vue {
  @Prop({ type: String }) search!: string
  @Prop({ type: Number }) searchResultNum!: number

  @Emit('clearSearch')
  clearSearch() {}
}
