export enum EVENT_TYPE {
  'battery_change_end' = 'battery_change_end',
  'battery_change_start' = 'battery_change_start',
  'battery_connect_reset' = 'battery_connect_reset',
  'battery_disconnect' = 'battery_disconnect',
  'battery_level_15_percent' = 'battery_level_15_percent',
  'battery_level_20_percent' = 'battery_level_20_percent',
  'battery_level_25_percent' = 'battery_level_25_percent',
  'battery_level_30_percent' = 'battery_level_30_percent',
  'battery_level_35_percent' = 'battery_level_35_percent',
  'battery_level_40_percent' = 'battery_level_40_percent',
  'battery_level_50_percent' = 'battery_level_50_percent',
  'battery_remove' = 'battery_remove',
  'battery_temperature_error' = 'battery_temperature_error',
  'over_n_minutes_no_response' = 'over_n_minutes_no_response',
  'ups_disable' = 'ups_disable',
  'ups_temperature_error' = 'ups_temperature_error',
  'input_reset' = 'input_reset',
  'input_error' = 'input_error',
  'ups_disconnect' = 'ups_disconnect',
  'ups_enable' = 'ups_enable',
  'ups_overload' = 'ups_overload',

}
