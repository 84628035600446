
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VSelect } from 'vuetify/lib'

@Component
export default class DefaultSelect extends Vue {
  @Prop({ type: String }) title!: string
  @Prop({ type: Boolean }) depressed!: boolean

  render() {
    return (
      <div>
        <VSelect props={this.$attrs} attr={this.$attrs} on={this.$listeners} class={{ depressed: this.depressed }} />
      </div>
    )
  }
}
