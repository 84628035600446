import { WorkSheet, WorkBook } from 'xlsx'
type XlsxModuleType = typeof import('xlsx')

export const parseJwt = <T = any>(token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((cipher) => '%' + ('00' + cipher.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )
  return JSON.parse(jsonPayload) as T
}

export class Exporter {
  XLSX?: XlsxModuleType
  XLSX_STYLE: any
  sheet?: WorkSheet
  book?: WorkBook

  async readAsOnePage(
    name = `page-1-${Date.now()}`,
    aoa: any[][],
    merges: Array<{ s: { r: number; c: number }; e: { r: number; c: number } }>
  ) {
    if (!this.XLSX || !this.XLSX_STYLE) {
      this.XLSX = (await import('xlsx')) as XlsxModuleType
      this.XLSX_STYLE = require('xlsx-style')
    }

    this.book = this.XLSX.utils.book_new()
    this.sheet = this.XLSX.utils.aoa_to_sheet(aoa)
    if (merges) {
      this.sheet['!merges'] = merges
    }
    this.XLSX.utils.book_append_sheet(this.book, this.sheet, `${name}`)

    return this
  }

  s2ab(s: string) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  download(name = `test-${Date.now()}`) {
    const str = this.XLSX_STYLE.write(this.book, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    })

    const bin = this.s2ab(str)

    const blob = new Blob([bin], { type: 'application/octet-stream' })

    const a = document.createElement('a')
    a.download = `${name}.xlsx`
    a.href = URL.createObjectURL(blob)
    a.click()
    URL.revokeObjectURL(a.href)
  }
}

export class XLSXReader {
  static XLSX: XlsxModuleType
  static reader = new FileReader()

  static async read(f: File) {
    if (this.XLSX === undefined) {
      this.XLSX = await import('xlsx')
    }

    this.reader.readAsBinaryString(f)

    const result = await new Promise((resolve) => {
      this.reader.onload = (e) => resolve(e.target?.result)
    })

    const workbook = this.XLSX.read(result, {
      type: 'binary',
    })
    return this.parse(workbook)
  }

  static parse(workbook: WorkBook) {
    const result: { [key: string]: any } = {}
    workbook.SheetNames.forEach((sheetName: string) => {
      const roa = XLSXReader.XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
        raw: false,
      })
      if (roa.length) {
        result[sheetName] = roa
      }
    })
    return result
  }
}

export const getComputedColor = (str: string) => {
  if (/^rgb|^#/g.test(str)) {
    return str.trim()
  }
  if (/^-/.test(str)) {
    return getComputedStyle(document.body).getPropertyValue(str).trim()
  }
  if (/^var/.test(str)) {
    str = str.replace(/^var\(/, '').replace(')', '')
    return getComputedStyle(document.body).getPropertyValue(str).trim()
  }
  return str
}

export const createColorScale = (domain: any, range: any) => {
  const [domainMin, domainMax] = domain;
  const [rangeMin, rangeMax] = range;
  return function (value: number) {
    const t = (value - domainMin) / (domainMax - domainMin);
    const r = Math.round(rangeMin[0] + t * (rangeMax[0] - rangeMin[0]));
    const g = Math.round(rangeMin[1] + t * (rangeMax[1] - rangeMin[1]));
    const b = Math.round(rangeMin[2] + t * (rangeMax[2] - rangeMin[2]));
    return `rgb(${r},${g},${b})`;
  };
}
