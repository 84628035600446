

















































import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import { IGetProjectItem, IGetProjectUserItem } from '@/types/api/project'
import EditProject from './edit.vue'
import { useAppStore } from '@/store/app'
import { WritableState } from '@/util/annotations'

@Component({
  components: {
    DataTable,
    EditProject,
  },
})
export default class tableList extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  showTableStatus = true
  search = ''
  tableItem = {}

  projectList: IGetProjectItem[] = []
  projectUserList: IGetProjectUserItem[] = []
  viewHeader = [
    { text: '編號', value: 'projectNumber', align: 'left' },
    { text: '專案名稱', value: 'projectLabel', sortable: false, align: 'left' },
    { text: '專案成員(人數)', value: 'userCount', sortable: false, align: 'left' },
    { text: '建檔時間', value: 'createTime', align: 'left' },
    { text: '', value: 'handler', sortable: false, align: 'end' },
  ]

  get tableList() {
    return this.projectList.map((i, index) => ({
      ...i,
      projectNumber: index + 1,
      userCount: i.userIdList.length,
      createTime: i.createTime ? this.$dayjs(i.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--',
    }))
  }

  async refreshList() {
    this.showTableStatus = true
    await this.getProjectList()
  }

  async handleEdit(item: IGetProjectItem) {
    this.showTableStatus = false
    this.tableItem = JSON.parse(JSON.stringify(item))
  }

  async handleDelete(item: IGetProjectItem) {
    try {
      this.isPageLoading = true
      await this.$api.project.deleteProject({ projectId: item.projectId })
      await this.getProjectList()
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectList() {
    this.isPageLoading = true
    try {
      const { data } = await this.$api.project.getProjectList()
      this.projectList = data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectUserList() {
    this.isPageLoading = true
    try {
      const data = await this.$api.project.gteProjectUserList()

      this.projectUserList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getProjectList()
    await this.getProjectUserList()
  }
}
