var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"d-flex justify-end align-start"},[_c('div',{staticClass:"d-flex align-baseline mr-4"},[_c('span',{staticClass:"text-theme-gray mr-4"},[_vm._v("資料時間")]),_c('ValidationProvider',{ref:"form",attrs:{"name":"資料時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"range":true,"label":'請選擇資料時間',"format":"YYYY-MM","type":"month","value-type":"timestamp","disabled-date":_vm.disableDate},model:{value:(_vm.dateRangeValue),callback:function ($$v) {_vm.dateRangeValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dateRangeValue"}}),_c('div',{staticClass:"errorAlert"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-btn',{staticClass:"text__size-14 px-10 mb-1",attrs:{"color":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("搜尋")])],1)]),_c('v-data-table',{staticClass:"mt-md-8 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"footer-props":{ 'items-per-page-options': [15, 50, 100, -1] }},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startTime ? _vm.$dayjs(item.startTime).format('YYYY/MM/DD HH:mm:ss') : '----/--')+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startTime ? _vm.$dayjs(item.endTime).format('YYYY/MM/DD HH:mm:ss') : '----/--')+" ")]}},{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-theme-blue download",on:{"click":function($event){return _vm.handleDownload(item)}}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-download")])],1)]}}],null,true)}),_c('downloadCsv',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"eventErrorLogDetailDownloadCsv","bom":true,"data":_vm.CSVTable,"name":(_vm.CSVname + ".csv")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }