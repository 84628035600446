
import { createChart } from '@/util/echartUtil'
import { getComputedColor } from '@/util/helpers'
import { EChartsOption } from 'echarts'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DashboardPieChart extends Vue {
  @Prop({ type: Number, default: 30 }) value!: number
  @Prop({ type: Number, default: 200 }) maxValue!: number
  @Prop({ type: Number, default: 0 }) minValue!: number
  @Prop({ type: Number, default: 200 }) height!: number

  @Prop({ type: String, default: '--v-primary-base' }) activeColor!: string
  @Prop({ type: String, default: '--v-secondary-base' }) baseColor!: string
  @Prop({ type: String, default: 'title' }) title!: string
  @Prop({ type: String, default: '%' }) unit!: string
  @Prop({ type: Number, default: 0 }) fixDigit!: number

  @Watch('value', { immediate: true })
  onValueChange() {
    ;(this.opts.series as any)[0].data = [this.value || 0]
  }

  render() {
    return (
      <div style='width:100%;'>
        <h3 class='mt-3' style='color: var(--v-secondary-lighten2);'>
          {this.title}
        </h3>
        <div class='pie'>
          <strong class='pie__value'>
            {this.value ? this.fixDigit || this.value.toFixed(this.fixDigit) : 0}
            <small>{this.unit}</small>
          </strong>
          <div ref='chart' style={{ height: `${this.height}px` }} />
        </div>
      </div>
    )
  }

  opts: EChartsOption = {
    emphasis: {
      disabled: true,
    },
    series: [
      {
        type: 'gauge',
        progress: {
          show: true,
          width: 20,
          itemStyle: {
            color: getComputedColor(this.activeColor),
          },
        },
        axisLine: {
          lineStyle: {
            color: [[1, getComputedColor(this.baseColor)]],
            width: 20,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        pointer: {
          show: false,
        },
        detail: {
          show: false,
        },
        startAngle: 0,
        endAngle: 360,
        data: [this.value],
        max: this.maxValue,
        min: this.minValue,
      },
    ],
    color: [getComputedColor(this.activeColor), getComputedColor(this.baseColor)],
  }

  mounted() {
    this.$nextTick(async () => {
      const chartRef = this.$refs.chart
      await createChart.call(this, chartRef, this.opts)
    })
  }
}
