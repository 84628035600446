









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Label from '@/components/Label.vue'
import { IDeviceInstallFile } from '@/types/device'

@Component({ components: { Label } })
export default class FixMgmtInstallThumbnails extends Vue {
  @Prop({ type: Array }) value!: IDeviceInstallFile[]
  @Prop({ type: Number, default: 0 }) limit!: number

  files: IDeviceInstallFile[] = []
  fileInputRef!: HTMLInputElement
  fileReader = new FileReader()
  previewIdx = -1

  get previewDialogVisibility() {
    return this.previewIdx > -1
  }

  set previewDialogVisibility(v: boolean | number) {
    if (typeof v === 'number') {
      this.previewIdx = v
    } else if (v === false) {
      this.previewIdx = -1
    }
  }

  get previewDialogData() {
    return this.files[this.previewIdx] || this.value[this.previewIdx]
  }

  get filesPreviewView() {
    if (this.limit === 0) {
      return [...this.files, undefined]
    }
    const len = this.files.length
    const paddingLen = Math.abs(len - this.limit)

    return [...this.files, ...Array.from(Array(paddingLen))]
  }

  @Watch('files', { deep: true })
  onFilesChange(files: IDeviceInstallFile[]) {
    this.$emit('change', files)
    console.log('[ emit onFilesChange]', files)
  }

  compressAndEncode(file: File, maxWidth: number, maxHeight: number, quality: number) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

      // 設置圖片加載完成後的回調函數
      image.onload = () => {
        let width = image.width
        let height = image.height

        // 如果圖片的尺寸超過了指定的最大值，就進行縮放
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }

        // 將圖片繪製到 canvas 上，並進行壓縮
        canvas.width = width
        canvas.height = height
        ctx.drawImage(image, 0, 0, width, height)
        const dataURL: string = canvas.toDataURL(file.type, quality)

        // 將 Base64 編碼的字符串返回
        resolve(dataURL)
      }

      // 設置圖片加載失敗時的回調函數
      image.onerror = () => {
        reject(new Error('圖片加載失敗'))
      }

      // 設置圖片的 URL，觸發圖片加載
      image.src = URL.createObjectURL(file)
    })
  }

  onFileUpload(idx: number) {
    if (this.files.length === 4) return
    const ref = this.$refs.fileUploader
    if (ref instanceof HTMLInputElement && this.fileInputRef === undefined) {
      this.fileInputRef = ref
    }
    this.fileInputRef.click()
  }

  async onFileChange() {
    if (this.fileInputRef.files === null) return

    const [file] = this.fileInputRef.files
    const base64Str = (await this.compressAndEncode(file, 800, 600, 0.7)) as string

    this.fileInputRef.value = null as any
    this.files.push({ file, name: file.name, base64: base64Str, url: base64Str, size: file.size })
  }

  onRemoveFile(idx: number) {
    console.log('[onRemoveFile - idx]', idx)
    this.files.splice(idx, 1)
  }

  async retrieveBase64StrFromFile(file: File) {
    this.fileReader.readAsDataURL(file)
    const result = await new Promise((resolve) => {
      this.fileReader.onload = (e) => resolve(e.target?.result)
    })
    return result as string
  }
}
