






















































































import { Component, Vue } from 'vue-property-decorator'
import LabelTextField from '@/components/LabelTextField.vue'
import Label from '@/components/Label.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import { IPostProjectRequest } from '@/types/api/project'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { WritableState } from '@/util/annotations'
import Editor from '../component/editorJson.vue'

const formateTemp = {
  batteryVendorGroup: '', // 限制 key 小寫
  gatewayVendorGroup: '', // 限制 key 小寫
  intersectionGroup: '', // 限制 key 小寫
  mapInitLatitude: '', // 緯度
  mapInitLongitude: '', // 經度
  mapInitZoom: '12', // 縮放
  projectLabel: '',
  upsVendorGroup: '', // 限制 key 小寫
}

@Component({
  components: {
    LabelTextField,
    Editor,
    Label,
    LabelSelect,
  },
})
export default class add extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  mapInitZoomList = ['6', '8', '10', '12', '14', '16', '18']

  intersectionFormatStatus = false
  batteryFormatStatus = false
  gatewayFormatStatus = false
  upsFormatStatus = false

  projectIdList: string[] = []

  temp: IPostProjectRequest = {
    ...formateTemp,
    intersectionGroup: localStorage.getItem('intersectionGroup')!,
    batteryVendorGroup: localStorage.getItem('batteryVendorGroup')!,
    gatewayVendorGroup: localStorage.getItem('gatewayVendorGroup')!,
    upsVendorGroup: localStorage.getItem('upsVendorGroup')!,
  }

  setInputValue(
    value: { [key: string]: string | number },
    errorStatus: boolean,
    isValidKeyProperty: boolean,
    noJsonValueProperty: boolean,
    formatStatusProperty: string,
    tempProperty: string
  ) {
    this[formatStatusProperty] = errorStatus || isValidKeyProperty || noJsonValueProperty

    if (!this[formatStatusProperty]) {
      this.temp[tempProperty] = JSON.stringify(value)
    }
  }

  onError(errorStatus: boolean, formatStatusProperty: string) {
    this[formatStatusProperty] = errorStatus
  }

  inputIntersectionValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'intersectionFormatStatus', 'intersectionGroup')
  }

  intersectionOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'intersectionFormatStatus')
  }

  inputBatteryValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'batteryFormatStatus', 'batteryVendorGroup')
  }

  batteryOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'batteryFormatStatus')
  }

  inputGatewayValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'gatewayFormatStatus', 'gatewayVendorGroup')
  }

  gatewayOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'gatewayFormatStatus')
  }

  inputUpsValue(value: { [key: string]: string | number }, errorStatus: boolean, isValidKey: boolean, noJsonValue: boolean) {
    this.setInputValue(value, errorStatus, isValidKey, noJsonValue, 'upsFormatStatus', 'upsVendorGroup')
  }

  upsOnError(errorStatus: boolean) {
    this.onError(errorStatus, 'upsFormatStatus')
  }

  async handleSubmit() {
    const validateStatus = await (this.$refs.form as Vue & { validate: () => boolean }).validate()

    if (!validateStatus || this.intersectionFormatStatus || this.batteryFormatStatus || this.gatewayFormatStatus || this.upsFormatStatus) {
      return
    }

    await this.doSubmit()
  }

  async doSubmit() {
    try {
      this.isPageLoading = true
      await this.$api.project.createProject(this.temp)
      await this.$swal.fire({
        title: '新增成功',
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      })
      await this.getProjectList()
      await useUserStore().getGroupParams(JSON.parse(localStorage.getItem('projectId')!))
      this.temp = {
        ...formateTemp,
        intersectionGroup: localStorage.getItem('intersectionGroup')!,
        batteryVendorGroup: localStorage.getItem('batteryVendorGroup')!,
        gatewayVendorGroup: localStorage.getItem('gatewayVendorGroup')!,
        upsVendorGroup: localStorage.getItem('upsVendorGroup')!,
      }
      ;(this.$refs.form as Vue & { reset: () => boolean }).reset()
    } catch (e) {
      console.error(e)
    } finally {
      this.isPageLoading = false
    }
  }

  async getProjectList() {
    try {
      this.isPageLoading = true

      const { data } = await this.$api.project.getProjectList()

      this.projectIdList = data.map((i) => i.projectId)
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    await this.getProjectList()
  }
}
