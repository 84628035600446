






import { useAppStore } from '@/store/app'
import { computed } from '@vue/composition-api'

export default {
  name: 'AppBarToggle',

  setup() {
    const store = useAppStore()
    const drawerIsMini = computed({
      get: () => store.drawerIsMini,
      set: (v) => {
        store.drawerIsMini = v
      },
    })
    return { drawerIsMini }
  },
}
