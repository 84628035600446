import { axiosIns } from '../axiosIns'
import {
  IDeviceInstallCheckGetRequest,
  IDeviceInstallCheckGetResponse,
  IDeviceGetInstallLogResponse,
  IDeviceInstallPostRequest,
  IDeviceGetListRequest,
  IDeviceGetListResponse,
  IDevicePostRequest,
  IDeviceDeleteRequest,
  ICheckDeviceInfo,
  IDeviceAddInstallDetailRequest,
  IDeviceInstallDetailResponse,
  IDeviceInstallImages,
} from '@/types/api/device'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  // 新增Device
  create: (payload: IDevicePostRequest) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/device`, payload))
  },

  // 安裝 Device
  install: (payload: IDeviceInstallPostRequest) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install`, payload))
  },

  // 安裝 Device 檢查
  check: (params: IDeviceInstallCheckGetRequest) => {
    return handleResponse<IDeviceInstallCheckGetResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/check`, { params }))
  },

  // 已安裝 Device 列表
  getInstallCheck: (params: { intersectionId: string }) => {
    return handleResponse<ICheckDeviceInfo>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/check`, { params }))
  },

  // 已安裝 Device 列表
  getInstalledLogs: (params: { intersectionId: string }) => {
    return handleResponse<IDeviceGetInstallLogResponse>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/log`, { params })
    )
  },

  getDeviceList: (params: IDeviceGetListRequest) => {
    return handleResponse<IDeviceGetListResponse>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/list`, { params }))
  },

  deleteDevice: (params: IDeviceDeleteRequest) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/device`, { params }))
  },

  addInstallDetail: (payload: IDeviceAddInstallDetailRequest) => {
    return handleResponse<void>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/detail`, payload))
  },

  getInstallDetailLog: (params: { intersectionId: string }) => {
    return handleResponse<IDeviceInstallDetailResponse[]>(
      axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/detail/log`, { params })
    )
  },

  deleteInstallDetailLog: (params: { intersectionId: string; installDeviceDetailNumber: string }) => {
    return handleResponse<void>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/detail`, { params }))
  },

  deviceUnbind: (params: IDeviceDeleteRequest) => {
    return handleResponse<void>(
      axiosIns.put(`${process.env.VUE_APP_API_PATH}/v1/auth/device/unbind?projectId=${params.projectId}&deviceId=${params.deviceId}`)
    )
  },

  updateImages: (payload: IDeviceInstallImages) => {
    return handleResponse<IDeviceInstallImages>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/images`, payload))
  },

  getInstallImages: (params: { intersectionId: string }) => {
    return handleResponse<IDeviceInstallImages>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/auth/device/install/images`, { params }))
  },
}
