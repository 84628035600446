import { axiosIns } from '../axiosIns'
import { IExceptionHandleResponse } from '@/types/api/exceptionHandle'
import Vue from 'vue'

const swalAlert = (title: string) => {
  Vue.prototype.$swal.fire({
    title,
    icon: 'warning',
    showConfirmButton: true,
    allowOutsideClick: false,
  })
}

const handleResponse = async <T>(callApi: Promise<any>) => {
  const { data, status } = await callApi

  if (status === 200) {
    const errorData = data as IExceptionHandleResponse

    if (Number(errorData.errorCode) > 1000) {
      swalAlert(errorData.errorMsg)

      throw errorData
    } else {
      return data as T
    }
  }
}

export default {
  // 刪除Totp雙因子ByAdmin
  deleteOtpByAdmin: (data: { userId: string }) => {
    return handleResponse<string>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/otp/admin/otp-secret`, { data }))
  },

  // 註冊Totp雙因子(CAT3 User)
  registerOtpSecret: () => {
    return handleResponse<string>(axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/otp/user/otp-secret`))
  },

  // 刪除Totp雙因子
  deleteOtpSecret: () => {
    return handleResponse<string>(axiosIns.delete(`${process.env.VUE_APP_API_PATH}/v1/auth/otp/user/otp-secret`))
  },

  // 驗證Totp雙因子
  verifyCode: (payload: string) => {
    return handleResponse<boolean>(
      axiosIns.post(`${process.env.VUE_APP_API_PATH}/v1/auth/otp/user/verify-code`, payload, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
    )
  },

  // 確認client otp 註冊狀態(CAT3 User)
  checkUserOtp: (params: { email: string }) => {
    return handleResponse<boolean>(axiosIns.get(`${process.env.VUE_APP_API_PATH}/v1/noauth/otp/user/check?email=${params.email}`))
  },
}
