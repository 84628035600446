import { defineStore } from 'pinia'
import api from '@/api/api'
import { IGetIntersectionItem, IGetIntersectionBatteryLevelResponse } from '@/types/api/intersection'
import { DeviceType } from '@/constants/device'
import {
  ITelemetryCurGetBatteryResponse,
  ITelemetryCurGetGatewayResponse,
  ITelemetryCurGetUpsResponse,
  IEventInputErrorLogResponse,
  IEventGetWorkLogResponse,
  IEventErrorLogGetResponse,
} from '@/types/api/event'
import { IntersectionStatusLabelMap, INTERSECTION_LABEL, INTERSECTION_STATUS } from '@/constants/intersection'
import dayjs from 'dayjs'
import {
  UpsConnectStatusLabelConfigMap,
  UpsOverloadStatusLabelConfigMap,
  UpsStatusLabelCodeConfigMap,
  UpsConnectStatusCodeLabelMap,
  UpsOverloadStatusCodeLabelMap,
  UpsStatusCodeLabelMap,
} from '@/constants/ups'
import { InputStatusConfigMap, InputStatusCodeLabelMap } from '@/constants/input'
import {
  BatteryConnectStatusLabel,
  BatteryConnectStatusLabelConfigMap,
  BatteryCharingStatusLabel,
  BatteryCharingStatusLabelConfigMap,
  BatteryConnectStatusLabelMap,
  BatteryCharingStatusCodeLabelMap,
} from '@/constants/battery'
import { DATE_PLACEHOLDER, TIME_PLACEHOLDER } from '@/constants/constants'
import { useAppStore } from '@/store/app'

interface State {
  // 所有路口
  intersectionItems: IGetIntersectionItem[]
  isIntersectionListHasLoaded: boolean
  // 被選中之路口 Id
  intersectionIdActived: string
  /**
   * 被選中之路口 Id 中 : the current telemetry data and device info
   * batteryADeviceInfoTelemetry : position "0" is A 、position "1" is B
   */
  batteryADeviceInfoTelemetry: ITelemetryCurGetBatteryResponse | null
  batteryBDeviceInfoTelemetry: ITelemetryCurGetBatteryResponse | null
  upsDeviceInfoTelemetry: ITelemetryCurGetUpsResponse | null
  gatewayDeviceInfoTelemetry: ITelemetryCurGetGatewayResponse | null
  /**
   * 檢查裝置是否離線
   */
  isBatteryOffline: boolean
  isInputOffline: boolean
  isUpsOffline: boolean

  /**
   * overview items
   */
  inputErrorList: IEventInputErrorLogResponse[]
  workLogList: IEventGetWorkLogResponse[]
  errEventLogs: IEventErrorLogGetResponse['data']
  batteryLevelList: IGetIntersectionBatteryLevelResponse[]

  // 供電車 MOBIL 或是固定式路口 FIXED
  intersectionType: string
}

interface IIntersectionLabel {
  intersectionLabel: string
  avgBatteryLevel: number
  intersectionStatus?: INTERSECTION_LABEL
  isBatteryOffline: boolean
  isInputOffline: boolean
  isUpsOffline: boolean
}

export class StoreInterval {
  static intervalTimerMap = new Map()

  static register(key: string, fn: Function, t: number) {
    const k = `${key}-${t}`
    const timer = this.intervalTimerMap.get(k)
    if (timer) {
      clearInterval(timer)
      this.intervalTimerMap.delete(k)
    }
    this.intervalTimerMap.set(k, setInterval(fn, t))
  }

  static remove(key: string) {
    const timer = this.intervalTimerMap.get(key)
    clearInterval(timer)
  }

  static removeAll() {
    for (const [, v] of this.intervalTimerMap) {
      clearInterval(v)
    }
    this.intervalTimerMap.clear()
  }
}

const containsNaN = (payload: any) => {
  for (const v of Object.values<any>(payload)) {
    if (typeof v === 'string' && v.includes('NaN')) {
      return true
    }
  }
}

export const useIntersectionStore = defineStore('intersections', {
  state: (): State => ({
    intersectionItems: [],
    isIntersectionListHasLoaded: false,
    intersectionIdActived: '',
    batteryADeviceInfoTelemetry: null,
    batteryBDeviceInfoTelemetry: null,
    upsDeviceInfoTelemetry: null,
    gatewayDeviceInfoTelemetry: null,
    isBatteryOffline: false,
    isInputOffline: false,
    isUpsOffline: false,
    inputErrorList: [],
    workLogList: [],
    errEventLogs: [],
    batteryLevelList: [],
    intersectionType: 'FIXED',
  }),
  actions: {
    /** 選中一特定路口後取得最新 device info & telemetry data (每分鐘) */
    async setActivedIntersectionId(id: string) {
      const appStore = useAppStore()
      this.resetIntersection()
      this.intersectionIdActived = id

      appStore.currentStatus = true
      try {
        const deviceInfos = await api.event.getTelemetryCur({ intersectionId: id })

        if (deviceInfos) {
          for (const deviceInfoTelemetry of deviceInfos) {
            /** @todo */
            if (containsNaN(deviceInfoTelemetry.payload)) {
              console.error('setActivedIntersectionId payload contains NaN', deviceInfoTelemetry)
              return
            }
            if (deviceInfoTelemetry.deviceType === DeviceType.battery) {
              if (deviceInfoTelemetry.position === '0') {
                this.batteryADeviceInfoTelemetry = deviceInfoTelemetry
              }
              if (deviceInfoTelemetry.position === '1') {
                this.batteryBDeviceInfoTelemetry = deviceInfoTelemetry
              }
            }
            if (deviceInfoTelemetry.deviceType === DeviceType.ups) {
              this.upsDeviceInfoTelemetry = deviceInfoTelemetry
            }
            if (deviceInfoTelemetry.deviceType === DeviceType.gateway) {
              this.gatewayDeviceInfoTelemetry = deviceInfoTelemetry
            }
          }
        }
      } finally {
        appStore.currentStatus = false
        this._checkHasOffline()
        StoreInterval.register('setActivedIntersectionId', this.setActivedIntersectionId.bind(this, id), 60 * 1000)
      }
    },
    /** 檢查裝置是否離線 */
    _checkHasOffline() {
      const threshold = 10
      const batteryALastTs = dayjs(this.batteryADeviceInfoTelemetry?.updateTime).diff(dayjs(), 'minutes')
      const batteryBLastTs = dayjs(this.batteryBDeviceInfoTelemetry?.updateTime).diff(dayjs(), 'minutes')
      const inputLastTs = dayjs(this.gatewayDeviceInfoTelemetry?.updateTime).diff(dayjs(), 'minutes')
      const upsLastTs = dayjs(this.upsDeviceInfoTelemetry?.updateTime).diff(dayjs(), 'minutes')

      this.isBatteryOffline = Math.abs(batteryBLastTs) > threshold && Math.abs(batteryALastTs) > threshold
      this.isInputOffline = Math.abs(inputLastTs) > threshold
      this.isUpsOffline = Math.abs(upsLastTs) > threshold
    },
    /** 市電異常次數、斷電總時數 (每 10 分鐘 refresh) */
    async getInputErrorLog() {
      try {
        const query = {
          projectId: JSON.parse(localStorage.getItem('projectId')!),
          startTime: dayjs().startOf('month').valueOf(), // 取得當前月份的第一天
          endTime: dayjs().endOf('month').valueOf(), // 取得當前月份的最後一天
          isFilterMobile: false,
        }

        const data = await api.event.getInputErrorLog(query)
        this.inputErrorList = data!
      } catch (error) {
        console.log(error)
      } finally {
        StoreInterval.register('getInputErrorLog', this.getInputErrorLog, 10 * 60 * 1000)
      }
    },
    /** UPS啟動次數、 UPS供電時間  refresh 10 minutely */
    async getWorkLogList() {
      try {
        const query = {
          projectId: JSON.parse(localStorage.getItem('projectId')!),
          startTime: dayjs().startOf('month').valueOf(), // 取得當前月份的第一天
          endTime: dayjs().endOf('month').valueOf(), // 取得當前月份的最後一天
          isFilterMobile: false,
        }

        const data = await api.event.getUpsWorkLog(query)
        this.workLogList = data!
      } catch (error) {
        console.log(error)
      } finally {
        StoreInterval.register('getWorkLogList', this.getWorkLogList, 10 * 60 * 1000)
      }
    },
    /** 本月異常事件通知列表  refresh minutely */
    async getErrEventLogList(page = 0) {
      try {
        const query = {
          projectId: JSON.parse(localStorage.getItem('projectId')!),
          startTime: dayjs().startOf('month').valueOf(), // 取得當前月份的第一天
          endTime: dayjs().endOf('month').valueOf(), // 取得當前月份的最後一天
          sort: 'DESC',
          pageSize: 100,
          page,
          intersectionId: '',
        }

        if (page === 0) {
          this.errEventLogs.splice(0)
        }

        const data = await api.event.getEventErrorLog(query)
        if (data === undefined) {
          throw new TypeError()
        }

        this.errEventLogs = [...this.errEventLogs, ...data.data]
        if (data.hasNext) {
          await this.getErrEventLogList(page + 1)
        }
      } catch (error) {
        console.error(error)
      } finally {
        StoreInterval.register('getErrEventLogList', this.getErrEventLogList, 60 * 1000)
      }
    },
    /** 取得路口電池電量列表  refresh minutely */
    async getBatteryLevelList() {
      // const appStore = useAppStore()
      try {
        // appStore.isPageLoading = true
        const data = await api.intersection.getBatteryLevel({ projectId: JSON.parse(localStorage.getItem('projectId')!) })
        this.batteryLevelList = data!
      } catch (error) {
        console.log(error)
      } finally {
        // appStore.isPageLoading = false
        StoreInterval.register('getErrEventLogList', this.getErrEventLogList, 60 * 1000)
      }
    },
    /** 取得全部路口 (每分鐘)  */
    async setAllIntersection() {
      this.isIntersectionListHasLoaded = false
      const _setAllIntersection = async (page = 0) => {
        if (page === 0) {
          this.intersectionItems.splice(0)
        }

        try {
          const query = {
            projectId: JSON.parse(localStorage.getItem('projectId')!),
            sort: 'DESC',
            pageSize: 100,
            page,
          }

          const data = await api.intersection.getIntersectionList(query)

          if (data) {
            const filteredArray = data.data.filter((x) => !this.intersectionItems.find((i) => i.intersectionId === x.intersectionId))
            this.intersectionItems = [...new Set([...this.intersectionItems, ...filteredArray])]
            if (data.hasNext) {
              await _setAllIntersection(page + 1)
            }
          }
        } finally {
          StoreInterval.register('setAllIntersection', _setAllIntersection, 60 * 1000)
        }
      }
      await _setAllIntersection()
      this.isIntersectionListHasLoaded = true
    },
    resetIntersection() {
      StoreInterval.remove(`setActivedIntersectionId-${60 * 1000}`)
      this.intersectionIdActived = ''
      // init
      this.batteryADeviceInfoTelemetry = null
      this.batteryBDeviceInfoTelemetry = null
      this.upsDeviceInfoTelemetry = null
      this.gatewayDeviceInfoTelemetry = null
      this.isBatteryOffline = false
      this.isInputOffline = false
      this.isUpsOffline = false
    },
  },
  getters: {
    // 已被選擇的路口
    intersectionItemActived: ({ intersectionItems, intersectionIdActived }) => {
      return intersectionItems.find((i) => i.intersectionId === intersectionIdActived)
    },
    // 全部已安裝路口
    intersectionInstalled: ({ intersectionItems }) => {
      return intersectionItems.filter((i) => i.intersectionStatus !== INTERSECTION_STATUS.NOT_INSTALL)
    },
    // ups 資訊
    upsInfo: (state) => {
      const connectStatus = UpsConnectStatusCodeLabelMap.get(+(state?.gatewayDeviceInfoTelemetry?.payload?.upsInfoDisconnect ?? 1))!
      const connectStatusConfig = UpsConnectStatusLabelConfigMap.get(connectStatus)!

      const overloadStatus = UpsOverloadStatusCodeLabelMap.get(+(state?.upsDeviceInfoTelemetry?.payload?.upsOverload ?? 1))!
      const overloadStatusConfig = UpsOverloadStatusLabelConfigMap.get(overloadStatus)!

      const status = UpsStatusCodeLabelMap.get(+(state?.upsDeviceInfoTelemetry?.payload?.upsStatus ?? 4))!
      const statusConfig = UpsStatusLabelCodeConfigMap.get(status)!

      const updateTime = state?.upsDeviceInfoTelemetry?.updateTime
        ? dayjs(state?.upsDeviceInfoTelemetry?.updateTime).format('YYYY/MM/DD HH:mm:ss')
        : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`

      return {
        id: state?.batteryADeviceInfoTelemetry?.payload?.upsId ?? state?.batteryBDeviceInfoTelemetry?.payload?.upsId ?? '-',
        connectStatus,
        connectStatusConfig,
        overloadStatus,
        overloadStatusConfig,
        status,
        statusConfig,
        temperature: +(state?.upsDeviceInfoTelemetry?.payload?.upsTemperature ?? NaN),
        output: +(state?.upsDeviceInfoTelemetry?.payload?.upsOutput ?? NaN),
        updateTime,
      }
    },
    // 市電資訊
    inputInfo: (state) => {
      const status = InputStatusCodeLabelMap.get(+(state?.gatewayDeviceInfoTelemetry?.payload?.inputStatus ?? '1').slice(0, 1))!
      const statusConfig = InputStatusConfigMap.get(status)

      const updateTime = state?.gatewayDeviceInfoTelemetry?.updateTime
        ? dayjs(state?.gatewayDeviceInfoTelemetry?.updateTime).format('YYYY/MM/DD HH:mm:ss')
        : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`

      return {
        status,
        statusConfig,
        frequency: +(state?.gatewayDeviceInfoTelemetry?.payload?.inputFrequency ?? 0),
        voltage: +(state?.gatewayDeviceInfoTelemetry?.payload?.inputVoltage ?? 0),
        updateTime,
      }
    },
    // 路口資訊
    intersectionInfo(state): IIntersectionLabel {
      const { isBatteryOffline = true, isInputOffline = true, isUpsOffline = true } = state
      const {
        intersectionLabel = '-',
        avgBatteryLevel = NaN,
        intersectionStatus = INTERSECTION_STATUS.ABNORMAL,
      } = this.intersectionItemActived || {}
      return {
        intersectionLabel,
        avgBatteryLevel,
        intersectionStatus: IntersectionStatusLabelMap.get(intersectionStatus),
        isBatteryOffline,
        isInputOffline,
        isUpsOffline,
      }
    },
    // 電池資訊
    batteryInfo(state): {
      connectStatus: BatteryConnectStatusLabel
      connectConfig: { color: string; icon: string }
      A: {
        label: string
        level: number
        disabled: boolean
        voltage: number
        updateTime: string
        chargingStatus: BatteryCharingStatusLabel
        chargingStatusConfig: {
          color: string
        }
      }
      B: {
        label: string
        level: number
        disabled: boolean
        voltage: number
        updateTime: string
        chargingStatus: BatteryCharingStatusLabel
        chargingStatusConfig: {
          color: string
        }
      }
    } {
      const batteryInfoDisconnect = state?.gatewayDeviceInfoTelemetry?.payload?.batteryInfoDisconnect ?? 1
      const connectStatus = BatteryConnectStatusLabelMap.get(+batteryInfoDisconnect)!
      const connectConfig = BatteryConnectStatusLabelConfigMap.get(connectStatus)!

      return {
        connectStatus,
        connectConfig,
        A: this._getBatteryInfoDetail('batteryADeviceInfoTelemetry'),
        B: this._getBatteryInfoDetail('batteryBDeviceInfoTelemetry'),
      }
    },
    _getBatteryInfoDetail: (state) => (ABkey: string) => {
      const ptr = state[ABkey]

      const chargingStatus = BatteryCharingStatusCodeLabelMap.get(+(ptr?.payload?.batteryChargingStatus ?? 2))!
      const chargingStatusConfig = BatteryCharingStatusLabelConfigMap.get(chargingStatus)!

      const level = ptr?.payload?.batteryLevel ? +Number(ptr?.payload?.batteryLevel).toFixed(2) : NaN
      const disabled = ptr?.payload?.batteryLevel ? parseFloat(String(ptr?.payload?.batteryLevel)) === 130 : true
      const voltage = ptr?.payload?.batteryVoltage ? +Number(ptr?.payload?.batteryVoltage).toFixed(2) : NaN

      return {
        label: ptr?.deviceLabel ?? '-',
        level,
        disabled,
        voltage,
        updateTime: ptr?.updateTime ? dayjs(ptr?.updateTime).format('YYYY/MM/DD HH:mm:ss') : `${DATE_PLACEHOLDER} ${TIME_PLACEHOLDER}`,
        chargingStatus,
        chargingStatusConfig,
      }
    },
  },
})
