















































































import { Component, Vue } from 'vue-property-decorator'
import AlertDialog from './Dialog/alertDialog.vue'
import { useAppStore } from '@/store/app'
import { IGetUserGroupItemResponse } from '@/types/api/user'
import { IEventSettingResponse } from '@/types/api/event'
import { WritableState } from '@/util/annotations'

const temp = {
  emailNotifyEnable: false,
  emailNotifyGroup: [],
  eventType: '',
  lineNotifyEnable: false,
  lineNotifyGroup: [],
  projectId: JSON.parse(localStorage.getItem('projectId')!),
}

@Component({ components: { AlertDialog } })
export default class AlertSetting extends Vue {
  @WritableState(useAppStore, 'isPageLoading') isPageLoading!: boolean

  projectId = ''
  dialogStatus = false
  temp = JSON.parse(JSON.stringify(temp))
  userGroupList: IGetUserGroupItemResponse[] = []
  eventSettingList: IEventSettingResponse[] = []

  lineNotifyGroupDetail: IGetUserGroupItemResponse[] = []
  emailNotifyGroupDetail: IGetUserGroupItemResponse[] = []

  get lineUserGroup() {
    return this.userGroupList.map((i) => ({
      ...i,
      userGroupLabel: i.lineToken ? i.userGroupLabel : `(未填寫 Line Token) ${i.userGroupLabel}`,
    }))
  }

  get tableList() {
    return this.eventSettingList.map((i, index) => ({
      ...i,
      index: index + 1,
      lineGroupLabel: i.lineNotifyGroup
        ? this.userGroupList.filter((o) => i.lineNotifyGroup.includes(o.userGroupId)).map((x) => x.userGroupLabel)
        : [],
      emailGroupLabel: i.emailNotifyGroup
        ? this.userGroupList.filter((o) => i.emailNotifyGroup.includes(o.userGroupId)).map((x) => x.userGroupLabel)
        : [],
    }))
  }

  switchShowInEventList(item: IEventSettingResponse) {
    item.showInEventList = !item.showInEventList
    this.handleSubmit(item)
  }

  switchLineActive(item: IEventSettingResponse) {
    item.lineNotifyEnable = !item.lineNotifyEnable
    this.handleSubmit(item)
  }

  switchEmailActive(item: IEventSettingResponse) {
    item.emailNotifyEnable = !item.emailNotifyEnable
    this.handleSubmit(item)
  }

  toggleDialog() {
    this.dialogStatus = !this.dialogStatus
    if (!this.dialogStatus) {
      this.temp = JSON.parse(JSON.stringify(temp))
    }
  }

  handleEdit(item: IEventSettingResponse) {
    this.temp = JSON.parse(JSON.stringify(item))
    this.lineNotifyGroupDetail = this.userGroupList.filter((o) => this.temp.lineNotifyGroup?.includes(o.userGroupId))
    this.emailNotifyGroupDetail = this.userGroupList.filter((o) => this.temp.emailNotifyGroup?.includes(o.userGroupId))

    this.toggleDialog()
  }

  async handleEmitGroupAndSubmit([lineNotifyGroup, emailNotifyGroup]: [string[], string[]]) {
    this.temp.emailNotifyGroup = emailNotifyGroup
    this.temp.lineNotifyGroup = lineNotifyGroup

    await this.handleSubmit(this.temp)
  }

  async handleSubmit(item: IEventSettingResponse) {
    try {
      this.isPageLoading = true
      await this.$api.event.updateEventSetting({
        showInEventList: item.showInEventList,
        emailNotifyEnable: item.emailNotifyEnable,
        emailNotifyGroup: item.emailNotifyGroup,
        eventType: item.eventType,
        lineNotifyEnable: item.lineNotifyEnable,
        lineNotifyGroup: item.lineNotifyGroup,
        projectId: this.projectId,
      })
      await this.getEventSetting()

      if (!this.dialogStatus) return

      await this.toggleDialog()
      await this.$swal.fire({
        title: `已成功在告警項目 ${item.eventDescription} 中變更通知對象`,
        icon: 'success',
        toast: true,
        position: 'top',
        timer: 1500,
        width: 600,
        showConfirmButton: false,
        timerProgressBar: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getUserGroup() {
    try {
      this.isPageLoading = true

      const data = await this.$api.user.getGroupList({ projectId: this.projectId })
      this.userGroupList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async getEventSetting() {
    try {
      this.isPageLoading = true

      const data = await this.$api.event.getEventSetting({ projectId: this.projectId })
      this.eventSettingList = data!
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false
    }
  }

  async mounted() {
    this.projectId = JSON.parse(localStorage.getItem('projectId')!)

    if (this.projectId) {
      await this.getUserGroup()
      await this.getEventSetting()
    }
  }
}
