
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeText from './TimeText.vue'

@Component({ components: { TimeText } })
export default class TimeTitle extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: string[]
  @Prop({ type: String, default: 'title' }) title!: string
  render() {
    return (
      <div style='display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-center;gap: 1rem;'>
        <h2 style='flex: 1 0 150px;text-align:left;'>{this.title}</h2>
        <div style='width:100%;display:flex;justify-content:space-between;gap: 1rem;'>
          {this.value.map((time) => (
            <TimeText>{time}</TimeText>
          ))}
        </div>
      </div>
    )
  }
}
