import device from './allApi/deviceService'
import auth from './allApi/authService'
import event from './allApi/eventService'
import intersection from './allApi/intersectionService'
import project from './allApi/projectService'
import user from './allApi/userService'
import setting from './allApi/settingService'
import admin from './allApi/adminService'
import otp from './allApi/otpService'

export default {
  device,
  auth,
  event,
  intersection,
  project,
  user,
  setting,
  admin,
  otp,
}
