import { createPinia, PiniaVuePlugin } from 'pinia'
import { PiniaLogger } from 'pinia-logger'
import Vue from 'vue'

const pinia = createPinia()
// pinia.use(
//   PiniaLogger({
//     expanded: true,
//     disabled: process.env.mode === 'production',
//   })
// )

Vue.use(PiniaVuePlugin)

export default pinia
