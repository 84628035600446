import Vue, { ComponentOptions, VNode } from 'vue'
import vuetify from '@/plugins/vuetify'
import { VBtn, VDialog, VCard, VCardTitle, VCol, VCardActions, VSpacer } from 'vuetify/lib'

export type TOpen = (p: {
  title: string | VNode
  content: string | VNode
  okText: string
  cancelText: string
}) => Promise<void>

const dialog = () => {
  let vm: null | Vue = null
  const getRootElm = () => document.getElementById('app')

  const close = () => {
    const elm = getRootElm()
    if (vm && elm) {
      elm.removeChild(vm.$el)
      vm.$destroy()
    }
  }

  const open: TOpen = ({ title, content, okText = 'Ok', cancelText = 'Cancel' }) => {
    let _resolve: any = null
    let _reject: any = null

    vm = new Vue({
      name: 'Dlg',
      vuetify,
      render: (h) => (
        <VDialog width='500' persistent value={true}>
          <VCard>
            <VCardTitle class='text-h5'>{title}</VCardTitle>
            <VCol class='px-6'>{content}</VCol>
            <VCardActions class='pt-3'>
              <VSpacer />
              {
                <VBtn
                  color='primary'
                  text
                  class='body-2 font-weight-bold'
                  on={{
                    click: () => {
                      _resolve()
                      close()
                    },
                  }}
                >
                  {okText}
                </VBtn>
              }
              {cancelText && (
                <VBtn
                  color='grey'
                  class='body-2 font-weight-bold'
                  outlined
                  on={{
                    click: () => {
                      _reject()
                      close()
                    },
                  }}
                >
                  {cancelText}
                </VBtn>
              )}
            </VCardActions>
          </VCard>
        </VDialog>
      ),
    }).$mount()

    const elm = getRootElm()
    elm && elm.append(vm.$el)

    return new Promise((resolve, reject) => {
      _resolve = resolve
      _reject = reject
    })
  }
  return {
    open,
    close,
  }
}

export default (() => dialog())()
