var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("已建立 Log ("+_vm._s(_vm.totalElements)+")")])])],1),_c('div',{staticClass:"table"},[_c('v-row',{staticClass:"d-flex align-end"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"mb-1"},[_vm._v("當天日期")]),_c('DateTimePicker',{attrs:{"range":false,"disabled-date":_vm.disableDate,"format":"YYYY-MM-DD","value-type":"timestamp"},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dateValue"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"mb-1"},[_vm._v("起始時間")]),_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","hide-details":"","outlined":"","solo":"","dense":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeStatus),callback:function ($$v) {_vm.startTimeStatus=$$v},expression:"startTimeStatus"}},[(_vm.startTimeStatus)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-row',{staticClass:"pt-8 pb-4",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.startTimeStatus = false}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"mb-1"},[_vm._v("結束時間")]),_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","hide-details":"","outlined":"","solo":"","dense":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeStatus),callback:function ($$v) {_vm.endTimeStatus=$$v},expression:"endTimeStatus"}},[(_vm.endTimeStatus)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-row',{staticClass:"pt-8 pb-4",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.endTimeStatus = false}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"mb-1"},[_vm._v("路口名稱")]),_c('v-autocomplete',{attrs:{"label":"選擇路口","itemText":"intersectionIndex","item-value":"intersectionId","items":_vm.mappingIntersectionList,"hide-details":"","outlined":"","solo":"","dense":""},on:{"change":_vm.getIntersectionLabel},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.installDeviceStatus === 'INSTALL' ? 'success' : 'gray'}},[_vm._v(" "+_vm._s(item.installDeviceStatus === 'INSTALL' ? 'mdi-checkbox-marked-circle' : 'mdi-help-circle')+" ")]),_vm._v(" "+_vm._s(item.intersectionIndex)+" ")]}}],null,true),model:{value:(_vm.intersectionId),callback:function ($$v) {_vm.intersectionId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"intersectionId"}})],1)],1),_c('div',{staticClass:"d-flex justify-end align-end mt-8"},[_c('button',{staticClass:"exportReport",on:{"click":_vm.handleDownLoadCSV}},[_c('v-icon',{attrs:{"dark":"","color":"#0070f2"}},[_vm._v(" mdi-file-export-outline ")]),_vm._v("匯出列表 ")],1),_c('v-btn',{staticClass:"px-8",attrs:{"color":"primary"},on:{"click":_vm.handleSearch}},[_vm._v(" 搜尋 ")])],1),_c('v-data-table',{staticClass:"mt-7 elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"hide-default-footer":true,"items-per-page":1000},scopedSlots:_vm._u([{key:"item.dataTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataTime ? _vm.$dayjs(item.dataTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createTime ? _vm.$dayjs(item.createTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updateTime ? _vm.$dayjs(item.updateTime).format('YYYY/MM/DD HH:mm:ss') : '----/--/-- --:--:--')+" ")]}},{key:"item.handler",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('button',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleView(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-text")])],1)])]}}],null,true)}),(_vm.totalPages > 0)?_c('v-row',{staticClass:"d-flex flex-column-reverse flex-sm-row align-center mt-4"},[_c('v-col',{attrs:{"cols":"0","sm":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":10},on:{"input":_vm.getTelemetry},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"currentPage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"單頁呈現筆數","items":[10, 50, 100, 500, 1000]},on:{"change":_vm.selectTelemetryTotalNum},model:{value:(_vm.currentPageSize),callback:function ($$v) {_vm.currentPageSize=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"currentPageSize"}})],1)],1):_vm._e()],1),_c('Dialog',{attrs:{"dialogStatus":_vm.payloadDialogStatus,"width":500,"title":'Payload'},on:{"closeDialog":function($event){_vm.payloadDialogStatus = false}}},[_c('div',{staticClass:"d-flex flex-column justify-start pb-12 px-8"},[_c('VueJsonPretty',{attrs:{"data":_vm.dialogList}}),(_vm.showTemperatureTips)?_c('p',{staticClass:"text__size-14 mt-8 text-theme-gray"},[_vm._v(" batteryTemperature 所載之值，將依實際電池型號不同進行對應offset調整才為實際電池溫度 (℃) ")]):_vm._e()],1)]),_c('downloadCsv',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"TelemetryTimeSeriesDownloadCsv","bom":true,"data":_vm.CSVTable,"name":(_vm.CSVname + ".csv")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }